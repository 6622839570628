import apolloclient from './apolloClient';
import { store } from '../store';
import storage from 'redux-persist/lib/storage';
// import { GET_USER } from './queries/users';
import * as Sentry from '@sentry/react';

class DBApi {
  constructor() {
    this.SYS_DB = 'plmdb';
  }

  query(gqlQuery, dbName) {
    const promise = new Promise((resolve, reject) => {
      apolloclient
        .query({
          query: gqlQuery,
          context: {
            headers: {
              plmdb: dbName,
            },
          },
        })
        .then((result) => {
          if (result.errors) {
            const errors = [];
            result.errors.forEach((item) => errors.push(item.message));
            Sentry.captureException(result.errors);
            reject(errors.join('\r\n'));
          } else {
            resolve(result.data);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          switch (err.networkError.statusCode) {
            case 400:
              reject(
                new Error(
                  `Data could not be loaded at this time, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 401:
              reject(
                new Error(
                  `Request cannot be completed, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 403:
              // clear all the browser cache like we do on logout, including cache itself as well as the old expired token
              // Can't clear httponly token on clientside
              reject(new Error(`Unauthorized Access, CODE: ${err.networkError.statusCode}`));
              localStorage.removeItem('userId');
              storage.removeItem('persist:root');
              store.dispatch({ type: 'CLEAR_USER_DATA' });
              store.dispatch({ type: 'CLEAR_PROJECT_DATA' });
              store.dispatch({ type: 'CLEAR_EDIT_USER' });
              window.location.reload();
              break;
            case 500:
              reject(new Error(`Internal server error, CODE: ${err.networkError.statusCode}`));
              break;
            default:
              reject(err);
              break;
          }
        });
    });
    return promise;
  }

  inputQuery(queryVariables, gqlQuery, dbName) {
    const promise = new Promise((resolve, reject) => {
      apolloclient
        .query({
          query: gqlQuery,
          variables: queryVariables,
          context: {
            headers: {
              plmdb: dbName,
            },
          },
        })
        .then((result) => {
          if (result.errors) {
            Sentry.captureException(result.errors);
            const errors = [];
            result.errors.forEach((item) => errors.push(item.message));
            reject(errors.join('\r\n'));
          } else {
            // if (gqlQuery === GET_USER) {
            //   // this is the only exception to the return value
            //   resolve(result.data);
            // }
            resolve(result.data);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          switch (err.networkError.statusCode) {
            case 400:
              reject(
                new Error(
                  `Data could not be loaded at this time, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 401:
              reject(
                new Error(
                  `Request cannot be completed, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 403:
              // clear all the browser cache like we do on logout, including cache itself as well as the old expired token
              reject(new Error(`Unauthorized Access, CODE: ${err.networkError.statusCode}`));
              localStorage.removeItem('userId');
              storage.removeItem('persist:root');
              store.dispatch({ type: 'CLEAR_USER_DATA' });
              store.dispatch({ type: 'CLEAR_PROJECT_DATA' });
              store.dispatch({ type: 'CLEAR_EDIT_USER' });
              window.location.reload();
              break;
            case 500:
              reject(new Error(`Internal server error, CODE: ${err.networkError.statusCode}`));
              break;
            default:
              reject(new Error(`Unexpected Error, CODE: ${err.networkEr}`));
              break;
          }
        });
    });
    return promise;
  }

  mutate(queryVariables, gqlQuery, dbName) {
    const promise = new Promise((resolve, reject) => {
      apolloclient
        .mutate({
          mutation: gqlQuery,
          variables: queryVariables,
          context: {
            headers: {
              plmdb: dbName,
            },
          },
        })
        .then((result) => {
          if (result !== undefined) {
            if (result.errors) {
              Sentry.captureException(result.errors);
              const errors = [];
              result.errors.forEach((item) => errors.push(item.message));
              reject(errors.join('\r\n'));
            } else {
              resolve(result.data);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err);
          switch (err.networkError.statusCode) {
            case 400:
              reject(
                new Error(
                  `Data could not be loaded at this time, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 401:
              reject(
                new Error(
                  `Request cannot be completed, please try again later, CODE: ${err.networkError.statusCode}`,
                ),
              );
              break;
            case 403:
              reject(new Error(`Unauthorized Access, CODE: ${err.networkError.statusCode}`));
              localStorage.removeItem('userId');
              storage.removeItem('persist:root');
              store.dispatch({ type: 'CLEAR_USER_DATA' });
              store.dispatch({ type: 'CLEAR_PROJECT_DATA' });
              store.dispatch({ type: 'CLEAR_EDIT_USER' });
              window.location.reload();
              break;
            case 500:
              reject(new Error(`Internal server error, CODE: ${err.networkError.statusCode}`));
              break;
            default:
              reject(new Error(`Unexpected Error, CODE: ${err.networkError.statusCode}`));
              break;
          }
        });
    });
    return promise;
  }
}

export const dbApi = new DBApi();
