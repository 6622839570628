import { EDIT_USER, CLEAR_EDIT_USER } from '../actions/types';

const initialState = {
  editUserData: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER:
      return {
        ...state,
        editUserData: action.payload,
      };
    case CLEAR_EDIT_USER:
      return {
        ...state,
        editUserData: {},
      };
    default:
      return state;
  }
};
