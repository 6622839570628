import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authApi } from '../../../api/authApi';
import { isNull, parseError } from '../../../lib/helpers';
import toast from 'react-hot-toast';

const PrivateRoute = (props) => {
  const { children } = props;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const data = await authApi.authorizeRoute(location.pathname);
        if (isNull(data)) {
          throw new Error('No Response From Server');
        }
        setIsAuthenticated(data.authorized);
      } catch (err) {
        toast.error(parseError(err));
      }
    };
    asyncFunc();
  }, [location]);

  if (isAuthenticated === false) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to="/dashboard/404" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
