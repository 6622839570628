import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AdditionalComment from './AdditionalComment';
import ChangeOrderNewEntryForm from './ChangeOrderNewEntryForm';
import ChangeOrderUpdateEntryForm from './ChangeOrderUpdateEntryForm';
import SubmissionCreatedModal from '../common/SubmissionCreatedModal';
import { isNull } from '../../../../lib/helpers';

const NewChangeOrder = (props) => {
  const {
    handleClose,
    currentProject,
    approvedRCO,
    linkedRcoId,
    approvedSubmission,
    handleApprovalSubmit,
    isCoRevision,
    revisionCO,
    refetchCallback,
    parentRecordId,
  } = props;

  const [comments, setComments] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [disableCancel, setDisableCancel] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [submission, setSubmission] = useState(
    isCoRevision === false
      ? {
          parentId: null,
          title: '',
          internalId: !isNull(approvedRCO) ? approvedRCO.internalId : '',
          clientDocumentId: '',
          isCoInternal: false,
          isCoOpen: false,
          byUser: userData.id,
          sfgCost: isNull(linkedRcoId) ? -1 : 0,
          clientCost: isNull(linkedRcoId) ? -1 : 0,
          approvalStatus: 'Approved',
          from: '',
          to: '',
          internalParties: [],
          externalParties: [],
          submissionDate: new Date(),
          approvalDeadline: new Date(),
          linkedRcoId,
        }
      : {
          parentId: parentRecordId,
          title: revisionCO.title,
          internalId: revisionCO.internalId,
          clientDocumentId: revisionCO.clientDocumentId,
          isCoInternal: false,
          isCoOpen: true,
          byUser: userData.id,
          sfgCost: revisionCO.sfgCost,
          clientCost: revisionCO.clientCost,
          approvalStatus: 'Approved',
          from: '',
          to: '',
          internalParties: [],
          externalParties: [],
          submissionDate: new Date(),
          approvalDeadline: new Date(),
        },
  );

  useMemo(() => {
    if (approvedRCO) {
      setSubmission({
        ...submission,
        title: `CO Generated from ${approvedRCO.internalId}`,
        sfgCost: approvedRCO.sfgCost,
        clientCost: approvedRCO.clientCost,
      });
      setDisableCancel(true);
    }
  }, [approvedRCO]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  const handleSubmissionData = (data) => {
    setSubmission(data);
  };

  const setCommentsCallback = (data) => {
    setComments(data);
  };

  return (
    <div>
      {!completed ? (
        <>
          {activeStep === 0 && isCoRevision === false && (
            <ChangeOrderNewEntryForm
              onNext={handleNext}
              parentCallBack={handleSubmissionData}
              currentProject={currentProject}
              onClose={handleClose}
              submission={submission}
              disableCancel={disableCancel}
            />
          )}
          {activeStep === 0 && isCoRevision === true && (
            <ChangeOrderUpdateEntryForm
              onNext={handleNext}
              parentCallBack={handleSubmissionData}
              currentProject={currentProject}
              onClose={handleClose}
              submission={submission}
            />
          )}
          {activeStep === 1 && (
            <AdditionalComment
              onBack={handleBack}
              onComplete={handleComplete}
              submission={submission}
              comments={comments}
              setCommentsCallback={setCommentsCallback}
              // parentCallBack={handleMessage}
              refetchCallback={refetchCallback}
              handleApprovalSubmit={handleApprovalSubmit}
              approvedSubmission={approvedSubmission}
              approvedRCO={approvedRCO}
            />
          )}
        </>
      ) : (
        // <SubmissionCreatedModal handleClose={linkedRcoId ? handleRCOSequenceClose : handleClose} />
        <SubmissionCreatedModal handleClose={handleClose} />
      )}
    </div>
  );
};

NewChangeOrder.propTypes = {
  handleClose: PropTypes.func,
  currentProject: PropTypes.object,
  linkedRcoId: PropTypes.string,
  approvedRCO: PropTypes.object,
  handleApprovalSubmit: PropTypes.func,
  isCoRevision: PropTypes.bool,
  revisionCO: PropTypes.object,
  approvedSubmission: PropTypes.object,
  refetchCallback: PropTypes.func,
  parentRecordId: PropTypes.string,
};

export default NewChangeOrder;
