/**
 * @file Main container for all home components
 * @EditedBy Andy Luo
 * @version 0.1.1
 * @copyright © 2021 SIBER FACADE GROUP
 */
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import PrivateRoute from './components/authentication/routing/PrivateRoute';
// import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
// import NewDrawing from './components/dashboard/project-management/drawing-submissions/NewEntryForm/NewDrawing';
// import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import ChangeOrderSubmissionList from './pages/dashboard/ProjectManagement/ChangeOrderSubmissionList';
import InternalChangeOrderSubmissionList from './pages/dashboard/ProjectManagement/InternalChangeOrderSubmissionList';
// import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pagess

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// // const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Login2FA = Loadable(lazy(() => import('./pages/authentication/Login2FA')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
// const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

/*
const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));
*/

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
// const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
// const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
// const UserDetails = Loadable(lazy(() => import('./pages/dashboard/UserDetails')));
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/UserEdit')));
const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')));
const UserAdd = Loadable(lazy(() => import('./pages/dashboard/UserAdd')));
// const UserAddForm = Loadable(lazy(() => import('./components/dashboard/user/UserAddForm')));

// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview/Overview')));
// const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
// const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Docs pages

// const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

// const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
// const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const EgnyteAuthResult = Loadable(lazy(() => import('./pages/EgnyteAuthResult')));

// Projects pages
// const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectAdd = Loadable(lazy(() => import('./pages/dashboard/ProjectPages/ProjectAdd')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
const ProjectList = Loadable(lazy(() => import('./pages/dashboard/ProjectPages/ProjectList')));
const ProjectEdit = Loadable(lazy(() => import('./pages/dashboard/ProjectPages/ProjectEdit')));
// Suppliers
const SuppliersContracts = Loadable(
  lazy(() => import('./pages/dashboard/SupplierPages/SupplierContractsList')),
);
// Project Actors
const ProjectActorList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectActorPages/ProjectActorList')),
);
const ProjectActorAdd = Loadable(
  lazy(() => import('./pages/dashboard/ProjectActorPages/ProjectActorAdd')),
);
const ProjectActorEdit = Loadable(
  lazy(() => import('./pages/dashboard/ProjectActorPages/ProjectActorEdit')),
);

// Project Submissions
const ProjectSubmissionList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/ProjectSubmissionList')),
);

// Drawing Submissons
const DrawingSubmissionList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/DrawingSubmissionList')),
);

// Samples Submissions
const SampleSubmissionList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/SampleSubmissionList')),
);

// Site Instructions
const SiteInstructionList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/SiteInstructionsList')),
);

// Request for Change Orders
const RCOSubmissionList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/RCOSubmissionList')),
);

// Request for Change Orders
// const RequestForRCOListPage = Loadable(lazy(() => import('./pages/dashboard/ProjectManagement/RCOSubmissionList')));

// RFI submissions
const RFISubmisisonList = Loadable(
  lazy(() => import('./pages/dashboard/ProjectManagement/RFISubmissionList')),
);

// Manufacturing Pages
// ------Levels Overview ----------
const ManufacturingListPage = Loadable(
  lazy(() => import('./pages/dashboard/Manufacturing/ManuFacturingList')),
);

// Customer Portal Pages
// ------Levels Overview ----------
const CustomerProjectDisplayPage = Loadable(
  lazy(() => import('./pages/dashboard/CustomerPortal/CustomerProjectDisplay')),
);

// ------MAterial Batch Page-------
const MaterialBatchAddPage = Loadable(
  lazy(() => import('./pages/dashboard/Manufacturing/MaterialBatchAdd')),
);
// const BatchListPage = Loadable(lazy(() => import('./pages/dashboard/Manufacturing/BatchList')));

// ------Production Batch Page-------
const ProductionBatchAddPage = Loadable(
  lazy(() => import('./pages/dashboard/Manufacturing/ProductionBatchAdd')),
);

// ------Crate Page------
const CrateAddPage = Loadable(lazy(() => import('./pages/dashboard/Manufacturing/CrateAdd')));
// const CrateListPage = Loadable(lazy(() => import('./pages/dashboard/Manufacturing/CrateList')));

// ------Container Page-----
const ContainerAddPage = Loadable(
  lazy(() => import('./pages/dashboard/Manufacturing/ContainerAdd')),
);
// const ContainerListPage = Loadable(lazy(() => import('./pages/dashboard/Manufacturing/ContainerList')));

// ------Product Details Page ----
const ProductDetailsPage = Loadable(
  lazy(() => import('./pages/dashboard/Manufacturing/ProductDetails')),
);

// Logistics Pages
// -------Logistics Reports Page-----
const LogisticsReportPage = Loadable(
  lazy(() => import('./pages/dashboard/Logistics/LogisticsReportPage')),
);
const LogisticsContainersPage = Loadable(
  lazy(() => import('./pages/dashboard/Logistics/LogisticsContainerPage')),
);
const LogisticsCratesPage = Loadable(
  lazy(() => import('./pages/dashboard/Logistics/LogisticsCratePage')),
);
const LogisticsDeliveryPage = Loadable(
  lazy(() => import('./pages/dashboard/Logistics/LogisticsDeliveryPage')),
);

// Google Maps Tracking Page
const TrackingContainerMapPage = Loadable(
  lazy(() => import('./pages/dashboard/Logistics/TrackingContainerMapPage')),
);

// App Settings container page
// const AppSettingsContainerPage = Loadable(lazy(() => import('./pages/dashboard/AppSettings/AppSettingsContainerPage')));
// Social pages
// const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
// const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

// const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
// const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));
// const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

const routes = [
  {
    path: '*',
    element: <Login />,
    children: [
      {
        path: '',
        element: <Login />,
      },
    ],
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login2fa',
        element: (
          <GuestGuard>
            <Login2FA />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        ),
      },
      {
        path: 'password-reset',
        element: (
          <GuestGuard>
            <PasswordReset />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'cip-project-grid',
        element: <CustomerProjectDisplayPage />,
      },
      {
        path: 'tracking-maps',
        element: <TrackingContainerMapPage />,
      },
      {
        path: 'calendar',
        element: (
          <PrivateRoute>
            <Calendar />
          </PrivateRoute>
        ),
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: 'egnyte-auth-result',
        element: <EgnyteAuthResult />,
      },
      {
        path: 'productscan',
        element: <ProductDetailsPage />,
      },
      {
        path: 'cip',
        children: [
          {
            path: 'overview',
            element: <CustomerProjectDisplayPage />,
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <PrivateRoute>
                <UserList />
              </PrivateRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <PrivateRoute>
                <UserAdd />
              </PrivateRoute>
            ),
          },
          {
            path: 'edit',
            element: (
              <PrivateRoute>
                <UserEdit />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'projects',
        children: [
          {
            path: 'add',
            element: (
              <PrivateRoute>
                <ProjectAdd />
              </PrivateRoute>
            ),
          },
          {
            path: '',
            element: (
              <PrivateRoute>
                <ProjectList />
              </PrivateRoute>
            ),
          },
          {
            path: 'edit',
            element: (
              <PrivateRoute>
                <ProjectEdit />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'suppliers',
        children: [
          {
            path: 'contracts',
            element: (
              <PrivateRoute>
                <SuppliersContracts />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'contacts',
        children: [
          {
            path: 'add',
            element: (
              <PrivateRoute>
                <ProjectActorAdd />
              </PrivateRoute>
            ),
          },
          {
            path: '',
            element: (
              <PrivateRoute>
                <ProjectActorList />
              </PrivateRoute>
            ),
          },
          {
            path: 'edit',
            element: (
              <PrivateRoute>
                <ProjectActorEdit />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'pm',
        children: [
          {
            path: 'project-submissions',
            element: (
              <PrivateRoute>
                <ProjectSubmissionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'drawing-submissions',
            element: (
              <PrivateRoute>
                <DrawingSubmissionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'site-instructions',
            element: (
              <PrivateRoute>
                <SiteInstructionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'sample-submissions',
            element: (
              <PrivateRoute>
                <SampleSubmissionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'rco-submissions',
            element: (
              <PrivateRoute>
                <RCOSubmissionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'change-order-submissions',
            element: (
              <PrivateRoute>
                <ChangeOrderSubmissionList />
              </PrivateRoute>
            ),
          },
          {
            path: 'rfi-submissions',
            element: (
              <PrivateRoute>
                <RFISubmisisonList />
              </PrivateRoute>
            ),
          },
          {
            path: 'internal-change-order-submissions',
            element: (
              <PrivateRoute>
                <InternalChangeOrderSubmissionList />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'mfg',
        children: [
          {
            path: 'overview',
            element: (
              <PrivateRoute>
                <ManufacturingListPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'material-batches/add',
            element: (
              <PrivateRoute>
                <MaterialBatchAddPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'production-batches/add',
            element: (
              <PrivateRoute>
                <ProductionBatchAddPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'crates/add',
            element: (
              <PrivateRoute>
                <CrateAddPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'containers/add',
            element: (
              <PrivateRoute>
                <ContainerAddPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'logistics',
        children: [
          {
            path: 'reports',
            element: (
              <PrivateRoute>
                <LogisticsReportPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'containers',
            element: (
              <PrivateRoute>
                <LogisticsContainersPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'crates',
            element: (
              <PrivateRoute>
                <LogisticsCratesPage />
              </PrivateRoute>
            ),
          },
          {
            path: 'delivery',
            element: (
              <PrivateRoute>
                <LogisticsDeliveryPage />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" replace />,
  },
];

export default routes;
