import { gql } from '@apollo/client';

export const GET_EGNYTE_SYSTEM_SETTINGS = gql`
  query getSystemSettings {
    getSystemSettings {
      engyteBaseUrl
      engyteClientApiKey
      engyteOauthState
      engyteBaseFilePath
    }
  }
`;

export const GET_ALL_SYSTEM_SETTINGS = gql`
  query getSystemSettings {
    getSystemSettings {
      sentryDsn
      googleMapsApiKey
      googleRecaptchaKey
      engyteBaseUrl
      engyteClientApiKey
      engyteOauthState
      engyteBaseFilePath
    }
  }
`;
