import { Avatar, Box, Button, Card, CardContent, Typography, Container } from '@mui/material';
import PropTypes from 'prop-types';
import useSettings from '../../../../hooks/useSettings';
import StarIcon from '@mui/icons-material/Star';

const SubmissionCreatedModal = (props) => {
  const { handleClose } = props;
  const { settings } = useSettings();

  return (
    <Container maxWidth={settings.compact ? 'xl' : false}>
      <Card>
        <CardContent>
          <Box
            sx={{
              maxWidth: 450,
              mx: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                }}
              >
                <StarIcon fontSize="small" />
              </Avatar>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography align="center" color="textPrimary" variant="h3">
                You are all done
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography align="center" color="textSecondary" variant="subtitle1">
                Submission has been created.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Button color="primary" onClick={handleClose} variant="contained">
                Close
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

SubmissionCreatedModal.propTypes = {
  handleClose: PropTypes.func,
};
export default SubmissionCreatedModal;
