import { gql } from '@apollo/client';

export const GET_CHANGE_ORDERS = gql`
  query getChangeOrders {
    getChangeOrders {
      id
      title
      internalId
      clientDocumentId
      isCoInternal
      isCoOpen
      supplierId
      supplierType
      supplierCost
      clientCost
      sfgCost
      approvalStatus
      to
      from
      submissionDate
      approvalDeadline
      linkedRcoId
      message
      parentId
      attachments {
        filename
        fieldname
        originalname
        encoding
        mimetype
        destination
        path
        size
      }
    }
  }
`;

export const GET_MOST_RECENT_CHANGE_ORDER = gql`
  query getMostRecentChangeOrder {
    getMostRecentChangeOrder
  }
`;

export const ADD_CHANGE_ORDER = gql`
  mutation addChangeOrder(
    $parentId: ID
    $linkedRcoId: ID
    $byUser: ID!
    $title: String!
    $internalId: String
    $clientDocumentId: String
    $isCoInternal: Boolean!
    $isCoOpen: Boolean!
    $supplierId: ID
    $supplierType: String
    $supplierCost: Float
    $sfgCost: Float!
    $clientCost: Float!
    $approvalStatus: String!
    $from: String!
    $to: String!
    $internalParties: [ID]!
    $externalParties: [ID]!
    $submissionDate: String!
    $approvalDeadline: String!
    $message: String
    $attachments: [AttachmentInputType]
    $addCalendarEntry: Boolean!
    $allDay: Boolean
    $isSystemEntry: Boolean
    $shipment: Boolean
    $start: String
    $end: String
    $calendarEntryTitle: String
    $projectId: ID
    $projectAbrv: String
    $department:[String]
    $dateType: String
  ) {
    addChangeOrder(
      parentId: $parentId
      linkedRcoId: $linkedRcoId
      byUser: $byUser
      title: $title
      internalId: $internalId
      clientDocumentId: $clientDocumentId
      isCoInternal: $isCoInternal
      isCoOpen: $isCoOpen
      supplierId: $supplierId
      supplierType: $supplierType
      supplierCost: $supplierCost
      sfgCost: $sfgCost
      clientCost: $clientCost
      approvalStatus: $approvalStatus
      from: $from
      to: $to
      internalParties: $internalParties
      externalParties: $externalParties
      submissionDate: $submissionDate
      approvalDeadline: $approvalDeadline
      message: $message
      attachments: $attachments
      addCalendarEntry: $addCalendarEntry
      allDay: $allDay
      isSystemEntry: $isSystemEntry
      shipment: $shipment
      start: $start
      end: $end
      calendarEntryTitle: $calendarEntryTitle
      projectId: $projectId
      projectAbrv: $projectAbrv
      department: $department
      dateType: $dateType
    ) {
      id
      parentId
      title
      isCoInternal
      supplierCost
      clientCost
      sfgCost
      approvalStatus
      linkedRcoId
      to
      from
      submissionDate
      approvalDeadline
      message
    }
  }
`;

export const UPDATE_CHANGE_ORDER = gql`
  mutation updateChangeOrder(
    $id: ID!
    $parentId: ID
    $title: String
    $clientCost: Float
    $sfgCost: Float
    $approvalStatus: String
    $to: String
    $from: String
    $submissionDate: String
    $approvalDeadline: String
    $linkedRcoId: ID
    $message: String
    $attachments: [AttachmentInputType]
  ) {
    updateChangeOrder(
      id: $id
      parentId: $parentId
      title: $title
      clientCost: $clientCost
      sfgCost: $sfgCost
      approvalStatus: $approvalStatus
      to: $to
      from: $from
      submissionDate: $submissionDate
      approvalDeadline: $approvalDeadline
      linkedRcoId: $linkedRcoId
      message: $message
      attachments: $attachments
    ) {
      id
    }
  }
`;
