import PropTypes from 'prop-types';
// import { useState } from 'react';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@mui/material';
import NavItem from './NavItem';

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        // eslint-disable-next-line no-use-before-define
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  const navItemDefaultProps = {
    active: exactMatch,
    depth,
    icon: item.icon,
    info: item.info,
    path: item.path,
    title: item.title,
    open: false,
  };

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname,
        )
      : false;

    navItemDefaultProps.active = partialMatch;
    navItemDefaultProps.open = partialMatch;

    acc.push(
      <NavItem
        // active={partialMatch}
        // depth={depth}
        // icon={item.icon}
        // info={item.info}
        // key={key}
        // open={partialMatch}
        // path={item.path}
        // title={item.title}
        key={key}
        {...navItemDefaultProps}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        // active={exactMatch}
        // depth={depth}
        // icon={item.icon}
        // info={item.info}
        key={key}
        // path={item.path}
        // title={item.title}
        {...navItemDefaultProps}
      />,
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props;
  // const [titles, setTitles] = useState([]);

  // const checkRole = (role) => {
  //   for (let i = 0; i < titles.length; i++) {
  //     if (role === 'admin' && titles[i] === 'Admin') {
  //       setTitles(titles.concat(''));
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setTitles(titles.concat(title));
  //   checkRole(userData.role);
  // }, [title]);

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
