import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
// import { Integrations as TracingIntegrations } from '@sentry/tracing';
// import * as Tracing from '@sentry/browser';.
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
// import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './api/apolloClient';
import { sentryConfig } from './config';
import { SOFTWARE_VERSION } from './constants';

import { licenseKey } from './devextreme-license';
import config from 'devextreme/core/config';

config({ licenseKey });

Sentry.init({
  release: `plm-frontend@${SOFTWARE_VERSION.major}.${SOFTWARE_VERSION.minor}.${SOFTWARE_VERSION.build}`,
  dsn: sentryConfig.dsn,
  integrations: [Sentry.browserTracingIntegration()],
  // Set environment so its easier to filter debug vs production errors
  environment: sentryConfig.env,
  // debug: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(sentryConfig.sample_rate),
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary showDialog>
      <ApolloProvider client={apolloClient}>
        <HelmetProvider>
          <PersistGate persistor={persistor}>
            <Provider store={store}>
              <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SettingsProvider>
                    <BrowserRouter>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </BrowserRouter>
                  </SettingsProvider>
                </LocalizationProvider>
              </StyledEngineProvider>
            </Provider>
          </PersistGate>
        </HelmetProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
    ,
  </StrictMode>,
);
