// Functions which Redux uses to alter the state
import { GET_PROJECT_DATA, EDIT_PROJECT, SET_DB_NAME, SET_CURRENT_PROJECT_ID, SET_CURRENT_PROJECT_WINDOW_SYSTEM, SET_CURRENT_PROJECT_ABRV } from './types';

export const getProjectData = () => {
  return {
    type: GET_PROJECT_DATA
  };
};

export const editingProjectData = (data) => {
  return {
    type: EDIT_PROJECT,
    payload: data
  };
};

export const setDbName = (data) => {
  return {
    type: SET_DB_NAME,
    payload: data
  };
};

export const setCurrentProjectId = (data) => {
  return {
    type: SET_CURRENT_PROJECT_ID,
    payload: data
  };
};

export const setCurrentProjectWindowSystem = (data) => {
  return {
    type: SET_CURRENT_PROJECT_WINDOW_SYSTEM,
    payload: data
  };
};

export const setCurrentProjectAbrv = (data) => {
  return {
    type: SET_CURRENT_PROJECT_ABRV,
    payload: data
  };
};
