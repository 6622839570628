/**
 * @file utilities.js. Various helper functions to make our lives easier
 * @author Igor C
 * @version 0.1.1
 * @copyright © 2022 SIBER FACADE GROUP
 *
 */
import * as Sentry from '@sentry/react';

const calendarColorGreen = '#51BA70';

const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const isNull = (value) => {
  if (value !== null && typeof value !== 'undefined') {
    return false;
  }
  return true;
};

const isArrayEmpty = (array) => {
  if (!isNull(array) && Array.isArray(array) && array.length > 0) {
    return false;
  }
  return true;
};

const doArraysHaveSameValues = (array1, array2) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) return false;
  if (array1.length !== array2.length) return false;
  let result = true;
  array2.every((v) => {
    if (array1.indexOf(v) === -1) {
      result = false;
      return false;
    }
    return true;
  });
  return result;
};

const isAttachmentRestricted = (filename) => {
  if (isNull(filename)) {
    return true;
  }

  const restrictedWordsArray = ['inv', 'pi'];

  for (let i = 0; i < restrictedWordsArray.length; i++) {
    if (filename.toLowerCase().includes(restrictedWordsArray[i])) {
      return true;
    }
  }

  return false;
};

const applyPagination = (data, page, limit) => {
  return data.slice(page * limit, page * limit + limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const applySort = (data, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = data.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const dateTodayOrPast = (dateToCompare) => {
  const yearNow = new Date().getFullYear();
  const monthNow = new Date().getMonth();
  const dayNow = new Date().getDate();

  const yearDue = new Date(dateToCompare).getFullYear();
  const monthDue = new Date(dateToCompare).getMonth();
  const dayDue = new Date(dateToCompare).getDate();

  let retVal = false;
  if (yearNow > yearDue) {
    retVal = true;
  } else if (monthNow > monthDue) {
    retVal = true;
  } else if (dayNow >= dayDue) {
    retVal = true;
  }

  return retVal;
};

const parseError = (error) => {
  Sentry.captureException(error);
  // try {
  if (isNull(error)) {
    return 'Invalid error parameter';
  } else if (typeof error === 'string') {
    return error;
  } else if (typeof error === 'object') {
    if (!isNull(error.message)) {
      return error.message;
    }
  }
  return 'Unknown Error';
};

const notesFieldGlobalRegex = /^[a-zA-Z0-9\s\-_#@&()/,.;?:!'"<>/]+$/;
const rcoTitleFieldGlobalRegex = /^[a-zA-Z0-9\s\-_#@()/:<>/]+$/;
// const stringFieldGlobalRegex = /^[aA0-zZ9\s-_@#()]+$/;
const stringFieldGlobalRegex = /^[a-zA-Z0-9\s\-_,.#&@()]+$/;
const stringFieldAlphaNumRegex = /^[a-zA-Z0-9]+$/;
const firstLastNameRegex = /^[aA-zZ\s-]+$/;
const companyNameRegex = /^[a-zA-Z0-9\s\-_.]+$/;
const passwordStringRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

const sleep = async (time) => new Promise((res) => setTimeout(res, time));

export {
  calendarColorGreen,
  getRndInteger,
  isNull,
  isArrayEmpty,
  doArraysHaveSameValues,
  isAttachmentRestricted,
  applyPagination,
  getComparator,
  applySort,
  parseError,
  sleep,
  dateTodayOrPast,
  notesFieldGlobalRegex,
  stringFieldGlobalRegex,
  stringFieldAlphaNumRegex,
  firstLastNameRegex,
  companyNameRegex,
  passwordStringRegex,
  rcoTitleFieldGlobalRegex,
};
