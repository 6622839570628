import axios from 'axios';
import { apiServerConfig } from '../config';
import { isNull, parseError } from '../lib/helpers';

const httpClient = axios.create({
  baseURL: apiServerConfig.api_endpoint,
  withCredentials: true,
});

class AuthApi {
  // async login({ email, password }) {
  //   try {
  //     // Query the /login API to try to login with user supplied credentials
  //     const req = { username: email, password };
  //     return httpClient.post('/login', req).then(
  //       (response) => {
  //         if (isNull(response)) {
  //           throw new Error('Please check your email and password');
  //         } else {
  //           return response.data.userId;
  //         }
  //       },
  //       (error) => {
  //         // console.error(error);
  //         throw new Error(parseError(error));
  //       },
  //     );
  //   } catch (err) {
  //     // console.error(err);
  //     throw new Error(parseError(err));
  //   }
  // }

  async login({ email, password }) {
    try {
      // Query the /login API to try to login with user supplied credentials
      const req = { username: email, password };
      return httpClient.post('/login', req).then(
        (response) => {
          // console.log(response);
          if (isNull(response)) {
            throw new Error('Please check your email and password');
          }
          return response.status;
        },
        (error) => {
          // console.error(error);
          throw new Error(parseError(error));
        },
      );
    } catch (err) {
      // console.error(err);
      throw new Error(parseError(err));
    }
  }

  async login2Fa({ email, password, code }) {
    try {
      // Query the /login API to try to login with user supplied credentials
      const req = { username: email, password, code };
      return httpClient.post('/login2fa', req).then(
        (response) => {
          if (isNull(response)) {
            throw new Error('Please check your email and password');
          } else {
            return response.data.userId;
          }
        },
        (error) => {
          // console.error(error);
          throw new Error(parseError(error));
        },
      );
    } catch (err) {
      // console.error(err);
      throw new Error(parseError(err));
    }
  }
  // async login({ email, password }) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       // Query the /login API to try to login with user supplied credentials
  //       const req = { username: email, password };
  //       httpClient.post('/login', req).then(
  //         (response) => {
  //           if (!response) {
  //             reject(new Error('Please check your email and password'));
  //             return;
  //           }
  //           resolve(response.data.userId);
  //         },
  //         (error) => {
  //           console.error(error);
  //           reject(new Error('Please check your email and password'));
  //         }
  //       );
  //     } catch (err) {
  //       console.error('[Auth Api]: ', err);
  //       reject(new Error('Internal server error'));
  //     }
  //   });
  // }

  async logout() {
    try {
      return httpClient.post('/logout').catch((error) => {
        throw new Error(parseError(error));
      });
    } catch (err) {
      throw new Error(parseError(err));
    }
  }

  async passwordRecovery(email) {
    try {
      // Query the /login API to try to login with user supplied credentials
      const req = { username: email };
      return httpClient.post('/passwordRecovery', req).then(
        (response) => {
          if (isNull(response)) {
            throw new Error('Please check your email');
          } else {
            return response.data;
          }
        },
        (error) => {
          throw new Error(parseError(error.response.data.error));
        },
      );
    } catch (err) {
      // console.error(err);
      throw new Error(parseError(err));
    }
  }

  async passwordReset(email, code, password) {
    try {
      // Query the /login API to try to login with user supplied credentials
      const req = { username: email, code, password };
      return httpClient.post('/passwordReset', req).then(
        (response) => {
          if (isNull(response)) {
            throw new Error('Please check your email');
          } else {
            return response.data;
          }
        },
        (error) => {
          // console.error(error);
          // throw new Error(parseError(error));
          throw new Error(parseError(error.response.data.error));
        },
      );
    } catch (err) {
      // console.error(err);
      throw new Error(parseError(err));
    }
  }

  // async logout() {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       httpClient.post('/logout')
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     } catch (err) {
  //       reject(err);
  //     }
  //   });
  // }

  authorizeRoute(route) {
    try {
      // Query the /login API to try to login with user supplied credentials
      const req = { route };
      return httpClient.post('/authorized', req).then(
        (response) => {
          if (isNull(response)) {
            throw new Error('Could not make request');
          }
          return response.data;
        },
        (error) => {
          console.error(error);
          throw new Error('Could not make request');
        },
      );
    } catch (err) {
      console.error(err);
      throw new Error(parseError(err));
    }
  }
}

export const authApi = new AuthApi();
