import {
  GET_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT_SUPPLIERS,
  UPDATE_PROJECT_SUPPLIER_DATA,
} from './queries/projects';
import { dbApi } from './dbApi';
import { uploadApi } from './uploadApi';
import { isNull, isArrayEmpty } from '../lib/helpers';
import * as Sentry from '@sentry/react';

const pattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

const supplierTypes = [
  'Installation Sub-Contractor',
  'Shop Drawings Sub-Contractor',
  'Window Fabricator/Supplier',
  'Door Supplier',
  'Extrusion Supplier',
  'Hardware Supplier',
  'Glass Supplier',
  'Gasket Supplier',
  'Silicone Supplier',
  'Catalyst Supplier',
];

class ProjectApi {
  getProjects() {
    return dbApi
      .query(GET_PROJECTS, dbApi.SYS_DB)
      .then((result) => {
        return result.getProjects;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getProjectSuppliers(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .inputQuery(queryVariables, GET_PROJECT_SUPPLIERS, dbApi.SYS_DB)
      .then((result) => {
        return result.getProjectSuppliers;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  deleteProject(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .mutate(queryVariables, DELETE_PROJECT, dbApi.SYS_DB)
      .then((result) => {
        return result.deleteProject;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  addProject(
    name,
    status,
    // jiraUrl,
    // jiraKey,
    // jiraAuthType,
    abbreviation,
    scope,
    size,
    doorSystems,
    windowSystems,
    uValue,
    acoustic,
    startDate,
    endDate,
    location,
    structuralLoad,
    movement,
    glassMakeup,
    hasPmu,
    hasVmu,
    users,
    client,
    architects,
    generalContractors,
    contractManagers,
    envelopeConsultants,
    shippingLines,
    projectManagers,
    engineersOnRecord,
    installSubContractors,
    shopDrawingSubContractors,
    windowSuppliers,
    doorSuppliers,
    extrusionSuppliers,
    hardwareSuppliers,
    glassSuppliers,
    gasketSuppliers,
    siliconeSuppliers,
    catalystSuppliers,
  ) {
    const queryVariables = {};
    if (!isNull(name) && typeof name === 'string' && name.length > 0) {
      queryVariables.name = name;
    } else {
      throw new Error('Invalid name');
    }
    if (
      !isNull(status) &&
      typeof status === 'string' &&
      status.length > 0 &&
      !status.match(pattern)
    ) {
      queryVariables.status = status;
    } else {
      throw new Error('Invalid status');
    }
    // if (!isNull(jiraUrl) && typeof jiraUrl === 'string') {
    //   queryVariables.jiraUrl = jiraUrl;
    // } else {
    //   throw new Error('Invalid jiraUrl');
    // }
    // if (!isNull(jiraKey) && typeof jiraKey === 'string') {
    //   queryVariables.jiraKey = jiraKey;
    // } else {
    //   throw new Error('Invalid jiraKey');
    // }
    // if (!isNull(jiraAuthType) && typeof jiraAuthType === 'string') {
    //   queryVariables.jiraAuthType = jiraAuthType;
    // } else {
    //   throw new Error('Invalid jiraAuthType');
    // }
    if (!isNull(abbreviation) && typeof abbreviation === 'string') {
      queryVariables.abbreviation = abbreviation;
    } else {
      throw new Error('Invalid abbreviation');
    }
    if (!isArrayEmpty(scope)) {
      queryVariables.scope = scope;
    } else {
      throw new Error('Invalid scope');
    }
    if (!isNull(size) && typeof size === 'string') {
      queryVariables.size = size;
    }
    if (!isArrayEmpty(doorSystems)) {
      queryVariables.doorSystems = doorSystems;
    }
    if (!isArrayEmpty(windowSystems)) {
      queryVariables.windowSystems = windowSystems;
    }
    if (!isNull(uValue) && typeof uValue === 'string') {
      queryVariables.uValue = uValue;
    }
    if (!isNull(acoustic) && typeof acoustic === 'string') {
      queryVariables.acoustic = acoustic;
    }
    if (!isNull(location) && typeof location === 'string') {
      queryVariables.location = location;
    } else {
      throw new Error('Invalid location');
    }

    if (!isNull(structuralLoad) && typeof structuralLoad === 'string') {
      queryVariables.structuralLoad = structuralLoad;
    }
    if (!isNull(movement) && typeof movement === 'string') {
      queryVariables.movement = movement;
    }
    if (!isNull(glassMakeup) && typeof glassMakeup === 'string') {
      queryVariables.glassMakeup = glassMakeup;
    }
    if (!isNull(hasPmu) && typeof hasPmu === 'boolean') {
      queryVariables.hasPmu = hasPmu;
    }
    if (!isNull(hasVmu) && typeof hasVmu === 'boolean') {
      queryVariables.hasVmu = hasVmu;
    }

    if (!isArrayEmpty(users)) {
      queryVariables.users = users;
    } else {
      throw new Error('Invalid users');
    }
    if (!isNull(client) && typeof client === 'string') {
      queryVariables.client = client;
    }
    if (!isArrayEmpty(architects)) {
      queryVariables.architects = architects;
    }
    if (!isArrayEmpty(generalContractors)) {
      queryVariables.generalContractors = generalContractors;
    }
    if (!isArrayEmpty(contractManagers)) {
      queryVariables.contractManagers = contractManagers;
    }
    if (!isArrayEmpty(envelopeConsultants)) {
      queryVariables.envelopeConsultants = envelopeConsultants;
    }
    if (!isArrayEmpty(shippingLines)) {
      queryVariables.shippingLines = shippingLines;
    }
    if (!isArrayEmpty(projectManagers)) {
      queryVariables.projectManagers = projectManagers;
    }
    if (!isArrayEmpty(engineersOnRecord)) {
      queryVariables.engineersOnRecord = engineersOnRecord;
    }
    if (!isArrayEmpty(installSubContractors)) {
      queryVariables.installSubContractors = installSubContractors;
    }
    if (!isArrayEmpty(shopDrawingSubContractors)) {
      queryVariables.shopDrawingSubContractors = shopDrawingSubContractors;
    }
    if (!isArrayEmpty(windowSuppliers)) {
      queryVariables.windowSuppliers = windowSuppliers;
    }
    if (!isArrayEmpty(doorSuppliers)) {
      queryVariables.doorSuppliers = doorSuppliers;
    }
    if (!isArrayEmpty(extrusionSuppliers)) {
      queryVariables.extrusionSuppliers = extrusionSuppliers;
    }
    if (!isArrayEmpty(hardwareSuppliers)) {
      queryVariables.hardwareSuppliers = hardwareSuppliers;
    }
    if (!isArrayEmpty(glassSuppliers)) {
      queryVariables.glassSuppliers = glassSuppliers;
    }
    if (!isArrayEmpty(gasketSuppliers)) {
      queryVariables.gasketSuppliers = gasketSuppliers;
    }
    if (!isArrayEmpty(siliconeSuppliers)) {
      queryVariables.siliconeSuppliers = siliconeSuppliers;
    }
    if (!isArrayEmpty(catalystSuppliers)) {
      queryVariables.catalystSuppliers = catalystSuppliers;
    }
    queryVariables.startDate = startDate;
    queryVariables.endDate = endDate;
    return dbApi
      .mutate(queryVariables, ADD_PROJECT, dbApi.SYS_DB)
      .then((result) => {
        return result.addProject;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  updateProject(
    id,
    name,
    status,
    // leadUserId,
    // jiraUrl,
    // jiraKey,
    // jiraAuthType,
    abbreviation,
    scope,
    size,
    doorSystems,
    windowSystems,
    uValue,
    acoustic,
    startDate,
    endDate,
    location,
    structuralLoad,
    movement,
    glassMakeup,
    hasPmu,
    hasVmu,
    archDrawingsDate,
    structDrawingsDate,
    specificationsDate,
    mechDrawingsDate,
    electDrawingsDate,
    energyDrawingsDate,
    users,
    client,
    architects,
    generalContractors,
    contractManagers,
    envelopeConsultants,
    shippingLines,
    projectManagers,
    engineersOnRecord,
    installSubContractors,
    shopDrawingSubContractors,
    windowSuppliers,
    doorSuppliers,
    extrusionSuppliers,
    hardwareSuppliers,
    glassSuppliers,
    gasketSuppliers,
    siliconeSuppliers,
    catalystSuppliers,
    attachments,
  ) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    }
    if (!isNull(name) && typeof name === 'string' && name.length > 0) {
      queryVariables.name = name;
    }
    if (
      !isNull(status) &&
      typeof status === 'string' &&
      status.length > 0 &&
      !status.match(pattern)
    ) {
      queryVariables.status = status;
    }
    // if (!isNull(leadUserId) && typeof leadUserId === 'string') {
    //   queryVariables.leadUserId = leadUserId;
    // }
    // if (!isNull(jiraUrl) && typeof jiraUrl === 'string') {
    //   queryVariables.jiraUrl = jiraUrl;
    // }
    // if (!isNull(jiraKey) && typeof jiraKey === 'string') {
    //   queryVariables.jiraKey = jiraKey;
    // }
    // if (!isNull(jiraAuthType) && typeof jiraAuthType === 'string') {
    //   queryVariables.jiraAuthType = jiraAuthType;
    // }
    if (!isNull(abbreviation) && typeof abbreviation === 'string') {
      queryVariables.abbreviation = abbreviation.toUpperCase();
    }
    if (!isNull(scope) && typeof scope === 'string') {
      queryVariables.scope = scope;
    }
    if (!isArrayEmpty(scope)) {
      queryVariables.scope = scope;
    }
    if (!isNull(size) && typeof size === 'string') {
      queryVariables.size = size;
    }
    if (!isArrayEmpty(windowSystems)) {
      queryVariables.windowSystems = windowSystems;
    }
    if (!isArrayEmpty(doorSystems)) {
      queryVariables.doorSystems = doorSystems;
    }
    if (!isNull(uValue) && typeof uValue === 'string') {
      queryVariables.uValue = uValue;
    }
    if (!isNull(acoustic) && typeof acoustic === 'string') {
      queryVariables.acoustic = acoustic;
    }
    if (!isNull(location) && typeof location === 'string') {
      queryVariables.location = location;
    }

    if (!isNull(structuralLoad) && typeof structuralLoad === 'string') {
      queryVariables.structuralLoad = structuralLoad;
    }
    if (!isNull(movement) && typeof movement === 'string') {
      queryVariables.movement = movement;
    }
    if (!isNull(glassMakeup) && typeof glassMakeup === 'string') {
      queryVariables.glassMakeup = glassMakeup;
    }
    if (!isNull(hasPmu) && typeof hasPmu === 'boolean') {
      queryVariables.hasPmu = hasPmu;
    }
    if (!isNull(hasVmu) && typeof hasVmu === 'boolean') {
      queryVariables.hasVmu = hasVmu;
    }

    if (!isNull(users)) {
      queryVariables.users = users;
    }
    if (!isNull(client) && typeof client === 'string') {
      queryVariables.client = client;
    }
    if (!isArrayEmpty(architects)) {
      queryVariables.architects = architects;
    }
    if (!isArrayEmpty(generalContractors)) {
      queryVariables.generalContractors = generalContractors;
    }
    if (!isArrayEmpty(contractManagers)) {
      queryVariables.contractManagers = contractManagers;
    }
    if (!isArrayEmpty(envelopeConsultants)) {
      queryVariables.envelopeConsultants = envelopeConsultants;
    }
    if (!isArrayEmpty(shippingLines)) {
      queryVariables.shippingLines = shippingLines;
    }
    if (!isArrayEmpty(projectManagers)) {
      queryVariables.projectManagers = projectManagers;
    }
    if (!isArrayEmpty(engineersOnRecord)) {
      queryVariables.engineersOnRecord = engineersOnRecord;
    }
    if (!isArrayEmpty(installSubContractors)) {
      queryVariables.installSubContractors = installSubContractors;
    }
    if (!isArrayEmpty(shopDrawingSubContractors)) {
      queryVariables.shopDrawingSubContractors = shopDrawingSubContractors;
    }
    if (!isArrayEmpty(windowSuppliers)) {
      queryVariables.windowSuppliers = windowSuppliers;
    }
    if (!isArrayEmpty(doorSuppliers)) {
      queryVariables.doorSuppliers = doorSuppliers;
    }
    if (!isArrayEmpty(extrusionSuppliers)) {
      queryVariables.extrusionSuppliers = extrusionSuppliers;
    }
    if (!isArrayEmpty(hardwareSuppliers)) {
      queryVariables.hardwareSuppliers = hardwareSuppliers;
    }
    if (!isArrayEmpty(glassSuppliers)) {
      queryVariables.glassSuppliers = glassSuppliers;
    }
    if (!isArrayEmpty(gasketSuppliers)) {
      queryVariables.gasketSuppliers = gasketSuppliers;
    }
    if (!isArrayEmpty(siliconeSuppliers)) {
      queryVariables.siliconeSuppliers = siliconeSuppliers;
    }
    if (!isArrayEmpty(catalystSuppliers)) {
      queryVariables.catalystSuppliers = catalystSuppliers;
    }
    if (!isNull(startDate) && typeof startDate === 'string') {
      queryVariables.startDate = startDate;
    }
    if (!isNull(endDate) && typeof endDate === 'string') {
      queryVariables.endDate = endDate;
    }

    if (!isNull(archDrawingsDate) && typeof archDrawingsDate === 'string') {
      queryVariables.archDrawingsDate = archDrawingsDate;
    }
    if (!isNull(structDrawingsDate) && typeof structDrawingsDate === 'string') {
      queryVariables.structDrawingsDate = structDrawingsDate;
    }
    if (!isNull(specificationsDate) && typeof specificationsDate === 'string') {
      queryVariables.specificationsDate = specificationsDate;
    }
    if (!isNull(mechDrawingsDate) && typeof mechDrawingsDate === 'string') {
      queryVariables.mechDrawingsDate = mechDrawingsDate;
    }
    if (!isNull(electDrawingsDate) && typeof electDrawingsDate === 'string') {
      queryVariables.electDrawingsDate = electDrawingsDate;
    }
    if (!isNull(energyDrawingsDate) && typeof energyDrawingsDate === 'string') {
      queryVariables.energyDrawingsDate = energyDrawingsDate;
    }

    if (!isArrayEmpty(attachments)) {
      queryVariables.attachments = attachments;
    }

    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_PROJECT, dbApi.SYS_DB)
        .then((result) => {
          return result.updateProject;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  updateProjectSupplierData(projectId, supplierId, supplierType, contractAmount) {
    const queryVariables = {};
    if (!isNull(projectId) && typeof projectId === 'string' && projectId.length > 0) {
      queryVariables.projectId = projectId;
    }
    if (!isNull(supplierId) && typeof supplierId === 'string' && supplierId.length > 0) {
      queryVariables.supplierId = supplierId;
    }
    if (
      !isNull(supplierType) &&
      typeof supplierType === 'string' &&
      supplierType.length > 0 &&
      supplierTypes.includes(supplierType)
    ) {
      queryVariables.supplierType = supplierType;
    }
    if (!isNull(contractAmount) && typeof contractAmount === 'number') {
      queryVariables.contractAmount = contractAmount;
    }
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_PROJECT_SUPPLIER_DATA, dbApi.SYS_DB)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  // Have to initialize FormData object to pass files
  // add custom error checking here later
  uploadProjectFile(dbName, csvfile) {
    if (csvfile?.length === 0) {
      throw new Error('Invalid data file, file length is zero');
    }
    const formData = new FormData();
    formData.append('dbName', dbName);
    formData.append('csvfile', csvfile);
    return uploadApi
      .uploadData(formData, 'project_upload')
      .then((result) => {
        return result;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }
}

export const projectApi = new ProjectApi();
