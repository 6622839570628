import { SET_SCANNED_PRODUCT_DATA } from '../actions/types';

// Initialize empty object state
const initialState = {
  scannedProductData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // populates userData Redux state with the payload received from the function
    case SET_SCANNED_PRODUCT_DATA:
      return {
        ...state,
        scannedProductData: action.payload
      };
    default:
      return state;
  }
};
