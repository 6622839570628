export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  app_origin: process.env.REACT_APP_AUTH0_APP_ORIGIN,
  api_endpoint: process.env.REACT_APP_AUTH0_API_ENDPOINT,
  role: process.env.REACT_APP_AUTH0_ID_TOKEN_META_ROLE,
  company: process.env.REACT_APP_AUTH0_ID_TOKEN_META_COMPANY,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const apiServerConfig = {
  api_endpoint: process.env.REACT_APP_API_URL,
  api_domain: process.env.REACT_APP_API_DOMAIN,
};

export const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  sample_rate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  env: process.env.REACT_APP_SENTRY_ENV,
  auth_token: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
};

export const googleApiConfig = {
  maps_key: process.env.REACT_APP_GMAP_KEY,
  recaptcha_key: process.env.REACT_APP_RECAPTCHA_KEY,
};

export const egnyteConfig = {
  base_url: process.env.REACT_APP_EGNYTE_BASE_URL,
  oauth_state: process.env.REACT_APP_EGNYTE_OAUTH_STATE,
  base_file_path: process.env.REACT_APP_EGNYTE_BASE_FILE_PATH,
  client_id: process.env.REACT_APP_EGNYTE_CLIENT_ID,
  frontend_url: process.env.REACT_APP_FRONTEND_URL,
};
