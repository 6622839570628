import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TreeList,
  Column,
  Button as DxButton,
  Paging,
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/tree-list';
import { Dialog, DialogContent, Card, Box, Button } from '@mui/material';
import { isNull } from '../../../../lib/helpers';
import ReadOnlyForm from '../../project-management/change-order-submissions/ReadOnlyForm';
// import ChangeApprovalStatus from './ChangeApprovalStatusForm';
// import EditCOForm from './modals/EditCOForm';
import getDate from '../../../../utils/getDate';
// import { Workbook } from 'exceljs';
// import saveAs from 'file-saver';
// import { exportDataGrid } from 'devextreme/excel_exporter';
import NewChangeOrder from '../../project-management/change-order-submissions/NewChangeOrder';
import toast from 'react-hot-toast';
import 'devextreme/dist/css/dx.material.blue.dark.compact.css';
import '../../../../css/styles.css';

const SubmissionTree = (props) => {
  const { submissions, currentProject, refetchChangeOrdersCallback } = props;
  const [currentParentId, setCurrentParentId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  // const [openEditStatusForm, setOpenEditStatusForm] = useState(false);
  // const [editingStatusRow, setEditingStatusRow] = useState({});
  const [revisionCO, setRevisionCO] = useState({});
  const [openReadOnlyForm, setReadOnlyForm] = useState(false);
  const [selectedSubmissionDetails, setSelectedSubmissionDetails] = useState({});
  const allowedPageSizes = [5, 10, 25, 50, 100];

  // const onStatusCellClick = (e) => {
  //   if (e.column.dataField === 'approvalStatus') {
  //     if (e.row && e.row.data.approvalStatus === 'Sent') {
  //       setEditingStatusRow(e.row.data);
  //       setOpenEditStatusForm(true);
  //     }
  //   }
  // };

  const onUpdateCellClick = (e) => {
    // console.log(e);
    if (isNull(e.row.data.internalId) || isNull(e.row.data.internalId)) {
      toast.error('No Internal ID found for submission.');
      return;
    }
    if (e.column.dataField === 'makeRevision') {
      if (e.row.node.hasChildren === true) {
        toast.error('Only last entry can be revised');
      } else if (e.row.data.isCoOpen === false) {
        toast.error('Only open COs can be updated');
      } else {
        setRevisionCO(e.row.data);
        setCurrentParentId(e.row.data.id);
        setOpenDialog(true);
      }
    }
  };

  // const handleCloseEditStatus = () => {
  //   refetchChangeOrdersCallback();
  //   setOpenEditStatusForm(false);
  // };

  const onCellPrepared = (e) => {
    if (e.rowType === 'data' && e.column.dataField === 'dueDate') {
      getDate(e.row.data.approvalDeadline);
    }

    if (e.rowType === 'data') {
      if (e.column.dataField === 'sfgCost' && e.data.sfgCost < 0) {
        e.cellElement.style.color = 'red';
      }

      if (e.column.dataField === 'clientCost' && e.data.clientCost < 0) {
        e.cellElement.style.color = 'red';
      }
    }
  };

  const handleOpenReadOnlyForm = (e) => {
    try {
      setSelectedSubmissionDetails(e.row.data);
    } finally {
      setReadOnlyForm(true);
    }
  };

  const handleOpenReadOnlyFormClose = () => {
    setReadOnlyForm(false);
  };

  const customizeCostText = (cellData) => {
    return `$${cellData.value.toLocaleString('en-US')}`;
  };

  // const customizeApprovalStatus = (data) => {
  //   if (data.data.approvalStatus === 'Sent') {
  //     return (
  //       <Button size="large" color="coSent" className="btnSiberStatus" variant="outlined">
  //         {data.data.approvalStatus}
  //       </Button>
  //     );
  //   }
  //   if (data.data.approvalStatus === 'Approved') {
  //     return (
  //       <Button size="large" color="coApproved" className="btnSiberStatus" variant="outlined">
  //         {data.data.approvalStatus}
  //       </Button>
  //     );
  //   }
  //   if (data.data.approvalStatus === 'Rejected') {
  //     return (
  //       <Button size="large" color="coRejeted" className="btnSiberStatus" variant="outlined">
  //         {data.data.approvalStatus}
  //       </Button>
  //     );
  //   }
  //   return true;
  // };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const customizeCOType = (cellData) => {
    if (cellData.data.isCoOpen === true) {
      return (
        <Button size="large" color="coSent" className="btnSiberStatus" variant="outlined">
          Open
        </Button>
      );
    } else {
      return (
        <Button size="large" color="coRejeted" className="btnSiberStatus" variant="outlined">
          Closed
        </Button>
      );
    }
  };

  const customizeTotalCost = (cellData) => {
    let retVal = '';

    if (cellData.rowType === 'data') {
      if (cellData.data.isCoOpen === true) {
        // this is the root element and OpenCO
        if (isNull(cellData.data.parentId)) {
          let { node } = cellData.row;
          let clientSum = 0;
          let sfgSum = 0;
          /* eslint-disable no-constant-condition */
          while (true) {
            sfgSum += node.data.sfgCost;
            clientSum += node.data.clientCost;
            if (node.hasChildren === false) {
              break;
            } else {
              [node] = node.children;
            }
          }

          if (cellData.column.name === 'totalSfgCost') {
            retVal = `$${sfgSum}`;
          } else if (cellData.column.name === 'totalClientCost') {
            retVal = `$${clientSum}`;
          }
        }
      } else if (cellData.data.isCoOpen === false) {
        // closed RCO just fill in the same numbers as for SFG and Client cost fields
        if (cellData.column.name === 'totalSfgCost') {
          retVal = `$${cellData.data.sfgCost}`;
        } else if (cellData.column.name === 'totalClientCost') {
          retVal = `$${cellData.data.clientCost}`;
        }
      }
    }

    return retVal;
  };

  // const onExporting = (e) => {
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet('Main sheet');
  //   exportDataGrid({
  //     component: e.component,
  //     worksheet,
  //     customizeCell: (options) => {
  //       const excelCell = options;
  //       excelCell.font = { name: 'Arial', size: 12 };
  //       excelCell.alignment = { horizontal: 'left' };
  //     },
  //   }).then(() => {
  //     workbook.xlsx.writeBuffer().then((buffer) => {
  //       saveAs(
  //         new Blob([buffer], { type: 'application/octet-stream' }),
  //         `${new Date().toISOString().substring(0, 10)}_ChangeOrderSubmissionsReport.xlsx`,
  //       );
  //     });
  //   });
  //   e.cancel = true;
  // };

  return (
    <Card>
      <Box sx={{ mx: 2, my: 2 }}>
        <TreeList
          id="changeOrders"
          dataSource={submissions}
          rootValue={-1}
          showRowLines
          showColumnLines
          showBorders
          columnAutoWidth
          keyExpr="id"
          parentIdExpr="parentId"
          allowColumnResizing
          // onExporting={onExporting}
          onCellPrepared={onCellPrepared}
          autoExpandAll
          // onSelectionChanged={onSelectionChange}
          // onCellClick={onStatusCellClick}
        >
          {/* <Export enabled /> */}
          <Scrolling mode="standard" />
          <FilterRow visible={false} />
          <HeaderFilter visible DataSource={submissions} />
          <Paging defaultPageSize={10} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            showInfo
            displayMode="full"
            showNavigationButtons
            showPageSizeSelector
          />
          {/* <ColumnChooser enabled /> */}
          <Column type="buttons" caption="Details">
            <DxButton
              name="entryDetails"
              stylingMode="contained"
              text="Entry Details"
              icon="file"
              onClick={handleOpenReadOnlyForm}
            />
          </Column>
          <Column dataField="internalId" caption="Internal Document ID" alignment="left" />
          <Column dataField="clientDocumentId" caption="Client Document ID" alignment="center" />
          {/* <Column dataField="title" caption="Entry Name" /> */}
          <Column dataField="to" caption="To" alignment="center" />
          <Column dataField="from" caption="From" alignment="center" />
          <Column dataField="submissionDate" caption="Submission Date" alignment="center" />
          {/* <Column
          dataField="approvalDeadline"
          caption="Approval Deadline"
          alignment="center"
          width={170}
        /> */}
          <Column
            dataField="sfgCost"
            caption="SFG Cost"
            alignment="center"
            customizeText={customizeCostText}
          />
          <Column
            dataField="clientCost"
            caption="CLI Cost"
            alignment="center"
            customizeText={customizeCostText}
          />
          <Column
            dataField="totalSfgCost"
            caption="Total SFG Cost"
            alignment="center"
            cellRender={customizeTotalCost}
            // customizeText={customizeCostText}
          />
          <Column
            dataField="totalClientCost"
            caption="Total CLI Cost"
            alignment="center"
            cellRender={customizeTotalCost}
            // customizeText={customizeCostText}
          />
          {/* <Column
          dataField="approvalStatus"
          caption="Status"
          alignment="center"
          cellRender={customizeApprovalStatus}
          width={100}
        /> */}
          <Column
            dataField="isCoOpen"
            caption="Type"
            alignment="center"
            cellRender={customizeCOType}
            // customizeText={customizeTypeText}
            // cellRender={customizeType}
          />
          <Column type="buttons" dataField="makeRevision" caption="Update" alignment="center">
            <DxButton
              name="makeRevision"
              stylingMode="contained"
              text="Update"
              icon="edit"
              onClick={onUpdateCellClick}
            />
          </Column>
        </TreeList>
        {/* <Dialog open={openEditStatusForm} onClose={handleCloseEditStatus}>
        <DialogContent>
          <ChangeApprovalStatus currentCO={editingStatusRow} onClose={handleCloseEditStatus} />
        </DialogContent>
      </Dialog> */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogContent>
            <NewChangeOrder
              handleClose={handleDialogClose}
              currentProject={currentProject}
              parentRecordId={currentParentId}
              // generatedRCOName={null}
              // nextNewRecordId={null}
              // nextRecordIdFromSI={null}
              // isRCOFromSI={false}
              isCoRevision
              revisionCO={revisionCO}
              refetchCallback={refetchChangeOrdersCallback}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openReadOnlyForm}
          onClose={handleOpenReadOnlyFormClose}
        >
          <DialogContent>
            <ReadOnlyForm selectedSubmissionDetails={selectedSubmissionDetails} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

SubmissionTree.propTypes = {
  submissions: PropTypes.array.isRequired,
  currentProject: PropTypes.object,
  refetchChangeOrdersCallback: PropTypes.func.isRequired,
};

export default SubmissionTree;
