import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import { Box, Button, Card, TextField, Typography, MenuItem, InputAdornment } from '@mui/material';
import { isNull, notesFieldGlobalRegex, rcoTitleFieldGlobalRegex } from '../../../../lib/helpers';
import toast from 'react-hot-toast';

const ChangeOrderNewEntryForm = (props) => {
  const { onClose, onNext, parentCallBack, currentProject, submission, disableCancel } = props;
  const [supplierId, setSupplierId] = useState('');
  const [supplierType, setSupplierType] = useState('');
  const [disableClientCostField, setDisableClientCostField] = useState(false);
  const [internalParties, setInternalParties] = useState(submission.internalParties);
  const [externalParties, setExternalParties] = useState(submission.externalParties);

  const handleSetParties = (event) => {
    const newInternalIdArray = [];
    const newExternalIdArray = [];
    let setInternal = false;
    let setExternal = false;
    if (!isNull(event.target.value)) {
      const tmpArr = event.target.value.split('%');
      /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
      if (tmpArr[0] === 'internal') {
        newInternalIdArray.push(tmpArr[1]);
        setInternal = true;
      } else {
        newExternalIdArray.push(tmpArr[1]);
        setSupplierId(tmpArr[1]);
        setSupplierType(tmpArr[2]);
        setExternal = true;
      }
    }

    if (setInternal) {
      setInternalParties(newInternalIdArray);
    }

    if (setExternal) {
      setExternalParties(newExternalIdArray);
    }
  };

  const checkIfSfgCostNegative = (value) => {
    if (value < 0) {
      setDisableClientCostField(true);
    } else if (isNull(submission.linkedRcoId)) {
      // setDisableClientCostField(false);
      toast.error('Only negative COs can be manually created');
    } else {
      setDisableClientCostField(false);
    }
  };

  return (
    <Formik
      initialValues={{
        byUser: submission.byUser,
        internalId: submission.internalId,
        clientDocumentId: submission.clientDocumentId,
        isCoInternal: submission.isCoInternal,
        isCoOpen: submission.isCoOpen,
        title: submission.title,
        sfgCost: submission.sfgCost,
        clientCost: submission.clientCost,
        approvalStatus: submission.approvalStatus,
        from: submission.from,
        to: submission.to,
        internalParties: submission.internalParties,
        externalParties: submission.externalParties,
        submissionDate: submission.submissionDate,
        approvalDeadline: submission.approvalDeadline,
        linkedRcoId: submission.linkedRcoId ? submission.linkedRcoId : null,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(10, 'Minimum 10 characters')
          .max(255, 'Maximum 255 characters')
          .matches(
            rcoTitleFieldGlobalRegex,
            'Min(10) Max(255) characters, Letters, Numbers, Spaces and -_#@&()/,.;?:!<>/ characters only',
          )
          .required('Required'),
        linkedRcoId: Yup.string().nullable(),
        internalId: Yup.string()
          .when('linkedRcoId', {
            is: (value) => !isNull(value),
            then: Yup.string()
              .min(5, 'Minimum 5 characters')
              .max(255, 'Maximum 100 characters')
              .matches(
                notesFieldGlobalRegex,
                'Min(5) Max(100) characters, Letters, Numbers, Spaces and -_#@&()/<>/ characters only',
              )
              .required(),
          })
          .notRequired(),
        clientDocumentId: Yup.string()
          .when('linkedRcoId', {
            is: (value) => !isNull(value),
            then: Yup.string()
              .min(5, 'Minimum 5 characters')
              .max(255, 'Maximum 100 characters')
              .matches(
                notesFieldGlobalRegex,
                'Min(5) Max(100) characters, Letters, Numbers, Spaces and -_#@&()/<>/ characters only',
              )
              .required(),
          })
          .notRequired(),
        isCoOpen: Yup.bool().required(),
        sfgCost: Yup.number().test(
          'maxDigitsAfterDecimal',
          'SFG Cost field must have 2 digits after decimal point or less',
          (number) => Number.isInteger(number * 10 ** 2), // Ensure only 2 digits after decimal point
        ),
        clientCost: Yup.number().test(
          'maxDigitsAfterDecimal',
          'Client Cost field must have 2 digits after decimal point or less',
          (number) => Number.isInteger(number * 10 ** 2), // Ensure only 2 digits after decimal point
        ),
        from: Yup.string().required('Required'),
        to: Yup.string().required('Required'),
        approvalStatus: Yup.string().required('Required'),
        // approvalDeadline: Yup.date().required('Required'),
        submissionDate: Yup.date().required('Required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          const submitValues = values;
          // Set the internal and external parties here
          submitValues.internalParties = internalParties;
          submitValues.externalParties = externalParties;
          if (values.sfgCost < 0) {
            // If SFG cost is negative, set the clientCost to the same value
            submitValues.clientCost = submitValues.sfgCost;
          }
          submitValues.supplierId = supplierId;
          submitValues.supplierType = supplierType;
          parentCallBack(submitValues);
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (error) {
          toast.error(error);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        isValid,
        // dirty
      }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={{ p: 2 }}>
            <Box sx={{ mb: 4 }}>
              <Typography
                color="textPrimary"
                variant="h3"
                align="center"
                marginBottom="10px"
                marginTop="10px"
              >
                New Change Order
              </Typography>
            </Box>
            <Box>
              <Box sx={{ mb: 4 }}>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  label="Title"
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                  required
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <TextField
                  error={Boolean(touched.internalId && errors.internalId)}
                  fullWidth
                  helperText={touched.internalId && errors.internalId}
                  label="Internal Document ID"
                  name="internalId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.internalId}
                  variant="outlined"
                  disabled
                />
                <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.clientDocumentId && errors.clientDocumentId)}
                  fullWidth
                  helperText={touched.clientDocumentId && errors.clientDocumentId}
                  label="Client Document ID"
                  name="clientDocumentId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.clientDocumentId}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <TextField
                  error={Boolean(touched.sfgCost && errors.sfgCost)}
                  fullWidth
                  helperText={touched.sfgCost && errors.sfgCost}
                  label="SFG Cost"
                  name="sfgCost"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  // if we are creating an OPEN type CO, make costs 0  so the math
                  // works later by adding on with the updated PO entries in the tree
                  value={
                    values.linkedRcoId !== null // Chekc is this is a manual CO or a generated CO from RCO
                      ? values.isCoOpen === false // this indicates that this is a generated CO
                        ? (values.sfgCost = submission.sfgCost) // set the cost to the cost indicated in RCO if the CO we create is closed
                        : (values.sfgCost = 0) // set the cost to zero in the CO since we are creating an OPEN CO type
                      : values.sfgCost // Since this is a manual entry, just use the values provided and do the negative check below
                  }
                  variant="outlined"
                  type="number"
                  disabled={disableCancel}
                />
                {checkIfSfgCostNegative(values.sfgCost)}
                <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.clientCost && errors.clientCost)}
                  fullWidth
                  helperText={touched.clientCost && errors.clientCost}
                  label="Client Cost"
                  name="clientCost"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={
                    // if we are creating an OPEN type CO, make costs 0 so the math
                    // works later by adding on with the updated PO entries in the tree
                    values.isCoOpen === false // Chekc if the Type of CO is being set to OPEN OR CLOSED
                      ? values.sfgCost >= 0 // CO type is Closed, so check if the SFGCost was set to negative value
                        ? (values.clientCost = submission.clientCost) // SFGCost is positive, so juse use the value from RCO
                        : values.sfgCost // SFGCost is negative so use the value set by the form
                      : (values.clientCost = 0) // Simple case where the CO is OPEN so set the cost to zero
                  }
                  variant="outlined"
                  type="number"
                  disabled={disableClientCostField || disableCancel}
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                {' '}
                <MobileDatePicker
                  label="Submission Date"
                  onAccept={() => setFieldTouched('submissionDate')}
                  onChange={(date) => setFieldValue('submissionDate', date)}
                  onClose={() => setFieldTouched('submissionDate')}
                  renderInput={(inputProps) => (
                    <TextField variant="outlined" fullWidth {...inputProps} />
                  )}
                  value={values.submissionDate}
                />
                {/* <Box sx={{ mr: 2 }} />
                <MobileDatePicker
                  label="Approval Deadline"
                  onAccept={() => setFieldTouched('approvalDeadline')}
                  onChange={(date) => setFieldValue('approvalDeadline', date)}
                  onClose={() => setFieldTouched('approvalDeadline')}
                  renderInput={(inputProps) => (
                    <TextField variant="outlined" fullWidth {...inputProps} />
                  )}
                  value={values.approvalDeadline}
                /> */}
              </Box>
              <Box sx={{ mb: 4 }} display="flex">
                <TextField
                  error={Boolean(touched.from && errors.from)}
                  fullWidth
                  helperText={touched.from && errors.from}
                  label="From"
                  name="from"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                  required
                  SelectProps={{
                    value: values.from,
                    onChange: (e) => {
                      handleSetParties(e);
                      handleChange(e);
                    },
                  }}
                >
                  {currentProject.client && (
                    <MenuItem
                      key={currentProject.client.id}
                      value={`external%${currentProject.client.id}%${currentProject.client.type}%${currentProject.client.name}`}
                    >
                      {`Client: ${currentProject.client.name}`}
                    </MenuItem>
                  )}
                  {currentProject.contractManagers &&
                    currentProject.contractManagers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Contract Manager: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.generalContractors &&
                    currentProject.generalContractors.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`General Contractor: ${actor.name}`}
                      </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.to && errors.to)}
                  fullWidth
                  helperText={touched.to && errors.to}
                  label="To"
                  name="to"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  required
                  variant="outlined"
                  SelectProps={{
                    value: values.to,
                    onChange: (e) => {
                      handleSetParties(e);
                      handleChange(e);
                    },
                  }}
                >
                  {currentProject.projectManagers &&
                    currentProject.projectManagers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`internal%${actor.id}%${actor.firstName} ${actor.lastName}`}
                      >
                        {`Project Manager: ${actor.firstName} ${actor.lastName}`}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box sx={{ mb: 4 }}>
                <TextField
                  error={Boolean(touched.isCoOpen && errors.isCoOpen)}
                  fullWidth
                  helperText={touched.isCoOpen && errors.isCoOpen}
                  label="Type"
                  name="isCoOpen"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.isCoOpen}
                  select
                  required
                  disabled={values.linkedRcoId === null}
                  variant="outlined"
                  SelectProps={{
                    value: values.isCoOpen,
                    onChange: handleChange,
                  }}
                >
                  <MenuItem value={false}>Closed</MenuItem>
                  <MenuItem value>Open</MenuItem>
                </TextField>
              </Box>
              <Box sx={{ mb: 4 }}>
                <TextField
                  error={Boolean(touched.approvalStatus && errors.approvalStatus)}
                  fullWidth
                  helperText={touched.approvalStatus && errors.approvalStatus}
                  label="Approval Status"
                  name="approvalStatus"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.approvalStatus}
                  select
                  required
                  disabled
                  variant="outlined"
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                </TextField>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                color="primary"
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="outlined"
                size="large"
              >
                Next
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="secondary"
                onClick={onClose}
                variant="outlined"
                disabled={disableCancel}
                size="large"
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ChangeOrderNewEntryForm.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  parentCallBack: PropTypes.func,
  currentProject: PropTypes.object,
  onClose: PropTypes.func,
  submission: PropTypes.object,
  disableCancel: PropTypes.bool,
};

export default ChangeOrderNewEntryForm;
