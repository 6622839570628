/**
 *@changelog
 * 2021-09-2 (Andy Luo) Added Redux function that saves user to the Redux state after successful sign-in + Redux function which removes the user state from Redux after signing out.
 * 2021-09-23 (Igor C) Rewritten to properly and efficiently handle login/logout/etc
 */
import * as Sentry from '@sentry/react';
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/authApi';
import { userApi } from '../api/userApi';
import apolloClient from '../api/apolloClient';
import { isNull, parseError } from '../lib/helpers';
import storage from 'redux-persist/lib/storage';
import toast from 'react-hot-toast';
// Import Redux store
import { store } from '../store';
// import { reject } from 'lodash';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, project } = action.payload;
    // store user.id in local storage
    // Directly manipulate store b/c not a component, inserts user data to Redux State after authorization
    store.dispatch({ type: 'ADD_USER_DATA', payload: user });
    store.dispatch({ type: 'SET_DB_NAME', payload: project.dbName });
    store.dispatch({ type: 'SET_CURRENT_PROJECT_ID', payload: project.currentProjectId });
    store.dispatch({ type: 'SET_CURRENT_PROJECT_ABRV', payload: project.currentProjectAbbr });
    return {
      ...state,
      isAuthenticated: true,
      user,
      project,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  /*
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
  */
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  // register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    const userId = localStorage.getItem('userId');
    if (userId === null) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return;
    }

    try {
      const user = await userApi.getUser(userId);
      if (isNull(user)) {
        throw new Error('No data recieved from server');
      }

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: !!user,
          user,
        },
      });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      // throw new Error(err);
      Sentry.captureException(err);
      toast.error('Error connecting to the server, please try again later.');
    }
    // try {
    //   const getUserPromise = userApi.getUser(userId);
    //   getUserPromise.then((response) => {
    //     const user = response.data;
    //     dispatch({
    //       type: 'INITIALIZE',
    //       payload: {
    //         isAuthenticated: !!user,
    //         user
    //       }
    //     });
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // } catch {
    //   dispatch({
    //     type: 'INITIALIZE',
    //     payload: {
    //       isAuthenticated: false,
    //       user: null
    //     }
    //   });
    // }
  };

  useEffect(() => {
    initialize();
  }, []);

  // const login = async (email, password) => {
  //   try {
  //     const userId = await authApi.login({ email, password });
  //     if (isNull(userId)) {
  //       throw new Error('No data recieved from server');
  //     }
  //     localStorage.setItem('userId', userId);
  //     const response = await userApi.getUser(userId);
  //     if (isNull(response)) {
  //       throw new Error('No data recieved from server');
  //     }
  //     const user = response.data.getUser;
  //     dispatch({
  //       type: 'LOGIN',
  //       payload: {
  //         isAuthenticated: !!user,
  //         user,
  //       },
  //     });
  //     Sentry.setUser({ id: user.id, role: user.role, department: user.department });
  //   } catch (err) {
  //     const message = parseError(err);
  //     if (message.includes('401')) {
  //       throw new Error('Invalid Credentials');
  //     }

  //     if (message.includes('403')) {
  //       throw new Error('Access Denied');
  //     }

  //     throw new Error(parseError(err));
  //   }
  // };

  const login = async (email, password) => {
    try {
      const statusCode = await authApi.login({ email, password });
      // console.log(statusCode);
      if (statusCode !== 200) {
        throw new Error('Authentication Error');
      }
      return true;
    } catch (err) {
      const message = parseError(err);
      Sentry.captureException(err);
      if (message.includes('401')) {
        throw new Error('Invalid Credentials');
      }

      if (message.includes('403')) {
        throw new Error('Access Denied');
      }

      if (message.includes('429')) {
        throw new Error('Too Many Requests');
      }

      if (message.includes('500')) {
        throw new Error('Internal Server Error');
      }

      throw new Error(parseError(err));
    }
  };

  const login2fa = async (email, password, code) => {
    try {
      const userId = await authApi.login2Fa({ email, password, code });
      if (isNull(userId)) {
        throw new Error('No data recieved from server');
      }
      localStorage.setItem('userId', userId);
      const user = await userApi.getUser(userId);
      if (isNull(user)) {
        throw new Error('No data recieved from server');
      }

      const data = await userApi.getUserProjects(userId);
      let project = {
        dbName: '',
        currentProjectId: '',
      };
      data.projects.map((prj) => {
        if (prj.id === user.lastWorkedOnProjectId) {
          project = {
            dbName: prj.dbName,
            currentProjectId: prj.id,
          };
          return true;
        }
        return false;
      });

      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: !!user,
          user,
          project,
        },
      });
      Sentry.setUser({ id: user.id, role: user.role, department: user.department });
    } catch (err) {
      Sentry.captureException(err);
      const message = parseError(err);
      if (message.includes('401')) {
        throw new Error('Invalid Credentials');
      }

      if (message.includes('403')) {
        throw new Error('Access Denied');
      }

      if (message.includes('429')) {
        throw new Error('Too Many Requests');
      }

      if (message.includes('500')) {
        throw new Error('Internal Server Error');
      }

      throw new Error(parseError(err));
    }
  };

  const logout = async () => {
    try {
      await authApi.logout();
    } catch (err) {
      Sentry.captureException(err);
      console.error(parseError(err));
    } finally {
      localStorage.removeItem('userId');
      // terminate any active ApolloClient processes,
      // making it safe to dispose of this ApolloClient instance.
      apolloClient.stop();
      // Clear userData directly from Redux State on logout
      store.dispatch({ type: 'CLEAR_USER_DATA' });
      store.dispatch({ type: 'CLEAR_PROJECT_ID' });
      store.dispatch({ type: 'CLEAR_PROJECT_DATA' });
      store.dispatch({ type: 'CLEAR_EDIT_USER' });
      storage.removeItem('persist:root');
      dispatch({ type: 'LOGOUT' });
      // window.location.reload();
    }
  };

  const passwordRecovery = async (email) => {
    try {
      await authApi.passwordRecovery(email);
    } catch (err) {
      Sentry.captureException(err);
      const message = parseError(err);
      // console.error(message);
      throw new Error(message);
      // if (message.includes('400')) {
      //   throw new Error('Unexpected error');
      // }
      // if (message.includes('401')) {
      //   throw new Error('Account Doesnt Exist');
      // }
      // if (message.includes('403')) {
      //   throw new Error('Account Doesnt Exist');
      // }
      // if (message.includes('404')) {
      //   throw new Error('No response from server');
      // }
    }
  };

  const passwordReset = async (email, code, newPassword) => {
    try {
      await authApi.passwordReset(email, code, newPassword);
    } catch (err) {
      Sentry.captureException(err);
      const message = parseError(err);
      // console.error(message);
      throw new Error(message);
      // if (message.includes('400')) {
      //   throw new Error('Unexpected error');
      // }
      // if (message.includes('401')) {
      //   throw new Error('Account Doesnt Exist');
      // }
      // if (message.includes('403')) {
      //   throw new Error('Account Doesnt Exist');
      // }
      // if (message.includes('404')) {
      //   throw new Error('No response from server');
      // }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        login2fa,
        logout,
        passwordRecovery,
        passwordReset,
        // register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
