import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AdditionalComment from './AdditionalComment';
import InternalChangeOrderNewEntryForm from './InternalChangeOrderNewEntryForm';
import SubmissionCreatedModal from '../common/SubmissionCreatedModal';

const NewChangeOrder = (props) => {
  const { handleClose, currentProject, refetchCallback } = props;
  const [comments, setComments] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [submission, setSubmission] = useState({
    parentId: null,
    title: '',
    internalId: 'CO#',
    clientDocumentId: '',
    isCoInternal: true,
    isCoOpen: false,
    byUser: userData.id,
    sfgCost: 0,
    supplierCost: 0,
    clientCost: 0,
    approvalStatus: 'Approved',
    from: '',
    to: '',
    internalParties: [],
    externalParties: [],
    submissionDate: new Date(),
    approvalDeadline: new Date(),
    linkedRcoId: null,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  const handleSubmissionData = (data) => {
    setSubmission(data);
  };

  const setCommentsCallback = (data) => {
    setComments(data);
  };

  return (
    <div>
      {!completed ? (
        <>
          {activeStep === 0 && (
            <InternalChangeOrderNewEntryForm
              onNext={handleNext}
              parentCallBack={handleSubmissionData}
              currentProject={currentProject}
              onClose={handleClose}
              submission={submission}
            />
          )}
          {activeStep === 1 && (
            <AdditionalComment
              onBack={handleBack}
              onComplete={handleComplete}
              submission={submission}
              comments={comments}
              setCommentsCallback={setCommentsCallback}
              refetchCallback={refetchCallback}
            />
          )}
        </>
      ) : (
        <SubmissionCreatedModal handleClose={handleClose} />
      )}
    </div>
  );
};

NewChangeOrder.propTypes = {
  handleClose: PropTypes.func,
  currentProject: PropTypes.object,
  refetchCallback: PropTypes.func,
};

export default NewChangeOrder;
