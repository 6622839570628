import { GET_EGNYTE_SYSTEM_SETTINGS } from './queries/systemSettings';
import { dbApi } from './dbApi';
import * as Sentry from '@sentry/react';
// import { isNull, isArrayEmpty } from '../lib/helpers';

class SystemSettingsApi {
  getEgnyteSettings() {
    return dbApi
      .query(GET_EGNYTE_SYSTEM_SETTINGS, dbApi.SYS_DB)
      .then((result) => {
        return result.getSystemSettings;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }
}

export const systemSettingsApi = new SystemSettingsApi();
