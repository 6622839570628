import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { googleApiConfig } from '../../../config';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import Reaptcha from 'reaptcha';

const LoginJWT = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { settings } = useSettings();
  const userData = useSelector((state) => state.userData.userData);
  const [isVerified, setIsVerified] = useState(false);
  let currentTheme = 'light';

  if (settings.theme === 'DARK') {
    currentTheme = 'dark';
  }

  const onVerify = () => {
    setIsVerified(true);
  };

  useEffect(() => {
    if (userData.id) {
      navigate('/dashboard');
    }
  }, [userData]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const retVal = await login(values.email, values.password);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          if (retVal) {
            navigate('/authentication/login2fa', {
              state: {
                username: values.email,
              },
            });
          } else {
            throw new Error('Invalid Login');
          }
        } catch (err) {
          Sentry.captureException(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            // setIsVerified(false);
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box sx={{ mt: 2 }}>
            <Reaptcha
              sitekey={googleApiConfig.recaptcha_key}
              onVerify={onVerify}
              theme={currentTheme}
            />
          </Box>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting || !isVerified}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Alert severity="info">Welcome to PLM</Alert>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
