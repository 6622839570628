import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Card,
} from '@mui/material';
import SubmissionTree from '../../lists/ChangeOrderSubmissionTree/ChangeOrderSubmissionTree';
import NewCO from './NewChangeOrder';

const ChangeOrderDisplay = (props) => {
  const { submissions, currentProject, nextNewRecordId, refetchChangeOrdersCallback } = props;
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const createData = (name, sfgCost, clientCost, adjustmentAmount) => {
    return { name, sfgCost, clientCost, adjustmentAmount };
  };

  useEffect(() => {
    const noNegativeSfgCost = submissions.filter((submission) => submission.sfgCost >= 0);
    const noNegativeClientCost = submissions.filter((submission) => submission.clientCost >= 0);
    let totalSfgCostWihtNegativeTmp = 0;
    let totalSfgCostTmp = 0;
    let totalClientCostTmp = 0;

    submissions.forEach((entry) => {
      totalSfgCostWihtNegativeTmp += Math.abs(entry.sfgCost);
    });

    noNegativeSfgCost.forEach((entry) => {
      totalSfgCostTmp += Math.abs(entry.sfgCost);
    });

    noNegativeClientCost.forEach((entry) => {
      totalClientCostTmp += Math.abs(entry.clientCost);
    });

    // console.log((Math.round(totalSfgCostTmp * 1e2) / 1e2).toLocaleString('en-US'));
    // console.log((Math.round(totalClientCostTmp * 1e2) / 1e2).toLocaleString('en-US'));
    // console.log(
    //   Math.round(totalClientCostTmp * 1e2) / 1e2 -
    //     Math.round(totalSfgCostWihtNegativeTmp * 1e2) / 1e2,
    // );

    const data = createData(
      'Approved Total Costs',
      (Math.round(totalSfgCostTmp * 1e2) / 1e2).toLocaleString('en-US'),
      (Math.round(totalClientCostTmp * 1e2) / 1e2).toLocaleString('en-US'),
      (
        Math.round(totalClientCostTmp * 1e2) / 1e2 -
        Math.round(totalSfgCostWihtNegativeTmp * 1e2) / 1e2
      ).toLocaleString('en-US'),
    );

    setRows([data]);
  }, [submissions]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Box
        sx={{
          my: 2,
        }}
      >
        <Card>
          <Box sx={{ mx: 2, my: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell className="RCOSfgCost">SFG Cost</TableCell>
                          <TableCell className="RCOClientCost">Client Cost</TableCell>
                          <TableCell className="RCOAdjustmentAmount">Adjustment Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="changeCalculatorOrderBodyText"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell>{`$${row.sfgCost}`}</TableCell>
                            <TableCell>{`$${row.clientCost}`}</TableCell>
                            <TableCell>
                              <b>{`$${row.adjustmentAmount}`}</b>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
            <SubmissionTree
              submissions={submissions}
              currentProject={currentProject}
              refetchChangeOrdersCallback={refetchChangeOrdersCallback}
            />
            <Box sx={{ mt: 2 }}>
              <Button color="primary" onClick={handleDialogOpen} variant="contained">
                Add
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogContent>
          <NewCO
            handleClose={handleDialogClose}
            currentProject={currentProject}
            nextNewRecordId={nextNewRecordId}
            isCoRevision={false}
            refetchCallback={refetchChangeOrdersCallback}
            linkedRcoId={null}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ChangeOrderDisplay.propTypes = {
  submissions: PropTypes.array,
  currentProject: PropTypes.object,
  nextNewRecordId: PropTypes.number,
  refetchChangeOrdersCallback: PropTypes.func,
};

export default ChangeOrderDisplay;
