import {
  GET_USER,
  GET_USER_EGNYTE_TOKEN,
  GET_USER_PROJECTS_BY_ID,
  GET_USER_PROJECTS_DETAILED_BY_ID,
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  UPDATE_USER_LAST_PROJECT,
  UPDATE_USER_REPORTS_CONFIG,
  UPDATE_USER_AVATAR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_EGNYTE_AUTH_TOKEN,
} from './queries/users';
import { dbApi } from './dbApi';
import { uploadApi } from './uploadApi';
import { isNull, isArrayEmpty } from '../lib/helpers';
import * as Sentry from '@sentry/react';

class UserApi {
  getUsers() {
    return dbApi
      .query(GET_USERS, dbApi.SYS_DB)
      .then((result) => {
        return result.getUsers;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getUser(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .inputQuery(queryVariables, GET_USER, dbApi.SYS_DB)
      .then((result) => {
        return result.getUser;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getUserEgnyteToken(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .inputQuery(queryVariables, GET_USER_EGNYTE_TOKEN, dbApi.SYS_DB)
      .then((result) => {
        return result.getUser;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getUserProjects(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .inputQuery(queryVariables, GET_USER_PROJECTS_BY_ID, dbApi.SYS_DB)
      .then((result) => {
        return result.getUser;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getUserProjectsDetailed(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .inputQuery(queryVariables, GET_USER_PROJECTS_DETAILED_BY_ID, dbApi.SYS_DB)
      .then((result) => {
        return result.getUser;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  addUser(firstName, lastName, email, role, department, company, projects) {
    const queryVariables = {};
    if (!isNull(firstName) && typeof firstName === 'string' && firstName.length > 0) {
      queryVariables.firstName = firstName;
    } else {
      throw new Error('Invalid firstname: only letters, numbers and spaces are allowed');
    }
    if (!isNull(lastName) && typeof lastName === 'string' && lastName.length > 0) {
      queryVariables.lastName = lastName;
    } else {
      throw new Error('Invalid lastname: only letters, numbers and spaces are allowed');
    }
    if (!isNull(email) && typeof email === 'string' && email.length > 0) {
      queryVariables.email = email;
    } else {
      throw new Error('Invalid email: only letters, numbers, @ sign and dot are allowed');
    }
    if (!isNull(role) && typeof role === 'string' && role.length > 0) {
      queryVariables.role = role;
    } else {
      throw new Error('Invalid role');
    }
    if (role !== 'External') {
      if (!isArrayEmpty(department)) {
        queryVariables.department = department;
      } else {
        throw new Error('Invalid department');
      }
    } else {
      queryVariables.department = ['External user'];
    }
    if (!isNull(company) && typeof company === 'string' && company.length > 0) {
      queryVariables.company = company;
    } else {
      throw new Error('Invalid company: only letters, numbers and spaces are allowed');
    }

    if (!isArrayEmpty(projects)) {
      queryVariables.projects = projects;
    }

    return dbApi
      .mutate(queryVariables, ADD_USER, dbApi.SYS_DB)
      .then((result) => {
        return result.addUser;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  deleteUser(id) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .mutate(queryVariables, DELETE_USER, dbApi.SYS_DB)
      .then(() => {
        return true;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  updateUserReportsConfig(id, reportsConfig) {
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid id');
    }
    if (!isArrayEmpty(reportsConfig)) {
      queryVariables.userReportsConfig = reportsConfig;
    } else {
      throw new Error('Invalid id');
    }
    return dbApi
      .mutate(queryVariables, UPDATE_USER_REPORTS_CONFIG, dbApi.SYS_DB)
      .then((result) => {
        return result.updateUserReportsConfig;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  updateUser(id, firstName, lastName, email, role, department, company, disabled, projects) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid parameter');
    }
    if (!isNull(firstName) && typeof firstName === 'string' && firstName.length > 0) {
      queryVariables.firstName = firstName;
    }
    if (!isNull(lastName) && typeof lastName === 'string' && lastName.length > 0) {
      queryVariables.lastName = lastName;
    }
    if (!isNull(email) && typeof email === 'string' && email.length > 0) {
      queryVariables.email = email;
    }
    if (!isNull(role) && typeof role === 'string' && role.length > 0) {
      queryVariables.role = role;
    }
    if (role !== 'External') {
      if (!isArrayEmpty(department)) {
        queryVariables.department = department;
      }
    }
    if (!isNull(company) && typeof company === 'string' && company.length > 0) {
      queryVariables.company = company;
    }
    if (!isNull(disabled) && typeof disabled === 'boolean') {
      queryVariables.disabled = disabled;
    }
    if (!isArrayEmpty(projects)) {
      queryVariables.projects = projects;
    }
    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_USER, dbApi.SYS_DB)
        .then((result) => {
          return result.updateUser;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  updateUserLastProject(id, lastWorkedOnProjectId) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid parameter');
    }
    if (
      !isNull(lastWorkedOnProjectId) &&
      typeof lastWorkedOnProjectId === 'string' &&
      lastWorkedOnProjectId.length > 0
    ) {
      queryVariables.lastWorkedOnProjectId = lastWorkedOnProjectId;
    }
    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_USER_LAST_PROJECT, dbApi.SYS_DB)
        .then((result) => {
          return result.updateUserLastProject;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  updateUserAvatar(id, avatarUrl) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid parameter');
    }
    if (!isNull(avatarUrl) && typeof avatarUrl === 'string' && avatarUrl.length > 0) {
      queryVariables.avatarUrl = avatarUrl;
    }
    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_USER_AVATAR, dbApi.SYS_DB)
        .then((result) => {
          return result.updateUserAvatar;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  updateUserEgnyteAuthToken(id, token) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid parameter');
    }
    if (!isNull(token) && typeof token === 'string' && token.length > 0) {
      queryVariables.egnyteAuthToken = token;
    }
    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_USER_EGNYTE_AUTH_TOKEN, dbApi.SYS_DB)
        .then((result) => {
          return result.updateUserEgnyteAuthToken;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  updateUserPassword(id, currentPassword, newPassword) {
    // check all passed arguments. omit nullable arguments here
    const queryVariables = {};
    if (!isNull(id) && typeof id === 'string' && id.length > 0) {
      queryVariables.id = id;
    } else {
      throw new Error('Invalid parameter');
    }
    if (
      !isNull(currentPassword) &&
      typeof currentPassword === 'string' &&
      currentPassword.length > 0
    ) {
      queryVariables.currentPassword = currentPassword;
    }
    if (!isNull(newPassword) && typeof newPassword === 'string' && newPassword.length > 0) {
      queryVariables.newPassword = newPassword;
    }
    // only send query if there is a need to do this at all
    if (Object.keys(queryVariables).length > 1) {
      return dbApi
        .mutate(queryVariables, UPDATE_USER_PASSWORD, dbApi.SYS_DB)
        .then((result) => {
          return result.updateUserPassword;
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw new Error(err);
        });
    } else {
      throw new Error('No changes detected');
    }
  }

  uploadAvatar(dbName, type, subType, avatar) {
    if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
      throw new Error('Invalid dbName');
    }
    if (isArrayEmpty(avatar)) {
      return [];
    }
    if (isNull(type) || typeof type !== 'string' || type.length <= 0) {
      throw new Error('Invalid type');
    }
    if (avatar[0]?.size === 0) {
      // check for zero length files
      throw new Error('Invalid avatar, file length is zero');
    }

    const formData = new FormData();
    formData.append('dbName', dbName);
    formData.append('type', type);
    formData.append('subType', subType);
    avatar.map((item) => formData.append('attachments', item));
    return uploadApi
      .uploadData(formData, 'upload')
      .then((result) => {
        return result;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }
}

export const userApi = new UserApi();
