import { HttpLink, from, InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { apiServerConfig } from '../config';

// ================================================================

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => data);
});

// ================================================================

/* End of Middleware for removing annoying __typename field from query results, this way is harmless for cache */

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      let msg = '';
      if (extensions !== null && typeof extensions !== 'undefined') {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Additional Info: ${extensions.originalError.message}`,
        );
        msg = `${message} ${extensions.originalError.message}`;
        // console.error(msg);
      } else {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
        msg = message;
        // console.error(msg);
      }
      // throw new Error(message);
      return msg;
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // return networkError;
  }
});

const httpLink = new HttpLink({
  uri: `${apiServerConfig.api_endpoint}/graphql`,
  headers: {
    'x-csrf-protection': '',
  },
  credentials: 'include',
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-and-network',
    // fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    // fetchPolicy: 'network-only',
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([cleanTypenameLink, errorLink, httpLink]),
  defaultOptions,
});

export default apolloClient;
