/**
 *@changelog
 * 2021-09-2 (Andy Luo) Connected the component to Redux store and integrated prop-types
 * 2021-09-2 (Andy Luo) Replaced userData to use Redux state instead of user = useAuth()
 */

import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';

import { getUserData, addUserData } from '../../actions/userActions';
import { apiServerConfig } from '../../config';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

const AccountPopover = (props) => {
  const anchorRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const userData = props.userData.userData; // eslint-disable-line
  const userFullName = `${userData.firstName} ${userData.lastName}`;

  // Calls redux action to add fetched user data to the Redux state

  useEffect(() => {
    props.getUserData(); // eslint-disable-line
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {userData.avatarUrl ? (
          <Avatar
            src={`${apiServerConfig.api_endpoint}/${userData.avatarUrl}`}
            sx={{
              height: 32,
              width: 32,
            }}
          />
        ) : (
          <Avatar
            src="nopic"
            sx={{
              height: 32,
              width: 32,
            }}
          />
        )}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {userFullName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {userData.company}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Account
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  getUserData: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, { getUserData, addUserData })(AccountPopover);
