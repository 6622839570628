export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  // NATURE: 'NATURE'
};

export const SOFTWARE_VERSION = {
  major: '2',
  minor: '0',
  build: '17',
};
