import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { changeOrderApi } from '../../../../api/changeOrderApi';
import { isNull, parseError } from '../../../../lib/helpers';
import EgnyteUploadAdditionalComments from '../common/EgnyteUploadAdditionalComments';
import toast from 'react-hot-toast';

const AdditionalComment = (props) => {
  const { onBack, onComplete, submission, comments, setCommentsCallback, refetchCallback } = props;
  const dbName = useSelector((state) => state.currentProjectDetails.dbName);
  const addCalendarEntry = useSelector((state) => state.submissionEntryData.addCalendarEntry);
  const currentProjectId = useSelector((state) => state.currentProjectDetails.currentProjectId);
  const currentprojectAbrv = useSelector((state) => state.currentProjectDetails.currentProjectAbrv);

  const createRecord = async (uploadedFilesMetadata) => {
    try {
      // The reason for this is because there is no way to pass
      // extra props in <MenuItem> component inside the <Select>
      // or at least no known way for me, so the value prop of
      // <MenuItem>s in Entry and Record forms have the following
      // format: <internal|external>%MongoDbIdOfTheParty%Party name
      // for GB storage, we only need the name which is the last element
      const [, , , to] = submission.to.split('%');
      const [, , from] = submission.from.split('%');

      const data = await changeOrderApi.addChangeOrder(
        dbName,
        submission.parentId,
        submission.title,
        submission.internalId,
        submission.clientDocumentId,
        submission.isCoInternal, // this is internal COs so should be true always
        submission.isCoOpen, // this is closed always for internal COs
        submission.supplierId,
        submission.supplierType,
        submission.supplierCost,
        submission.clientCost,
        submission.sfgCost,
        submission.approvalStatus,
        to,
        from,
        submission.submissionDate,
        submission.approvalDeadline,
        submission.linkedRcoId, // this is always null for internal COs
        comments,
        uploadedFilesMetadata,
        submission.byUser,
        submission.internalParties,
        submission.externalParties,
        addCalendarEntry,
        false,
        true,
        false,
        currentProjectId,
        currentprojectAbrv,
        ['Project management'],
        'PM events'
      );

      if (isNull(data)) {
        throw new Error('No data recieved from server');
      } else {
        onComplete();
        refetchCallback();
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  };

  return (
    <EgnyteUploadAdditionalComments
      onBack={onBack}
      comments={comments}
      setCommentsCallback={setCommentsCallback}
      createRecordCallback={createRecord}
      addToCalendarCheckbox
    />
  );
};

AdditionalComment.propTypes = {
  onBack: PropTypes.func,
  onComplete: PropTypes.func,
  submission: PropTypes.object,
  comments: PropTypes.string,
  setCommentsCallback: PropTypes.func,
  refetchCallback: PropTypes.func,
};

export default AdditionalComment;
