import { ADD_SUBMISSION_ENTRY_DATA, CLEAR_SUBMISSION_ENTRY_DATA, ADD_SUBMISSION_COMMENT, CLEAR_SUBMISSION_COMMENT, ADD_CALENDAR_ENTRY } from './types';

export const addSubmissionEntryData = (data) => ({ type: ADD_SUBMISSION_ENTRY_DATA, payload: data });

export const addSubmissionComment = (data) => ({ type: ADD_SUBMISSION_COMMENT, payload: data });

export const clearSubmisisonEntryData = () => ({ type: CLEAR_SUBMISSION_ENTRY_DATA, payload: {} });

export const clearSubmissionComment = () => ({ type: CLEAR_SUBMISSION_COMMENT, payload: '' });

export const addCalendarEntry = (data) => ({ type: ADD_CALENDAR_ENTRY, payload: data });
