import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, Box, Card } from '@mui/material';
import SubmissionTree from '../../lists/InternalChangeOrderSubmissionTree/InternalChangeOrderSubmissionTree';
import NewInternalChangeOrder from './NewInternalChangeOrder';
import { isNull, isArrayEmpty } from '../../../../lib/helpers';

const ChangeOrderDisplay = (props) => {
  const {
    submissions,
    currentProject,
    nextNewRecordId,
    projectSuppliers,
    refetchChangeOrdersCallback,
  } = props;
  const [totalSfgCost, setTotalSfgCost] = useState([]);
  const [totalSupplierCost, setTotalSupplierCost] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const noNegativeSfgCost = submissions.filter((submission) => submission.sfgCost >= 0);
  const noNegativeSupplierCost = submissions.filter((submission) => submission.supplierCost >= 0);

  // console.log(submissions);

  useEffect(() => {
    setTotalSfgCost([]);
    setTotalSupplierCost([]);
    noNegativeSfgCost.forEach((entry) => {
      if (entry.approvalStatus === 'Approved') {
        setTotalSfgCost((prevState) => [...prevState, entry.sfgCost]);
      }
    });
    noNegativeSupplierCost.forEach((entry) => {
      if (entry.approvalStatus === 'Approved') {
        setTotalSupplierCost((prevState) => [...prevState, entry.supplierCost]);
      }
    });
  }, [submissions]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    // window.location.reload();
  };

  // const closeDialog = () => {
  //   setOpenDialog(false);
  // };

  function createData(name, sfgCost, supplierCost, adjustmentAmount) {
    return { name, sfgCost, supplierCost, adjustmentAmount };
  }

  // const rows = [
  //   createData('Approved Total Costs', Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2, Math.round(totalClientCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2),
  // ];

  const rows = [];

  if (!isArrayEmpty(submissions) && !isNull(projectSuppliers)) {
    // console.log(projectSuppliers);
    rows.push(
      createData(
        'Approved Total Costs',
        Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
        Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
        Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2 -
          Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
      ),
    );
  }

  // const rows = [
  //   createData(
  //     'Approved Total Costs',
  //     Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
  //     Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
  //     (Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2) - (Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2)
  //   ),
  // ];

  // if (!currentProject) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div>
      <Box
        sx={{
          my: 2,
        }}
      >
        <Card>
          <Box
            sx={{
              mx: 2,
              my: 2,
            }}
          >
            {/* <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell className="RCOSfgCost">SFG Cost</TableCell>
                          <TableCell className="RCOClientCost">Supplier Cost</TableCell>
                          <TableCell className="RCOAdjustmentAmount">Adjustment Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="changeCalculatorOrderBodyText"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell>{`$${row.sfgCost}`}</TableCell>
                            <TableCell>{`$${row.supplierCost}`}</TableCell>
                            <TableCell><b>{`$${row.adjustmentAmount}`}</b></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            <br /> */}
            <SubmissionTree
              submissions={submissions}
              currentProject={currentProject}
              refetchChangeOrdersCallback={refetchChangeOrdersCallback}
            />
            <br />
            <div className="addButton">
              <Button color="primary" onClick={handleDialogOpen} variant="contained">
                Add
              </Button>
            </div>
          </Box>
        </Card>
      </Box>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogContent>
          <NewInternalChangeOrder
            handleClose={handleDialogClose}
            currentProject={currentProject}
            nextNewRecordId={nextNewRecordId}
            refetchCallback={refetchChangeOrdersCallback}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ChangeOrderDisplay.propTypes = {
  submissions: PropTypes.array,
  currentProject: PropTypes.object,
  nextNewRecordId: PropTypes.number,
  projectSuppliers: PropTypes.object,
  refetchChangeOrdersCallback: PropTypes.func,
};

export default ChangeOrderDisplay;
