/**
 *@changelog
 * 2021-09-1 (Andy Luo) Created RootReducer to combine all Redux reducers in one file
 * 2021-09-2 (Andy Luo) Integrated Redux-Persist Library to maintain userData
 */

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
// Or sessionStorage
import storage from 'redux-persist/lib/storage';
// import storage from 'redux-persist-indexeddb-storage';

// import { reducer as chatReducer } from '../slices/chat';
// import { reducer as kanbanReducer } from '../slices/kanban';
// import { reducer as mailReducer } from '../slices/mail';
import userReducer from '../reducers/userReducer';
import editUserReducer from '../reducers/editUserReducer';
import editProjectReducer from '../reducers/editProjectReducer';
import projectDbNameReducer from '../reducers/projectDbNameReducer';
import projectAffiliateReducer from '../reducers/projectAffiliateReducer';
import submissionEntryReducer from '../reducers/submissionEntryReducer';
import productReducer from '../reducers/productReducer';
import calendarReducer from '../reducers/calendarReducer';

const persistConfig = {
  key: 'root',
  // storage: storage('plmDb'),
  storage,
  whitelist: [
    'userData',
    'currentProjectDetails',
    'scannedProductData',
    'projectEditData',
    'editUserData',
    'calendar'
  ],
};

const rootReducer = combineReducers({
  // chat: chatReducer,
  // kanban: kanbanReducer,
  // mail: mailReducer,
  userData: userReducer,
  editUserData: editUserReducer,
  projectEditData: editProjectReducer,
  currentProjectDetails: projectDbNameReducer,
  projectAffiliateData: projectAffiliateReducer,
  submissionEntryData: submissionEntryReducer,
  scannedProductData: productReducer,
  calendar: calendarReducer,
});

export default persistReducer(persistConfig, rootReducer);
