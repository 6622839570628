import { ADD_SUBMISSION_ENTRY_DATA, CLEAR_SUBMISSION_ENTRY_DATA, ADD_CALENDAR_ENTRY } from '../actions/types';

// Initialize empty object state
const initialState = {
  submissionEntryData: {},
  addCalendarEntry: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // populates userData Redux state with the payload received from the function
    case ADD_SUBMISSION_ENTRY_DATA:
      return {
        ...state,
        submissionEntryData: action.payload
      };
    case ADD_CALENDAR_ENTRY:
      return {
        ...state,
        addCalendarEntry: action.payload
      };
    case CLEAR_SUBMISSION_ENTRY_DATA:
      return {
        ...state,
        submissionEntryData: {}
      };
    default:
      return state;
  }
};
