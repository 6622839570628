import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
import { useTheme } from '@mui/material/styles';
import '../../../css/styles.css';

const LoadingSpinner = (props) => {
  const { isLoading } = props;
  const theme = useTheme();
  return (
    <div className="generalLoadingSpinner">
      <RingLoader
        color={theme.palette.primary.main}
        loading={isLoading}
        size={150}
        // speedMultiplier={0.2}
        aria-label="Loading Spinner"
      />
    </div>
  );
};

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default LoadingSpinner;
