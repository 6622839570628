import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Formik } from 'formik';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import { Box, Button, Card, TextField, Typography, MenuItem, InputAdornment } from '@mui/material';
import { isNull, notesFieldGlobalRegex } from '../../../../lib/helpers';
import toast from 'react-hot-toast';

const InternalChangeOrderNewEntryForm = (props) => {
  const { onClose, onNext, parentCallBack, currentProject, submission } = props;
  const [supplierId, setSupplierId] = useState('');
  const [supplierType, setSupplierType] = useState('');
  const [internalParties, setInternalParties] = useState(submission.internalParties);
  const [externalParties, setExternalParties] = useState(submission.externalParties);

  const handleSetParties = (event) => {
    const newInternalIdArray = [];
    const newExternalIdArray = [];
    let setInternal = false;
    let setExternal = false;
    if (!isNull(event.target.value)) {
      const tmpArr = event.target.value.split('%');
      /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
      if (tmpArr[0] === 'internal') {
        newInternalIdArray.push(tmpArr[1]);
        setInternal = true;
      } else {
        newExternalIdArray.push(tmpArr[1]);
        setSupplierId(tmpArr[1]);
        setSupplierType(tmpArr[2]);
        setExternal = true;
      }
    }

    if (setInternal) {
      setInternalParties(newInternalIdArray);
    }

    if (setExternal) {
      setExternalParties(newExternalIdArray);
    }
  };

  return (
    <Formik
      initialValues={{
        parentId: submission.parentId,
        byUser: submission.byUser,
        internalId: submission.internalId,
        clientDocumentId: submission.clientDocumentId,
        isCoInternal: submission.isCoInternal, // this is always true here
        isCoOpen: submission.isCoOpen, // this is always false here
        title: submission.title,
        sfgCost: submission.sfgCost,
        clientCost: submission.clientCost, // this is always 0 here
        approvalStatus: submission.approvalStatus, // this is set to 'Approved' always
        supplierId: submission.supplierId,
        supplierType: submission.supplierType,
        supplierCost: submission.supplierCost,
        from: submission.from,
        to: submission.to,
        internalParties: submission.internalParties,
        externalParties: submission.externalParties,
        submissionDate: submission.submissionDate,
        approvalDeadline: submission.approvalDeadline,
        linkedRcoId: submission.linkedRcoId, // this is always null here
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(10, 'Minimum 10 characters')
          .max(255, 'Maximum 255 characters')
          .matches(
            notesFieldGlobalRegex,
            'Min(10) Max(255) characters, Letters, Numbers, Spaces and -_#@&()/,.;?:!<>/ characters only',
          )
          .required('Required'),
        internalId: Yup.string()
          .min(4, 'Minimum 4 characters')
          .max(255, 'Maximum 100 characters')
          .matches(
            notesFieldGlobalRegex,
            'Min(5) Max(100) characters, Letters, Numbers, Spaces and -_#@&()/,.;?:!<>/ characters only',
          )
          .required('Required')
          .test('CO# prefix', 'Internal Document ID should start with CO#', (value) => {
            if (!isNull(value) && value.length > 0) {
              return value.startsWith('CO#');
            } else {
              return false;
            }
          }),
        clientDocumentId: Yup.string()
          .min(5, 'Minimum 5 characters')
          .max(255, 'Maximum 100 characters')
          .matches(
            notesFieldGlobalRegex,
            'Min(5) Max(100) characters, Letters, Numbers, Spaces and -_#@&()/,.;?:!<>/ characters only',
          ),
        sfgCost: Yup.number().test(
          'maxDigitsAfterDecimal',
          'SFG Cost field must have 2 digits after decimal point or less',
          (number) => Number.isInteger(number * 10 ** 2), // Ensure only 2 digits after decimal point
        ),
        // .test(
        //   'Is positive?',
        //   'SFG Cost must be a positive value!',
        //   (value) => value >= 0
        // ),
        supplierCost: Yup.number(),
        // .test(
        //   'Is positive?',
        //   'Client Cost must be a positive value!',
        //   (value) => value >= 0
        // ),
        from: Yup.string().required('Required'),
        to: Yup.string().required('Required'),
        approvalStatus: Yup.string().required('Required'),
        approvalDeadline: Yup.date().required('Required'),
        submissionDate: Yup.date().required('Required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          const submitValues = values;
          // Set the internal and external parties here
          submitValues.internalParties = internalParties;
          submitValues.externalParties = externalParties;
          if (values.sfgCost < 0) {
            // If SFG cost is negative, set the clientCost to the same value
            submitValues.supplierCost = submitValues.sfgCost;
          }
          submitValues.supplierId = supplierId;
          submitValues.supplierType = supplierType;
          parentCallBack(submitValues);
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (error) {
          toast.error(error);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        isValid,
        // dirty
      }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={{ p: 2 }}>
            <Box sx={{ mb: 4 }}>
              <Typography
                color="textPrimary"
                variant="h3"
                align="center"
                marginBottom="10px"
                marginTop="10px"
              >
                New Supplier Change Order
              </Typography>
            </Box>
            <Box>
              <Box sx={{ mb: 4 }}>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  label="Title"
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                  required
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <TextField
                  error={Boolean(touched.internalId && errors.internalId)}
                  fullWidth
                  helperText={touched.internalId && errors.internalId}
                  label="Internal Document ID"
                  name="internalId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.internalId}
                  variant="outlined"
                  required
                />
                <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.clientDocumentId && errors.clientDocumentId)}
                  fullWidth
                  helperText={touched.clientDocumentId && errors.clientDocumentId}
                  label="Supplier Document ID"
                  name="clientDocumentId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.clientDocumentId}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <TextField
                  error={Boolean(touched.sfgCost && errors.sfgCost)}
                  fullWidth
                  helperText={touched.sfgCost && errors.sfgCost}
                  label="SFG Cost"
                  name="sfgCost"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={values.sfgCost}
                  variant="outlined"
                  type="number"
                />
                {/* <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.supplierCost && errors.supplierCost)}
                  fullWidth
                  helperText={touched.supplierCost && errors.supplierCost}
                  label="Supplier Cost"
                  name="supplierCost"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={values.supplierCost}
                  variant="outlined"
                  type="number"
                  disabled={disableCancel}
                /> */}
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                {' '}
                <MobileDatePicker
                  label="Submission Date"
                  onAccept={() => setFieldTouched('submissionDate')}
                  onChange={(date) => setFieldValue('submissionDate', date)}
                  onClose={() => setFieldTouched('submissionDate')}
                  renderInput={(inputProps) => (
                    <TextField variant="outlined" fullWidth {...inputProps} />
                  )}
                  value={values.submissionDate}
                />
                <Box sx={{ mr: 2 }} />
                <MobileDatePicker
                  label="Approval Deadline"
                  onAccept={() => setFieldTouched('approvalDeadline')}
                  onChange={(date) => setFieldValue('approvalDeadline', date)}
                  onClose={() => setFieldTouched('approvalDeadline')}
                  renderInput={(inputProps) => (
                    <TextField variant="outlined" fullWidth {...inputProps} />
                  )}
                  value={values.approvalDeadline}
                />
              </Box>
              <Box sx={{ mb: 4 }} display="flex">
                <TextField
                  error={Boolean(touched.from && errors.from)}
                  fullWidth
                  helperText={touched.from && errors.from}
                  label="From"
                  name="from"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                  required
                  SelectProps={{
                    value: values.from,
                    onChange: (e) => {
                      handleSetParties(e);
                      handleChange(e);
                    },
                  }}
                >
                  {currentProject.projectManagers &&
                    currentProject.projectManagers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`internal%${actor.id}%${actor.firstName} ${actor.lastName}`}
                      >
                        {`Project Manager: ${actor.firstName} ${actor.lastName}`}
                      </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mr: 2 }} />
                <TextField
                  error={Boolean(touched.to && errors.to)}
                  fullWidth
                  helperText={touched.to && errors.to}
                  required
                  label="To"
                  name="to"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                  SelectProps={{
                    value: values.to,
                    onChange: (e) => {
                      handleSetParties(e);
                      handleChange(e);
                    },
                  }}
                >
                  {currentProject.catalystSuppliers &&
                    currentProject.catalystSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Catalyst Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.doorSuppliers &&
                    currentProject.doorSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Door Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.extrusionSuppliers &&
                    currentProject.extrusionSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Extrusion Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.gasketSuppliers &&
                    currentProject.gasketSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Gasket Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.glassSuppliers &&
                    currentProject.glassSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Glass Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.hardwareSuppliers &&
                    currentProject.hardwareSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`HW Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.installSubContractors &&
                    currentProject.installSubContractors.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Install Subcontractors: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.siliconeSuppliers &&
                    currentProject.siliconeSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Silicon Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                  {currentProject.windowSuppliers &&
                    currentProject.windowSuppliers.map((actor) => (
                      <MenuItem
                        key={actor.id}
                        value={`external%${actor.id}%${actor.type}%${actor.name}`}
                      >
                        {`Window Suppliers: ${actor.name}`}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box sx={{ mb: 4 }} display="flex">
                <TextField
                  error={Boolean(touched.approvalStatus && errors.approvalStatus)}
                  fullWidth
                  helperText={touched.approvalStatus && errors.approvalStatus}
                  label="Approval Status"
                  name="approvalStatus"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.approvalStatus}
                  select
                  required
                  disabled
                  variant="outlined"
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                </TextField>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mb: 4 }}>
              <Button
                color="primary"
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="outlined"
                size="large"
              >
                Next
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button color="secondary" onClick={onClose} variant="outlined" size="large">
                Cancel
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

InternalChangeOrderNewEntryForm.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  parentCallBack: PropTypes.func,
  currentProject: PropTypes.object,
  onClose: PropTypes.func,
  submission: PropTypes.object,
  setEmpty: PropTypes.func,
  disableCancel: PropTypes.bool,
  // fileDropZone: PropTypes.element
};

export default InternalChangeOrderNewEntryForm;
