/**
 *@changelog
 * 2021-09-1 (Andy Luo) Created userReducer to perform operations on the userData state controlled by Redux
 * 2021-09-2 (Andy Luo) Added GET, ADD, and CLEAR functions
 */

import { GET_USER_DATA, ADD_USER_DATA, CLEAR_USER_DATA } from '../actions/types';

// Initialize empty object state
const initialState = {
  userData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
      };
    // populates userData Redux state with the payload received from the function
    case ADD_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        userData: {},
      };
    default:
      return state;
  }
};
