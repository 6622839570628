import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { changeOrderApi } from '../../../../api/changeOrderApi';
import { isNull, parseError } from '../../../../lib/helpers';
import EgnyteUploadAdditionalComments from '../common/EgnyteUploadAdditionalComments';
import toast from 'react-hot-toast';

const AdditionalComment = (props) => {
  const {
    onBack,
    onComplete,
    submission,
    comments,
    setCommentsCallback,
    refetchCallback,
    handleApprovalSubmit,
    approvedSubmission,
    approvedRCO,
  } = props;
  const dbName = useSelector((state) => state.currentProjectDetails.dbName);

  const createRecord = async (uploadedFilesMetadata) => {
    try {
      if (!isNull(approvedRCO)) {
        handleApprovalSubmit(
          approvedSubmission.rcoId,
          approvedSubmission.rcoParentId,
          approvedSubmission.curDbName,
          approvedSubmission.approvalStatus,
        );
      }
      // The reason for this is because there is no way to pass
      // extra props in <MenuItem> component inside the <Select>
      // or at least no known way for me, so the value prop of
      // <MenuItem>s in Entry and Record forms have the following
      // format: <internal|external>%MongoDbIdOfTheParty%Party name
      // for GB storage, we only need the name which is the last element
      const [, , to] = submission.to.split('%');
      const [, , , from] = submission.from.split('%');

      const data = await changeOrderApi.addChangeOrder(
        dbName,
        submission.parentId,
        submission.title,
        submission.internalId,
        submission.clientDocumentId,
        submission.isCoInternal, // this is external COs so should be false always
        submission.isCoOpen,
        submission.supplierId,
        submission.supplierType,
        0, // just set the supplier cost to 0, its not used here since the CO is not internal
        submission.clientCost,
        submission.sfgCost,
        submission.approvalStatus,
        to,
        from,
        submission.submissionDate,
        submission.approvalDeadline,
        submission.linkedRcoId,
        comments,
        uploadedFilesMetadata,
        submission.byUser,
        submission.internalParties,
        submission.externalParties,
        false
      );

      if (isNull(data)) {
        throw new Error('No data recieved from server');
      }
      refetchCallback();
      onComplete();
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  };

  return (
    <EgnyteUploadAdditionalComments
      onBack={onBack}
      comments={comments}
      setCommentsCallback={setCommentsCallback}
      createRecordCallback={createRecord}
    />
  );
};

AdditionalComment.propTypes = {
  onBack: PropTypes.func,
  onComplete: PropTypes.func,
  submission: PropTypes.object,
  comments: PropTypes.string,
  setCommentsCallback: PropTypes.func,
  refetchCallback: PropTypes.func,
  // files: PropTypes.array,
  // parentCallBack: PropTypes.func,
  // message: PropTypes.string,
  approvedRCO: PropTypes.object,
  handleApprovalSubmit: PropTypes.func,
  approvedSubmission: PropTypes.object,
};

export default AdditionalComment;
