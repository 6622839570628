import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  Column,
  ColumnChooser,
  Pager,
  Paging,
  Export,
  FilterRow,
  HeaderFilter,
  Button as DxButton,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.dark.compact.css';
import { Dialog, DialogContent, Card, Button } from '@mui/material';
import ReadOnlyForm from '../../project-management/internal-change-order-submissions/ReadOnlyForm';
// import ChangeApprovalStatus from './InternalChangeApprovalStatusForm';
import getDate from '../../../../utils/getDate';
import '../../../../css/styles.css';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const SubmissionTree = (props) => {
  const { submissions } = props;
  // const [openEditStatusForm, setOpenEditStatusForm] = useState(false);
  // const [editingStatusRow, setEditingStatusRow] = useState({});
  const [openReadOnlyForm, setReadOnlyForm] = useState(false);
  const [selectedSubmissionDetails, setSelectedSubmissionDetails] = useState({});
  const allowedPageSizes = [5, 10, 25, 50, 100];

  // const onStatusCellClick = (e) => {
  //   // console.log(e);
  //   if (e.column.dataField === 'approvalStatus') {
  //     if (e.row && e.row.data.approvalStatus === 'Sent') {
  //       setEditingStatusRow(e.row.data);
  //       setOpenEditStatusForm(true);
  //     }
  //   }
  // };

  // const handleCloseEditStatus = () => {
  //   refetchChangeOrdersCallback();
  //   setOpenEditStatusForm(false);
  // };

  const onCellPrepared = (e) => {
    // checks if parentId is null, therefore it is an Entry
    if (e.rowType === 'data' && e.column.dataField === 'dueDate') {
      getDate(e.row.data.approvalDeadline);
    }

    if (e.rowType === 'data' && e.column.dataField === 'sfgCost') {
      if (e.data.sfgCost < 0) {
        e.cellElement.style.color = 'red';
      }
    }
  };

  const handleOpenReadOnlyForm = (e) => {
    try {
      setSelectedSubmissionDetails(e.row.data);
    } finally {
      setReadOnlyForm(true);
    }
  };

  const handleOpenReadOnlyFormClose = () => {
    setReadOnlyForm(false);
  };

  const customizeSFGCostText = (cellData) => {
    return `$${cellData.value.toLocaleString('en-US')}`;
  };

  const customizeApprovalStatus = (data) => {
    if (data.data.approvalStatus === 'Sent') {
      return (
        <Button size="large" color="coSent" className="btnSiberStatus" variant="outlined">
          {data.data.approvalStatus}
        </Button>
      );
    }
    if (data.data.approvalStatus === 'Approved') {
      return (
        <Button size="large" color="coApproved" className="btnSiberStatus" variant="outlined">
          {data.data.approvalStatus}
        </Button>
      );
    }
    if (data.data.approvalStatus === 'Rejected') {
      return (
        <Button size="large" color="coRejeted" className="btnSiberStatus" variant="outlined">
          {data.data.approvalStatus}
        </Button>
      );
    }
    return true;
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: (options) => {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${new Date().toISOString().substring(0, 10)}_SupplierChangeOrderSubmissionsReport.xlsx`,
        );
      });
    });
    e.cancel = true;
  };

  return (
    <Card>
      <DataGrid
        id="supplierChangeOrderSubmissionTree"
        dataSource={submissions}
        rootValue={-1}
        showRowLines
        showColumnLines
        showBorders
        columnAutoWidth
        keyExpr="id"
        parentIdExpr="parentId"
        allowColumnResizing
        onExporting={onExporting}
        onCellPrepared={onCellPrepared}
        autoExpandAll
        // onSelectionChanged={onSelectionChange}
        // onCellClick={onStatusCellClick}
      >
        <Export enabled />
        <FilterRow visible />
        <HeaderFilter visible DataSource={submissions} />
        <Paging defaultPageSize={10} />
        <Pager
          visible
          allowedPageSizes={allowedPageSizes}
          showInfo
          displayMode="full"
          showNavigationButtons
          showPageSizeSelector
        />
        <ColumnChooser enabled />
        <Column type="buttons" caption="Details">
          <DxButton
            name="entryDetails"
            stylingMode="contained"
            text="Entry Details"
            icon="file"
            onClick={handleOpenReadOnlyForm}
          />
        </Column>
        <Column
          dataField="internalId"
          caption="Internal Document ID"
          alignment="center"
          width={180}
        />
        <Column
          dataField="clientDocumentId"
          caption="Supplier Document ID"
          alignment="center"
          width={185}
        />
        <Column dataField="title" caption="Entry Name" />
        <Column dataField="to" caption="To" alignment="center" />
        <Column dataField="from" caption="From" alignment="center" />
        <Column dataField="submissionDate" caption="Submission Date" alignment="center" />
        <Column dataField="approvalDeadline" caption="Approval Deadline" alignment="center" />
        <Column
          dataField="sfgCost"
          customizeText={customizeSFGCostText}
          caption="SFG Cost"
          alignment="center"
        />
        <Column
          dataField="approvalStatus"
          cellRender={customizeApprovalStatus}
          caption="Status"
          alignment="center"
        />
      </DataGrid>
      {/* <Dialog
        open={openEditStatusForm}
        onClose={handleCloseEditStatus}
      >
        <DialogContent>
          <ChangeApprovalStatus
            currentRCO={editingStatusRow}
            onClose={handleCloseEditStatus}
          />
        </DialogContent>
      </Dialog> */}
      <Dialog fullWidth maxWidth="md" open={openReadOnlyForm} onClose={handleOpenReadOnlyFormClose}>
        <DialogContent>
          <ReadOnlyForm selectedSubmissionDetails={selectedSubmissionDetails} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

SubmissionTree.propTypes = {
  submissions: PropTypes.array.isRequired,
  // refetchChangeOrdersCallback: PropTypes.func.isRequired,
};

export default SubmissionTree;
