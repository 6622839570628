import { EDIT_PROJECT, CLEAR_PROJECT_DATA } from '../actions/types';

const initialState = {
  projectEditData: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROJECT:
      return {
        ...state,
        projectEditData: action.payload,
      };
    case CLEAR_PROJECT_DATA:
      return {
        ...state,
        projectEditData: {},
      };
    default:
      return state;
  }
};
