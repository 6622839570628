/**
 *@changelog
 * 2021-09-2 (Andy Luo) Connected the component to Redux store and integrated prop-types
 * 2021-09-2 (Andy Luo) Replaced userData to use Redux state instead of user = useAuth()
 */

import { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  // Button,
  // Button,
  Divider,
  Drawer,
  // Link,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import sections from './Sections';

// Redux function imports
import { getUserData, addUserData } from '../../actions/userActions';
import { apiServerConfig } from '../../config';
import { userApi } from '../../api/userApi';
import { isNull, isArrayEmpty, parseError } from '../../lib/helpers';
import { connect } from 'react-redux';
import useMounted from '../../hooks/useMounted';
import toast from 'react-hot-toast';
import { SOFTWARE_VERSION } from '../../constants';

// Collaprse sidebare
// import { hideSideBarWidth } from './DashboardLayout';

const DashboardSidebar = (props) => {
  // const { openMobile, onMobileClose, paddingWidth } = props;
  const { openMobile, onMobileClose } = props;
  const mounted = useMounted();
  const location = useLocation();
  // const [hide, setHide] = useState(false);
  // const [sidebarWIdth, setSideBarWidth] = useState(280);
  const [sidebarWIdth] = useState(280);
  // const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  // const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const theme = useTheme();
  const { userData } = props.userData; // eslint-disable-line
  const userFullName = `${userData.firstName} ${userData.lastName}`;
  const isMobile = window.screen.width < 600;

  // const handleHide = () => {
  //   hideSideBarWidth();
  //   setSideBarWidth(0);
  // };

  const newSections = sections;

  const [array, setArray] = useState(newSections); // eslint-disable-line
  // const [departmentsArray, setDepartmentsArray] = useState();

  const checkRole = (role, department) => {
    const newArr = [...array];
    if (role === 'Contractor') {
      // This is for contractors
      const deptArray = newArr.filter((item) => item.title !== 'Admin'); // they have no right to see Admin tabs
      deptArray.forEach((item) => {
        if (item.title === 'General') {
          // Remove Calendar for Contractor users
          const itemToRemove = item.items.filter((entry) => entry.title !== 'Calendar');
          item.items = itemToRemove.slice(0);
        }
      });
      setArray(deptArray);
      if (department.includes('Logistics')) {
        // specifically the logistics 3rd party that messes with crates and containers only
        deptArray.forEach((item) => {
          if (item.title === 'Modules') {
            // Find and keep only Modules category
            item.items = item.items.filter((entry) => entry.title === 'Logistics'); // Find and keep only Logistics category
            if (!isArrayEmpty(item.items)) {
              item.items[0].children = item.items[0].children.filter(
                (entry) => entry.title === 'Deliveries',
              );
            }
          }
          if (item.title === 'General') {
            // Find and keep only Modules category
            item.items = item.items.filter((entry) => entry.title !== 'Customer Portal'); // Remove Customer portal entry
          }
        });
        setArray(deptArray);
        return;
      }
    } else {
      // remove the Deliveries entry from the logistics tab
      newArr.forEach((item) => {
        if (item.title === 'Modules') {
          // Find and keep only Modules category
          item.items.forEach((entry) => {
            if (entry.title === 'Logistics') {
              entry.children = entry.children.filter((el) => el.title !== 'Deliveries');
            }
          });
        }
      });
    }

    if (role !== 'Admin') {
      const deptArray = newArr.filter((item) => item.title !== 'Admin');
      deptArray.forEach((item) => {
        if (item.title === 'Modules') {
          let test = item.items.filter((dept) => dept.title !== 'Production');
          item.items = test.slice(0);
          if (role === 'External') {
            item.title = '';
          }
          if (role === 'External' || !department.includes('Project management')) {
            test = item.items.filter((dept) => dept.title !== 'Project Management');
            item.items = test.slice(0);
          }
          if (
            role === 'External' ||
            (!department.includes('Manufacturing') &&
              !department.includes('Project management') &&
              !department.includes('Site Team') &&
              !department.includes('Logistics'))
          ) {
            test = item.items.filter((dept) => dept.title !== 'Manufacturing');
            item.items = test.slice(0);
          }
          if (role === 'External' || role === 'Contractor' || !department.includes('Logistics')) {
            test = item.items.filter((dept) => dept.title !== 'Logistics');
            item.items = test.slice(0);
          }

          //  console.log(item);
          // Remove the deliveries tab in logistics we added just for the 3rd party contractors
          // const index = array.indexOf(5);
        } else if (item.title === 'General') {
          // Hide the customer portal from non customers
          if (role !== 'External') {
            const test = item.items.filter((dept) => dept.title !== 'Customer Portal');
            item.items = test.slice(0);
          }
          // Remove Calendar for External users and Manufacturing department
          if (role === 'External') {
            item.items = item.items.filter((entry) => entry.title !== 'Calendar');
          }
          if (!department.includes('Logistics') && !department.includes('Project management')) {
            item.items = item.items.filter((entry) => entry.title !== 'Calendar');
          }
        }
      });
      setArray(deptArray);
    }
    if (role === 'Project Admin') {
      newArr.forEach((item) => {
        if (item.title === 'Admin') {
          const test = item.items.filter((dept) => dept.title !== 'Users');
          item.items = test.slice(0);
          const projectAdd = item.items.filter((dept) => dept.title === 'Projects');
          item.items[0].children = projectAdd[0].children.slice(0, 1);
        }
        if (item.title === 'Modules') {
          const test = item.items.filter((dept) => dept.title !== 'Production');
          item.items = test.slice(0);
        }
        // if (item.title === 'Modules' && !department.includes('Manufacturing')) {
        //   const test = item.items.filter((dept) => dept.title !== 'Manufacturing');
        //   item.items = test.slice(0);
        // }
        if (item.title === 'Modules' && !department.includes('Logistics')) {
          const test = item.items.filter((dept) => dept.title !== 'Logistics');
          item.items = test.slice(0);
        }
      });
      setArray(newArr);
    }
    if (role === 'Admin') {
      newArr.forEach((item) => {
        if (item.title === 'Modules') {
          const test = item.items.filter((dept) => dept.title !== 'Production');
          item.items = test.slice(0);
        }
      });
      setArray(newArr);
    }
  };

  const fetchUserProjects = useCallback(async () => {
    try {
      const data = await userApi.getUserProjects(userData.id);
      if (isNull(data.projects)) {
        throw new Error('No data recieved from server');
      }
      if (userData.role === 'Admin') {
        return;
      }
      if (isArrayEmpty(data.projects)) {
        const newArr = [...array];
        const deptArray = newArr.filter((item) => item.title !== 'Admin');
        deptArray.forEach((item) => {
          if (item.title === 'Modules') {
            item.items = [];
          }
        });
        setArray(deptArray);
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    fetchUserProjects();
  }, [fetchUserProjects]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // checkPmRole(userData.role, userData.department);
    checkRole(userData.role, userData.department);
    // checkDepartment();
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        {isMobile && (
          <Box
            sx={{
              color: theme.palette.softwareVersionText.main,
              textAlign: 'center',
              fontWeight: '250',
            }}
          >
            {`v.${SOFTWARE_VERSION.major}.${SOFTWARE_VERSION.minor}.${SOFTWARE_VERSION.build}`}
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              {userData.avatarUrl ? (
                <Avatar
                  src={`${apiServerConfig.api_endpoint}/${userData.avatarUrl}`}
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                />
              ) : (
                <Avatar
                  src="nopic"
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                />
              )}
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {userFullName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {userData.company}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {array.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
          {/* <Button onClick={handleHide}>Hide</Button> */}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '70px !Important',
            width: sidebarWIdth,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  // if (xlUp && hide === true) {
  //   return (
  //     <Drawer
  //       anchor="left"
  //       open
  //       PaperProps={{
  //         sx: {
  //           backgroundColor: 'background.paper',
  //           height: 'calc(100% - 64px) !important',
  //           top: '64px !Important',
  //           width: 280
  //         }
  //       }}
  //       variant="permanent"
  //     >
  //       {content}
  //     </Drawer>
  //   );
  // }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  props: PropTypes.any,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  getUserData: PropTypes.func,
  addUserData: PropTypes.func,
  userData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

// Connecting this component to the redux store
export default connect(mapStateToProps, { getUserData, addUserData })(DashboardSidebar);
