import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import getDate from '../../../../utils/getDate';
import QuillEditor from '../../../QuillEditor';
import '../../../../css/styles.css';

const quilEditorModules = {
  toolbar: false,
};

const ReadOnlyForm = (props) => {
  const { selectedSubmissionDetails } = props;
  return (
    <Card>
      <CardHeader title="Internal Change Order Entry Details" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Internal Document ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {selectedSubmissionDetails.internalId}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Supplier Document ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {selectedSubmissionDetails.clientDocumentId}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Title
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {selectedSubmissionDetails.title}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                To
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {selectedSubmissionDetails.to}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                From
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {selectedSubmissionDetails.from}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                SFG Cost
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                $
                {selectedSubmissionDetails.sfgCost !== null &&
                  selectedSubmissionDetails.sfgCost.toLocaleString('en-US')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Submission Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {getDate(selectedSubmissionDetails.submissionDate)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Approval Deadline
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body">
                {getDate(selectedSubmissionDetails.approvalDeadline)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Additional Comments
              </Typography>
            </TableCell>
            <TableCell>
              <QuillEditor
                sx={{ display: 'flex', border: 0 }}
                value={selectedSubmissionDetails.message}
                modules={quilEditorModules}
                readOnly
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Attachments
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                <span className="attachmentCellContainer">
                  {selectedSubmissionDetails.attachments && (
                    <span className="attachmentContainer">
                      {selectedSubmissionDetails.attachments.map((file) => (
                        <span key={file.originalname}>
                          <a
                            className="attachmentLink"
                            target="_blank"
                            rel="noopener noreferrer"
                            // href={`${apiServerConfig.api_endpoint}/${file.path}`}
                            href={`${file.path}`}
                          >
                            {file.originalname}
                            &nbsp;
                          </a>
                        </span>
                      ))}
                    </span>
                  )}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

ReadOnlyForm.propTypes = {
  selectedSubmissionDetails: PropTypes.object,
};

export default ReadOnlyForm;
