import { SET_CALENDAR_FILTERS, SET_CALENDAR_DATE_TYPE_FILTER } from '../actions/types';
// SET_CALENDAR_SHIPMENTS_FILTER,

const initialState = {
  selectedProjectsFilter: [],
  // selectedShipments: [],
  selectedDateTypeFilters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CALENDAR_FILTERS:
      return {
        ...state,
        selectedProjectsFilter: [...action.payload],
      };
    // case SET_CALENDAR_SHIPMENTS_FILTER:
    //   return {
    //     ...state,
    //     selectedShipments: [...action.payload],
    //   };
    case SET_CALENDAR_DATE_TYPE_FILTER:
      return {
        ...state,
        selectedDateTypeFilters: [...action.payload],
      };
    default:
      return state;
  }
};
