import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjects {
    getProjects {
      id
      dbName
      name
      status
      creationDate
      abbreviation
      scope
      size
      doorSystems
      windowSystems
      uValue
      acoustic
      startDate
      endDate
      location
      structuralLoad
      movement
      glassMakeup
      hasPmu
      hasVmu
      archDrawingsDate
      structDrawingsDate
      specificationsDate
      mechDrawingsDate
      electDrawingsDate
      energyDrawingsDate
      users {
        id
        firstName
        lastName
      }
      client {
        id
      }
      architects {
        id
      }
      generalContractors {
        id
      }
      contractManagers {
        id
      }
      envelopeConsultants {
        id
      }
      shippingLines {
        id
      }
      projectManagers {
        id
      }
      engineersOnRecord {
        id
      }
      installSubContractors {
        id
      }
      shopDrawingSubContractors {
        id
      }
      windowSuppliers {
        id
      }
      doorSuppliers {
        id
      }
      extrusionSuppliers {
        id
      }
      hardwareSuppliers {
        id
      }
      glassSuppliers {
        id
      }
      gasketSuppliers {
        id
      }
      siliconeSuppliers {
        id
      }
      catalystSuppliers {
        id
      }
      attachments {
        filename
        fieldname
        originalname
        encoding
        mimetype
        destination
        path
        size
      }
    }
  }
`;

export const GET_PROJECT_SUPPLIERS = gql`
  query getProjectSuppliers($id: ID!) {
    getProjectSuppliers(id: $id) {
      installSubContractors {
        id
        name
        type
      }
      installSubContractorsProjectData {
        supplierId
        contractAmount
      }
      shopDrawingSubContractors {
        id
        name
        type
      }
      shopDrawingSubContractorsProjectData {
        supplierId
        contractAmount
      }
      windowSuppliers {
        id
        name
        type
      }
      windowSuppliersProjectData {
        supplierId
        contractAmount
      }
      doorSuppliers {
        id
        name
        type
      }
      doorSuppliersProjectData {
        supplierId
        contractAmount
      }
      extrusionSuppliers {
        id
        name
        type
      }
      extrusionSuppliersProjectData {
        supplierId
        contractAmount
      }
      hardwareSuppliers {
        id
        name
        type
      }
      hardwareSuppliersProjectData {
        supplierId
        contractAmount
      }
      glassSuppliers {
        id
        name
        type
      }
      glassSuppliersProjectData {
        supplierId
        contractAmount
      }
      gasketSuppliers {
        id
        name
        type
      }
      gasketSuppliersProjectData {
        supplierId
        contractAmount
      }
      siliconeSuppliers {
        id
        name
        type
      }
      siliconeSuppliersProjectData {
        supplierId
        contractAmount
      }
      catalystSuppliers {
        id
        name
        type
      }
      catalystSuppliersProjectData {
        supplierId
        contractAmount
      }
    }
  }
`;

export const UPDATE_PROJECT_SUPPLIER_DATA = gql`
  mutation updateProjectSuplierData(
    $projectId: ID!
    $supplierId: ID!
    $supplierType: String!
    $contractAmount: Float
  ) {
    updateProjectSuplierData(
      id: $projectId
      supplierId: $supplierId
      supplierType: $supplierType
      contractAmount: $contractAmount
    ) {
      supplierId
      contractAmount
    }
  }
`;

export const ADD_PROJECT = gql`
  mutation addProject(
    $name: String!
    $status: String!
    $abbreviation: String!
    $scope: [String]
    $size: String
    $doorSystems: [String]
    $windowSystems: [String]
    $uValue: String
    $acoustic: String
    $startDate: String
    $endDate: String
    $location: String
    $structuralLoad: String
    $movement: String
    $glassMakeup: String
    $hasPmu: Boolean
    $hasVmu: Boolean
    $users: [ID]
    $client: ID
    $architects: [ID]
    $generalContractors: [ID]
    $contractManagers: [ID]
    $envelopeConsultants: [ID]
    $shippingLines: [ID]
    $projectManagers: [ID]
    $engineersOnRecord: [ID]
    $installSubContractors: [ID]
    $shopDrawingSubContractors: [ID]
    $windowSuppliers: [ID]
    $doorSuppliers: [ID]
    $extrusionSuppliers: [ID]
    $hardwareSuppliers: [ID]
    $glassSuppliers: [ID]
    $gasketSuppliers: [ID]
    $siliconeSuppliers: [ID]
    $catalystSuppliers: [ID]
  ) {
    addProject(
      name: $name
      status: $status
      abbreviation: $abbreviation
      scope: $scope
      size: $size
      doorSystems: $doorSystems
      windowSystems: $windowSystems
      uValue: $uValue
      acoustic: $acoustic
      startDate: $startDate
      endDate: $endDate
      location: $location
      structuralLoad: $structuralLoad
      movement: $movement
      glassMakeup: $glassMakeup
      hasPmu: $hasPmu
      hasVmu: $hasVmu
      users: $users
      client: $client
      architects: $architects
      generalContractors: $generalContractors
      contractManagers: $contractManagers
      envelopeConsultants: $envelopeConsultants
      shippingLines: $shippingLines
      projectManagers: $projectManagers
      engineersOnRecord: $engineersOnRecord
      installSubContractors: $installSubContractors
      shopDrawingSubContractors: $shopDrawingSubContractors
      windowSuppliers: $windowSuppliers
      doorSuppliers: $doorSuppliers
      extrusionSuppliers: $extrusionSuppliers
      hardwareSuppliers: $hardwareSuppliers
      glassSuppliers: $glassSuppliers
      gasketSuppliers: $gasketSuppliers
      siliconeSuppliers: $siliconeSuppliers
      catalystSuppliers: $catalystSuppliers
    ) {
      id
      name
      status
      abbreviation
      scope
      size
      doorSystems
      windowSystems
      uValue
      acoustic
      startDate
      endDate
      location
      structuralLoad
      movement
      glassMakeup
      hasPmu
      hasVmu
      users {
        id
      }
      attachments {
        filename
        fieldname
        originalname
        encoding
        mimetype
        destination
        path
        size
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: ID!
    $name: String
    $status: String
    $abbreviation: String
    $scope: [String]
    $size: String
    $doorSystems: [String]
    $windowSystems: [String]
    $uValue: String
    $acoustic: String
    $startDate: String
    $endDate: String
    $location: String
    $structuralLoad: String
    $movement: String
    $glassMakeup: String
    $hasPmu: Boolean
    $hasVmu: Boolean
    $archDrawingsDate: String
    $structDrawingsDate: String
    $specificationsDate: String
    $mechDrawingsDate: String
    $electDrawingsDate: String
    $energyDrawingsDate: String
    $users: [ID]
    $client: ID
    $architects: [ID]
    $generalContractors: [ID]
    $contractManagers: [ID]
    $envelopeConsultants: [ID]
    $shippingLines: [ID]
    $projectManagers: [ID]
    $engineersOnRecord: [ID]
    $installSubContractors: [ID]
    $shopDrawingSubContractors: [ID]
    $windowSuppliers: [ID]
    $doorSuppliers: [ID]
    $extrusionSuppliers: [ID]
    $hardwareSuppliers: [ID]
    $glassSuppliers: [ID]
    $gasketSuppliers: [ID]
    $siliconeSuppliers: [ID]
    $catalystSuppliers: [ID]
    $attachments: [AttachmentInputType]
  ) {
    updateProject(
      id: $id
      name: $name
      status: $status
      abbreviation: $abbreviation
      scope: $scope
      size: $size
      doorSystems: $doorSystems
      windowSystems: $windowSystems
      uValue: $uValue
      acoustic: $acoustic
      startDate: $startDate
      endDate: $endDate
      location: $location
      structuralLoad: $structuralLoad
      movement: $movement
      glassMakeup: $glassMakeup
      hasPmu: $hasPmu
      hasVmu: $hasVmu
      archDrawingsDate: $archDrawingsDate
      structDrawingsDate: $structDrawingsDate
      specificationsDate: $specificationsDate
      mechDrawingsDate: $mechDrawingsDate
      electDrawingsDate: $electDrawingsDate
      energyDrawingsDate: $energyDrawingsDate
      users: $users
      client: $client
      architects: $architects
      generalContractors: $generalContractors
      contractManagers: $contractManagers
      envelopeConsultants: $envelopeConsultants
      shippingLines: $shippingLines
      projectManagers: $projectManagers
      engineersOnRecord: $engineersOnRecord
      installSubContractors: $installSubContractors
      shopDrawingSubContractors: $shopDrawingSubContractors
      windowSuppliers: $windowSuppliers
      doorSuppliers: $doorSuppliers
      extrusionSuppliers: $extrusionSuppliers
      hardwareSuppliers: $hardwareSuppliers
      glassSuppliers: $glassSuppliers
      gasketSuppliers: $gasketSuppliers
      siliconeSuppliers: $siliconeSuppliers
      catalystSuppliers: $catalystSuppliers
      attachments: $attachments
    ) {
      id
      dbName
      name
      status
      abbreviation
      scope
      size
      doorSystems
      windowSystems
      uValue
      acoustic
      startDate
      endDate
      location
      structuralLoad
      movement
      glassMakeup
      hasPmu
      hasVmu
      archDrawingsDate
      structDrawingsDate
      specificationsDate
      mechDrawingsDate
      electDrawingsDate
      energyDrawingsDate
      users {
        id
        firstName
        lastName
      }
      client {
        id
      }
      architects {
        id
      }
      generalContractors {
        id
      }
      contractManagers {
        id
      }
      envelopeConsultants {
        id
      }
      shippingLines {
        id
      }
      projectManagers {
        id
      }
      engineersOnRecord {
        id
      }
      installSubContractors {
        id
      }
      shopDrawingSubContractors {
        id
      }
      windowSuppliers {
        id
      }
      doorSuppliers {
        id
      }
      extrusionSuppliers {
        id
      }
      hardwareSuppliers {
        id
      }
      glassSuppliers {
        id
      }
      gasketSuppliers {
        id
      }
      siliconeSuppliers {
        id
      }
      catalystSuppliers {
        id
      }
      attachments {
        filename
        fieldname
        originalname
        encoding
        mimetype
        destination
        path
        size
      }
    }
  }
`;
