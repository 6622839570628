import Egnyte from 'egnyte-js-sdk/dist/egnyte.min';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { isNull, parseError, sleep } from '../lib/helpers';

const chunkSizeInBytes = 10485760; // 10Mb file chunk
const singleChunkUploadSizeLimitInBytes = 10485760; // 104857600; // Files bigger than 10Mb will get chunked

// Egnyte API path for chunked upload.
// This should be preceeded by https://userdomain.egnyte.com/ and
// superceeded by the folder path to where the upload should happen
// Example: https://userdomain.egnyte.com/pubapi/v1/fs-content-chunked/Shared/Test
const egnyteApiCreateFileChuked = 'pubapi/v1/fs-content-chunked';
// Same as above but for direct file upload without chunking
const egnyteApiCreateFile = 'pubapi/v1/fs-content';

// const httpGet = async (url, headers = {}, token) => {
//   try {
//     const AuthStr = `Bearer ${token}`;
//     const response = await axios.get(url, {
//       headers: { Authorization: AuthStr, ...headers },
//     });
//     if (response) {
//       return response;
//     } else {
//       console.error('httpGet ERROR:', response);
//     }
//   } catch (error) {
//     console.error('httpGet ERROR:', error);
//     return error.response;
//   }
// };

const httpPost = async (token, url, data, headers = {}) => {
  let retVal;
  try {
    const AuthStr = `Bearer ${token}`;
    const response = await axios.post(url, data, {
      headers: {
        Authorization: AuthStr,
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    });
    if (response) {
      retVal = response;
    } else {
      console.error('httpPost ERROR:', response);
    }
  } catch (error) {
    Sentry.captureException(error);
    retVal = error.response;
  }

  return retVal;
};

class EgnyteApi {
  async fileUpload(baseUrl, token, uploadFolderPath, files, fileProgressIndicatorCallback) {
    const retVal = [];
    try {
      const filesToUpload = [...files];
      const egnyte = Egnyte.init(baseUrl, { token });

      for (let i = 0; i < filesToUpload.length; ++i) {
        const file = filesToUpload[i];

        if (file.size > singleChunkUploadSizeLimitInBytes) {
          let uploadId;
          let chunkNum = 1;
          let currentChunkStartByte = 0;
          let currentChunkFinalByte = chunkSizeInBytes > file.size ? file.size : chunkSizeInBytes;
          const totallChunks = file.size / chunkSizeInBytes;

          const isFinalChunk = () => currentChunkStartByte + chunkSizeInBytes >= file.size;
          if (!isNull(fileProgressIndicatorCallback)) {
            fileProgressIndicatorCallback(1, totallChunks, file.name);
          }

          // let j = 0;
          /* eslint-disable no-constant-condition */
          while (true) {
            const remainingBytes = file.size - currentChunkFinalByte;
            const chunk = file.slice(currentChunkStartByte, currentChunkFinalByte);
            const headers = {};
            if (isFinalChunk()) {
              headers['X-Egnyte-Last-Chunk'] = true;
            }
            headers['X-Egnyte-Chunk-Num'] = chunkNum;
            if (uploadId) {
              headers['X-Egnyte-Upload-Id'] = uploadId;
            }

            const uploadPath = `${baseUrl}/${egnyteApiCreateFileChuked}/${uploadFolderPath}/${file.name}`;
            /* eslint-disable no-await-in-loop */
            const response = await httpPost(token, uploadPath, { file: chunk }, headers);
            if (response?.status === 401) {
              throw new Error('Expired session. Please re-login');
            } else {
              if (!uploadId && response?.headers['x-egnyte-upload-id']) {
                uploadId = response.headers['x-egnyte-upload-id'];
              }
              if (currentChunkFinalByte === file.size) {
                if (response?.headers['x-egnyte-chunk-sha512-checksum']) {
                  if (response?.data?.checksum) {
                    // console.log(file.name, response.data.checksum);
                  }
                  // calculate sha512 here to make sure file was uploaded correctly
                } else {
                  throw new Error(`${file.name} - File upload failed`);
                }
                break;
              } else if (remainingBytes < chunkSizeInBytes) {
                currentChunkStartByte = currentChunkFinalByte;
                currentChunkFinalByte = currentChunkStartByte + remainingBytes;
                ++chunkNum;
              } else {
                currentChunkStartByte = currentChunkFinalByte;
                currentChunkFinalByte = currentChunkStartByte + chunkSizeInBytes;
                ++chunkNum;
              }
              if (!isNull(fileProgressIndicatorCallback)) {
                fileProgressIndicatorCallback(chunkNum, totallChunks, file.name);
              }
              //   if ((j + 1) % 2 === 0) {
              //     await sleep(400);
              //   }
              // ++j;
            }
          }
        } else {
          const uploadPath = `${baseUrl}/${egnyteApiCreateFile}/${uploadFolderPath}/${file.name}`;
          if (!isNull(fileProgressIndicatorCallback)) {
            fileProgressIndicatorCallback(i, filesToUpload.length, file.name);
          }
          const response = await httpPost(token, uploadPath, { file });
          if (response?.status === 401) {
            throw new Error('Expired session. Please re-login');
          } else {
            if (response?.data?.checksum) {
              // calculate sha512 here to make sure file was uploaded correctly
              // console.log(file.name, response.data.checksum);
            } else {
              throw new Error(`${file.name} - File upload failed`);
            }
            if ((i + 1) % 2 === 0) {
              await sleep(400);
            }
          }
        } // End of upload either chunked or whole

        // If we are here the file was fully uploaded either one way or another.
        // Generate the links for the file we just uploaded and save it to be returned to the caller
        const newLink = await egnyte.API.link.createLink({
          path: `${uploadFolderPath}/${file.name}`,
          type: 'file',
          accessibility: 'domain',
        });
        // console.log(newLink);
        if (newLink) {
          retVal.push({
            originalname: file.name,
            mimetype: file.type,
            destination: uploadFolderPath[i],
            size: file.size,
            filename: file.name,
            path: newLink.links[0].url,
          });
        }
        if ((i + 1) % 2 === 0) {
          await sleep(400);
        }
      }

      if (!isNull(fileProgressIndicatorCallback)) {
        fileProgressIndicatorCallback(0, 10, '');
      }
      return retVal;
    } catch (error) {
      throw new Error(parseError(error));
    }
  }

  async getLinks(baseUrl, token, filesToGetLinksFor, fileProgressIndicatorCallback) {
    // If we are here the file was fully uploaded either one way or another.
    // Generate the links for the file we just uploaded and save it to be returned to the caller
    const retVal = [];
    try {
      const egnyte = Egnyte.init(baseUrl, { token });
      for (let i = 0; i < filesToGetLinksFor.length; i += 1) {
        if (!isNull(fileProgressIndicatorCallback)) {
          fileProgressIndicatorCallback(i, filesToGetLinksFor.length, filesToGetLinksFor[i].name);
        }
        const newLink = await egnyte.API.link.createLink({
          path: `${filesToGetLinksFor[i].path}`,
          type: 'file',
          accessibility: 'domain',
        });
        if (newLink) {
          retVal.push({
            originalname: filesToGetLinksFor[i].name,
            // mimetype: file.type,
            destination: filesToGetLinksFor[i].path,
            size: filesToGetLinksFor[i].size,
            filename: filesToGetLinksFor[i].name,
            path: newLink.links[0].url,
          });
        }
        if ((i + 1) % 2 === 0) {
          await sleep(400);
        }
      }

      if (!isNull(fileProgressIndicatorCallback)) {
        fileProgressIndicatorCallback(0, 10, '');
      }
    } catch (error) {
      throw new Error(parseError(error));
    }

    return retVal;
  }

  // async getUserInfo(baseUrl, token, headers = {}) {
  //   let retVal;
  //   try {
  //     const AuthStr = `Bearer ${token}`;
  //     const response = await axios.post(`${baseUrl}/pubapi/v1/userinfo`, {
  //       headers: {
  //         Authorization: AuthStr,
  //         // 'Content-Type': 'multipart/form-data',
  //         ...headers,
  //       },
  //     });
  //     if (response) {
  //       retVal = response;
  //     } else {
  //       console.error('httpPost ERROR:', response);
  //     }
  //   } catch (error) {
  //     retVal = error.response;
  //   }

  //   return retVal;
  // }
}

export const egnyteApi = new EgnyteApi();
