// import  { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  Column,
  ColumnChooser,
  Pager,
  Paging,
  Export,
  FilterRow,
  HeaderFilter
  // Button
} from 'devextreme-react/data-grid';

// import DataSource from 'devextreme/data/data_source';

import 'devextreme/dist/css/dx.material.blue.dark.compact.css';
import {
  Card
} from '@mui/material';
import '../../../../css/styles.css';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const SubmissionTree = (props) => {
  const { reportData } = props;

  const onCellPrepared = (e) => {
    // checks if parentId is null, therefore it is an Entry
    if (e.rowType === 'data' && e.column.dataField === 'adjustedAmount') {
      if (e.data.adjustedAmount < 0) {
        e.cellElement.style.color = 'red';
      }
    }
  };

  const customizeDollarValueText = (cellData) => {
    return (`$${cellData.value.toLocaleString('en-US')}`);
  };

  const allowedPageSizes = [5, 10, 25, 50];

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: (options) => {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      }
    }).then(() => {
      workbook.xlsx.writeBuffer()
        .then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${(new Date()).toISOString().substring(0, 10)}_SupplierContractAdjustmentsReport.xlsx`);
        });
    });
    e.cancel = true;
  };

  return (
    <Card>
      <DataGrid
        id="supplierChangeOrderReportTree"
        dataSource={reportData}
        rootValue={-1}
        showRowLines
        showColumnLines
        showBorders
        columnAutoWidth
        keyExpr="id"
        parentIdExpr="parentId"
        allowColumnResizing
        onExporting={onExporting}
        onCellPrepared={onCellPrepared}
        autoExpandAll
      >
        <Export enabled />
        <FilterRow visible />
        <HeaderFilter
          visible
          DataSource={reportData}
        />
        <Paging defaultPageSize={10} />
        <Pager
          visible
          allowedPageSizes={allowedPageSizes}
          showInfo
          displayMode="full"
          showNavigationButtons
          showPageSizeSelector
        />
        <ColumnChooser enabled />
        <Column
          dataField="name"
          caption="Supplier Name"
          alignment="center"
        />
        <Column
          dataField="type"
          caption="Supplier Type"
          alignment="center"
        />
        <Column
          dataField="contractAmount"
          caption="Original Contract Amount"
          alignment="center"
          customizeText={customizeDollarValueText}
        />
        <Column
          dataField="adjustedAmount"
          caption="Adjustment Amount"
          alignment="center"
          customizeText={customizeDollarValueText}
        />
        <Column
          dataField="newContractCost"
          caption="New Contract Amount"
          alignment="center"
          customizeText={customizeDollarValueText}
        />
      </DataGrid>
    </Card>
  );
};

SubmissionTree.propTypes = {
  reportData: PropTypes.array.isRequired,
};

export default SubmissionTree;
