// import ChartPieIcon from '../../icons/ChartPie';
import Dashboard from '@mui/icons-material/Dashboard';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Contacts from '@mui/icons-material/Contacts';
import Apps from '@mui/icons-material/Apps';
import ArchiveIcon from '../../icons/Archive';
import BriefcaseLogo from '../../icons/Briefcase';
import BusinessLogo from '@mui/icons-material/Business';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import MapIcon from '@mui/icons-material/Map';
import LanguageSharp from '@mui/icons-material/LanguageSharp';
// import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import FactoryIcon from '@mui/icons-material/Grid4x4';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
// import SettingsIcon from '@mui/icons-material/Settings';
// import ProjectList from '../../pages/dashboard/ProjectList';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <Dashboard fontSize="small" />,
      },
      // {
      //   title: 'Analytics',
      //   path: '/dashboard/analytics',
      //   icon: <ChartPieIcon fontSize="small" />,
      // },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: 'Customer Portal',
        path: '/dashboard/cip',
        icon: <ViewModuleIcon fontSize="small" />,
        children: [
          {
            title: 'Project Data Grid',
            path: '/dashboard/cip/overview',
          },
        ],
      },
      {
        title: 'Shipment Tracking',
        path: '/dashboard/tracking-maps',
        icon: <MapIcon fontSize="small" />,
      },
      {
        title: 'Calendar',
        path: '/dashboard/calendar',
        icon: <CalendarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Admin',
    items: [
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/users',
          },
          {
            title: 'Add',
            path: '/dashboard/users/new',
          },
        ],
      },
      {
        title: 'Projects',
        path: '/dashboard/projects',
        icon: <Apps fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/projects',
          },
          {
            title: 'Add',
            path: '/dashboard/projects/add',
          },
          // {
          //   title: 'Edit',
          //   path: '/dashboard/projects/1/edit',
          // },
        ],
      },
      {
        title: 'Suppliers',
        path: '/dashboard/suppliers',
        icon: <PrecisionManufacturingIcon fontSize="small" />,
        children: [
          {
            title: 'Contracts',
            path: '/dashboard/suppliers/contracts',
          },
          // {
          //   title: 'Add',
          //   path: '/dashboard/projects/add',
          // },
          // {
          //   title: 'Edit',
          //   path: '/dashboard/projects/1/edit',
          // },
        ],
      },
      {
        title: 'Project Contacts',
        path: '/dashboard/contacts',
        icon: <Contacts fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/contacts',
          },
          {
            title: 'Add',
            path: '/dashboard/contacts/add',
          },
          // {
          //   title: 'Edit',
          //   path: '/dashboard/projects/1/edit',
          // },
        ],
      },
      // {
      //   title: 'App Settings',
      //   path: '/dashboard/app-settings',
      //   icon: <SettingsIcon fontSize="small" />,
      // },
      // {
      //   title: 'Products',
      //   path: '/dashboard/products',
      //   icon: <ShoppingCartIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/dashboard/products',
      //     },
      //     {
      //       title: 'Create',
      //       path: '/dashboard/products/new',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Modules',
    items: [
      {
        title: 'Project Management',
        path: '/dashboard/pm/drawing-submissions',
        icon: <BriefcaseLogo fontSize="small" />,
        children: [
          {
            title: 'Requests For Information',
            path: '/dashboard/pm/rfi-submissions',
          },
          {
            title: 'Project Submissions',
            path: '/dashboard/pm/project-submissions',
          },
          {
            title: 'Drawing Submissions',
            path: '/dashboard/pm/drawing-submissions',
          },
          {
            title: 'Sample Submissions',
            path: '/dashboard/pm/sample-submissions',
          },
          {
            title: 'Site Instructions',
            path: '/dashboard/pm/site-instructions',
          },
          {
            title: 'Requests For Change Orders',
            path: '/dashboard/pm/rco-submissions',
          },
          {
            title: 'Change Orders',
            path: '/dashboard/pm/change-order-submissions',
          },
          {
            title: 'Supplier Change Orders',
            path: '/dashboard/pm/internal-change-order-submissions',
          },
        ],
      },
      {
        title: 'Manufacturing',
        path: '/dashboard/mfg',
        icon: <BusinessLogo fontSize="small" />,
        children: [
          {
            title: 'Product Grid',
            path: '/dashboard/mfg/overview',
          },
          // {
          //   title: 'Suppliers',
          //   path: '/dashboard/mfg/suppliers',
          //   children: [
          //     {
          //       title: 'List',
          //       path: '/dashboard/mfg/suppliers',
          //     },
          //     {
          //       title: 'Add',
          //       path: '/dashboard/mfg/suppliers/new',
          //     },
          //     {
          //       title: 'Edit',
          //       path: '/dashboard/mfg/suppliers/1/edit',
          //     },
          //   ],
          // },
          // {
          //   title: 'Batches',
          //   path: '/dashboard/mfg/batches',
          //   children: [
          //     // {
          //     //   title: 'Batch List',
          //     //   path: '/dashboard/mfg/batch-list',
          //     // },
          //     {
          //       title: 'Add',
          //       path: '/dashboard/mfg/batches/add',
          //     },
          //     // {
          //     //   title: 'Edit',
          //     //   path: '/dashboard/mfg/batches/1/edit',
          //     // },
          //   ],
          // },
          // {
          //   title: 'Crates',
          //   path: '/dashboard/mfg/crates',
          //   children: [
          //     {
          //       title: 'Crate List',
          //       path: '/dashboard/mfg/crate-list',
          //     },
          //     {
          //       title: 'Add',
          //       path: '/dashboard/mfg/crates/add',
          //     },
          //     // {
          //     //   title: 'Edit',
          //     //   path: '/dashboard/mfg/batches/1/edit',
          //     // },
          //   ],
          // },
          // {
          //   title: 'Containers',
          //   path: '/dashboard/mfg/containers',
          //   children: [
          //     {
          //       title: 'Container List',
          //       path: '/dashboard/mfg/container-list',
          //     },
          //     {
          //       title: 'Add',
          //       path: '/dashboard/mfg/containers/add',
          //     },
          //     // {
          //     //   title: 'Edit',
          //     //   path: '/dashboard/mfg/batches/1/edit',
          //     // },
          //   ],
          // },
          // {
          //   title: 'Work Orders',
          //   path: '/dashboard/mfg/work-orders',
          //   children: [
          //     {
          //       title: 'List',
          //       path: '/dashboard/mfg/work-orders',
          //     },
          //     {
          //       title: 'Add',
          //       path: '/dashboard/mfg/work-orders/new',
          //     },
          //     {
          //       title: 'Edit',
          //       path: '/dashboard/mfg/work-orders/1/edit',
          //     },
          //   ],
          // },
          // {
          //   title: 'Shipments',
          //   path: '/dashboard/mfg/shipments',
          //   children: [
          //     {
          //       title: 'Crates',
          //       path: '/dashboard/mfg/shipments/crates',
          //       children: [
          //         {
          //           title: 'List',
          //           path: '/dashboard/mfg/shipments/crates',
          //         },
          //         {
          //           title: 'Add',
          //           path: '/dashboard/mfg/shipments/crates/new',
          //         },
          //         {
          //           title: 'Edit',
          //           path: '/dashboard/mfg/shipments/crates/1/edit',
          //         },
          //       ],
          //     },
          //     {
          //       title: 'Containers',
          //       path: '/dashboard/mfg/shipments/containers',
          //       children: [
          //         {
          //           title: 'List',
          //           path: '/dashboard/mfg/shipments/containers',
          //         },
          //         {
          //           title: 'Add',
          //           path: '/dashboard/mfg/shipments/containers/new',
          //         },
          //         {
          //           title: 'Edit',
          //           path: '/dashboard/mfg/shipments/containers/1/edit',
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      }, // End Manufacturing
      {
        title: 'Production',
        path: '/dashboard/production',
        icon: <ArchiveIcon fontSize="small" />,
        children: [
          {
            title: 'Picking Lists',
            path: '/dashboard/production/picking-lists',
            children: [
              {
                title: 'List',
                path: '/dashboard/production/picking-lists',
              },
              {
                title: 'Add',
                path: '/dashboard/production/picking-lists/new',
              },
              {
                title: 'Edit',
                path: '/dashboard/production/picking-lists/1/edit',
              },
            ],
          },
          {
            title: 'Inventory',
            path: '/dashboard/production/inventory',
            children: [
              {
                title: 'List',
                path: '/dashboard/production/inventory',
              },
              {
                title: 'Manage',
                path: '/dashboard/production/inventory/manage',
              },
              {
                title: 'Reports',
                path: '/dashboard/production/inventory/reports',
              },
            ],
          },
          {
            title: 'Deliveries',
            path: '/dashboard/production/deliveries',
            children: [
              {
                title: 'List',
                path: '/dashboard/production/deliveries',
              },
              {
                title: 'Add',
                path: '/dashboard/production/deliveries/new',
              },
              {
                title: 'Manage',
                path: '/dashboard/production/deliveries/manage',
              },
            ],
          },
          {
            title: 'Reports',
            path: '/dashboard/production/reports',
          },
        ],
      }, // End Production
      {
        title: 'Logistics',
        path: '/dashboard/logistics',
        icon: <LanguageSharp fontSize="small" />,
        children: [
          {
            title: 'Deliveries',
            path: '/dashboard/mfg/overview',
          },
          {
            title: 'Containers',
            path: '/dashboard/logistics/containers',
          },
          {
            title: 'Crates',
            path: '/dashboard/logistics/crates',
          },
          {
            title: 'Reports',
            path: '/dashboard/logistics/reports',
          },
          // {
          //   title: 'Delivery Schedule',
          //   path: '/dashboard/logistics/delivery',
          // },
          // {
          //   title: 'Tracking',
          //   path: '/dashboard/logistics/tracking',
          // },
        ],
      }, // End Logistics
      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />,
      // },
    ],
  },
  /*
    {
      title: 'Apps',
      items: [
        {
          title: 'Kanban',
          path: '/dashboard/kanban',
          icon: <ClipboardListIcon fontSize="small" />
        },
        {
          title: 'Mail',
          path: '/dashboard/mail',
          icon: <MailIcon fontSize="small" />
        },
        {
          title: 'Chat',
          path: '/dashboard/chat',
          icon: <ChatAltIcon fontSize="small" />
        },
        {
          title: 'Calendar',
          path: '/dashboard/calendar',
          icon: <CalendarIcon fontSize="small" />
        }
      ]
    }
    */
];

export default sections;
