import { EDIT_PROJECT_AFFILIATE } from '../actions/types';

const initialState = {
  ProjectActorEditData: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROJECT_AFFILIATE:
      return {
        ...state,
        ProjectActorEditData: action.payload
      };
    default:
      return state;
  }
};
