import { useCallback, useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Link,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '../../../icons/ChevronRight';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import { useSelector } from 'react-redux';
import { changeOrderApi } from '../../../api/changeOrderApi';
import { projectApi } from '../../../api/projectApi';
import { userApi } from '../../../api/userApi';
import { isNull, isArrayEmpty, parseError } from '../../../lib/helpers';
import getDate from '../../../utils/getDate';
import toast from 'react-hot-toast';
import LoadingSpinner from '../../../components/widgets/spinners/loadingSpinner';
import InternalChangeOrderDisplay from '../../../components/dashboard/project-management/internal-change-order-submissions/InternalChangeOrderDisplay';
import InternalChangeOrderReportDisplay from '../../../components/dashboard/project-management/internal-change-order-submissions/InternalChangeOrderReportDisplay';

const tabs = [
  { label: 'Entries', value: 'entries' },
  { label: 'Reports', value: 'reports' },
];

const ChangeOrderSubmissionList = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('entries');
  const [projectSuppliers, setProjectSuppliers] = useState(null);
  const [changeOrders, setChangeOrders] = useState([]);
  const [projects, setProjects] = useState([]);
  const dbName = useSelector((state) => state.currentProjectDetails.dbName);
  const currentUserData = useSelector((state) => state.userData.userData);
  const [nextNewRecordId, setNextNewRecordId] = useState(0);

  const getChangeOrders = useCallback(async () => {
    try {
      let tmpNum = 0;
      const data = await changeOrderApi.getChangeOrders(dbName);
      if (!isArrayEmpty(data)) {
        data.map((submission) => {
          submission.approvalDeadline = getDate(submission.approvalDeadline);
          submission.dueDate = getDate(submission.dueDate);
          submission.submissionDate = getDate(submission.submissionDate);
          return false;
        });
      } else {
        tmpNum = 1;
      }
      if (mounted.current) {
        setNextNewRecordId(tmpNum);
        setChangeOrders(data.filter((co) => co.isCoInternal === true)); // only show COs that internal
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  }, [mounted]);

  const getProjectSuppliers = useCallback(async () => {
    try {
      const data = await projectApi.getProjectSuppliers(currentUserData.lastWorkedOnProjectId);
      if (isNull(data)) {
        throw new Error('No data recieved from server');
      }
      if (mounted.current) {
        setProjectSuppliers(data);
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getChangeOrders();
  }, [getChangeOrders]);

  useEffect(() => {
    getProjectSuppliers();
  }, [getProjectSuppliers]);

  const currentStoredProjectId = useSelector(
    (state) => state.currentProjectDetails.currentProjectId,
  );
  const currentProject = projects.find((project) => project.id === currentStoredProjectId);
  const userData = useSelector((state) => state.userData.userData);

  const fetchUserProjects = useCallback(async () => {
    try {
      const data = await userApi.getUserProjectsDetailed(userData.id);
      if (isNull(data)) {
        toast.error('No Data Recieved');
        console.error('no data received');
      }
      if (mounted.current) {
        setProjects(data.projects);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  }, [mounted]);

  useMemo(() => {
    setIsLoading(true);
    fetchUserProjects();
  }, [fetchUserProjects]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const refetchChangeOrdersCallback = () => {
    getChangeOrders();
  };

  return (
    <>
      <Helmet>
        <title>Project Management: Supplier Change Orders | Siber PLM</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Supplier Change Order Submissions
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/pm/internal-change-order-submissions"
                  variant="subtitle2"
                >
                  Project Management
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Supplier Change Orders
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <LoadingSpinner isLoading={isLoading} />
            ) : (
              <>
                {currentTab === 'entries' && projectSuppliers && (
                  <InternalChangeOrderDisplay
                    submissions={changeOrders}
                    currentProject={currentProject}
                    nextNewRecordId={nextNewRecordId}
                    projectSuppliers={projectSuppliers}
                    refetchChangeOrdersCallback={refetchChangeOrdersCallback}
                  />
                )}
                {currentTab === 'reports' && projectSuppliers && (
                  <InternalChangeOrderReportDisplay
                    submissions={changeOrders}
                    // currentProject={currentProject}
                    nextNewRecordId={nextNewRecordId}
                    projectSuppliers={projectSuppliers}
                  />
                )}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ChangeOrderSubmissionList;
