import { SET_DB_NAME, SET_CURRENT_PROJECT_ID, CLEAR_PROJECT_ID, SET_CURRENT_PROJECT_ABRV, SET_CURRENT_PROJECT_WINDOW_SYSTEM } from '../actions/types';

const initialState = {
  dbName: '',
  currentProjectId: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DB_NAME:
      return {
        ...state,
        dbName: action.payload
      };
    case SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        currentProjectId: action.payload
      };
    case SET_CURRENT_PROJECT_ABRV:
      return {
        ...state,
        currentProjectAbrv: action.payload
      };
    case SET_CURRENT_PROJECT_WINDOW_SYSTEM:
      return {
        ...state,
        currentProjectWindowSystem: action.payload
      };
    case CLEAR_PROJECT_ID:
      return {
        ...state,
        currentProjectId: '',
        dbName: '',
      };
    default:
      return state;
  }
};
