import {
  GET_CHANGE_ORDERS,
  ADD_CHANGE_ORDER,
  // UPDATE_CHANGE_ORDER,
  GET_MOST_RECENT_CHANGE_ORDER,
  // ADD_RECORD_TO_SUBMISSION_ENTRY
} from './queries/changeOrders';
import { dbApi } from './dbApi';
import { uploadApi } from './uploadApi';
import { isArrayEmpty, isNull } from '../lib/helpers';
import * as Sentry from '@sentry/react';

class ChangeOrderApi {
  // make a query that can handle id / dbName inputs later
  getChangeOrders(dbName) {
    if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
      throw new Error('Invalid dbName');
    }
    return dbApi
      .query(GET_CHANGE_ORDERS, dbName)
      .then((result) => {
        return result.getChangeOrders;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  getMostRecentChangeOrder(dbName) {
    if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
      throw new Error('Invalid dbName');
    }
    return dbApi
      .query(GET_MOST_RECENT_CHANGE_ORDER, dbName)
      .then((result) => {
        return result.getMostRecentChangeOrder;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  addChangeOrder(
    dbName,
    parentId,
    title,
    internalId,
    clientDocumentId,
    isCoInternal,
    isCoOpen,
    supplierId,
    supplierType,
    supplierCost,
    clientCost,
    sfgCost,
    // recordId,
    approvalStatus,
    to,
    from,
    submissionDate,
    approvalDeadline,
    linkedRcoId,
    message,
    attachments,
    byUser,
    internalParties,
    externalParties,
    addCalendarEntry,
    allDay,
    isSystemEntry,
    shipment,
    projectId,
    projectAbrv,
    department,
    dateType
  ) {
    const queryVariables = {};
    if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
      throw new Error('Invalid dbName');
    }

    if (!isNull(title) && typeof title === 'string' && title.length > 0) {
      queryVariables.title = title;
    } else {
      throw new Error('Invalid Title');
    }

    if (!isNull(internalId) && typeof internalId === 'string' && internalId.length > 0) {
      queryVariables.internalId = internalId;
    } else if (isNull(internalId) && !isNull(linkedRcoId)) {
      throw new Error('Invalid Internal ID');
    }

    if (
      !isNull(clientDocumentId) &&
      typeof clientDocumentId === 'string' &&
      clientDocumentId.length > 0
    ) {
      queryVariables.clientDocumentId = clientDocumentId;
    } else if (isNull(clientDocumentId) && !isNull(linkedRcoId)) {
      throw new Error('Invalid Client Document ID');
    }

    if (
      !isNull(approvalStatus) &&
      typeof approvalStatus === 'string' &&
      approvalStatus.length > 0
    ) {
      queryVariables.approvalStatus = approvalStatus;
    } else {
      throw new Error('Invalid Approval Status');
    }

    if (!isNull(to) && typeof to === 'string' && to.length > 0) {
      queryVariables.to = to;
    } else {
      throw new Error('Invalid to');
    }

    if (!isNull(from) && typeof from === 'string' && from.length > 0) {
      queryVariables.from = from;
    } else {
      throw new Error('Invalid from');
    }

    if (!isNull(isCoInternal) && typeof isCoInternal === 'boolean') {
      queryVariables.isCoInternal = isCoInternal;
    } else {
      throw new Error('Invalid co type flag');
    }

    if (!isNull(isCoInternal) && typeof isCoInternal === 'boolean') {
      if (!isCoInternal) {
        // CO is not internal we process client cost here and set supplier cost to 0
        if (!isNull(clientCost) && typeof clientCost === 'number') {
          queryVariables.clientCost = clientCost;
          queryVariables.supplierCost = 0;
        } else {
          throw new Error('Invalid clientCost');
        }
      }

      if (!isNull(sfgCost) && typeof sfgCost === 'number') {
        queryVariables.sfgCost = sfgCost;
      } else {
        throw new Error('Invalid sfgCost');
      }

      if (isCoInternal) {
        // CO is not internal we process supplierCost cost here instead of client cost and set client cost to 0
        if (!isNull(supplierCost) && typeof supplierCost === 'number') {
          queryVariables.supplierCost = supplierCost;
          queryVariables.clientCost = 0;
        } else {
          throw new Error('Invalid supplierCost');
        }

        if (!isNull(supplierId) && typeof supplierId === 'string') {
          queryVariables.supplierId = supplierId;
        } else {
          throw new Error('Invalid supplierId');
        }

        if (!isNull(supplierType) && typeof supplierType === 'string' && supplierType.length > 0) {
          queryVariables.supplierType = supplierType;
        } else {
          throw new Error('Invalid supplierType');
        }
      }
    } else {
      throw new Error('Invalid Internal/External CO type');
    }

    if (!isNull(isCoOpen) && typeof isCoOpen === 'boolean') {
      queryVariables.isCoOpen = isCoOpen;
    } else {
      throw new Error('Invalid CO Type');
    }

    if (!isNull(byUser) && typeof byUser === 'string' && byUser.length > 0) {
      queryVariables.byUser = byUser;
    } else {
      throw new Error('Invalid byUser');
    }

    if (isArrayEmpty(internalParties)) {
      throw new Error('System Error Corrupted Data');
    }
    if (isArrayEmpty(externalParties)) {
      throw new Error('System Error Corrupted Data');
    }

    if (!isNull(parentId)) {
      queryVariables.parentId = parentId;
    }

    if (!isNull(linkedRcoId)) {
      queryVariables.linkedRcoId = linkedRcoId;
    }

    if (!isNull(submissionDate)) {
      if (!Number.isNaN(Date.parse(submissionDate))) {
        queryVariables.submissionDate = submissionDate;
      } else {
        throw new Error('Invalid Submission Date');
      }
    }

    if (!isNull(approvalDeadline)) {
      if (!Number.isNaN(Date.parse(approvalDeadline))) {
        queryVariables.approvalDeadline = approvalDeadline;
      } else {
        throw new Error('Invalid Approval Deadline');
      }
    }

    if (!isNull(message) && typeof message === 'string' && message.length > 0) {
      queryVariables.message = message;
    }

    if (!isArrayEmpty(attachments)) {
      queryVariables.attachments = attachments;
    }

    if (!isArrayEmpty(internalParties)) {
      queryVariables.internalParties = internalParties;
    } else {
      throw new Error('Invalid Internal Parties');
    }

    if (!isArrayEmpty(externalParties)) {
      queryVariables.externalParties = externalParties;
    } else {
      throw new Error('Invalid External Parties');
    }

    if (!isNull(addCalendarEntry) && typeof addCalendarEntry === 'boolean') {
      queryVariables.addCalendarEntry = addCalendarEntry;
    } else {
      throw new Error('Invalid addCalendarEntry Type');
    }

    if (addCalendarEntry === true) {
      if (isNull(allDay) || typeof allDay !== 'boolean') {
        throw new Error('Invalid parametr calendar allDay');
      }
      if (isNull(isSystemEntry) || typeof allDay !== 'boolean') {
        throw new Error('Invalid parametr calendar isSystemEntry');
      }
      if (isNull(shipment) || typeof allDay !== 'boolean') {
        throw new Error('Invalid parametr calendar shipment');
      }
      if (isNull(projectId)) {
        throw new Error('Invalid parametr calendar projectId');
      }
      const eventStart = new Date(approvalDeadline.toString().slice(3, 16));
      const eventEnd = new Date(approvalDeadline.toString().slice(3, 16));
      eventEnd.setHours(eventEnd.getHours() + 1);

      queryVariables.createdBy = byUser;
      queryVariables.allDay = allDay;
      queryVariables.isSystemEntry = isSystemEntry;
      queryVariables.shipment = shipment;
      queryVariables.start = eventStart;
      queryVariables.end = eventEnd;
      queryVariables.calendarEntryTitle = title;
      queryVariables.projectId = projectId;
      queryVariables.projectAbrv = projectAbrv;
      queryVariables.department = department;
      queryVariables.dateType = dateType;
    }

    return dbApi
      .mutate(queryVariables, ADD_CHANGE_ORDER, dbName)
      .then((result) => {
        return result.addChangeOrder;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }

  // updateChangeOrder(
  //   id,
  //   parentId,
  //   dbName,
  //   title,
  //   clientCost,
  //   sfgCost,
  //   recordId,
  //   approvalStatus,
  //   to,
  //   from,
  //   submissionDate,
  //   approvalDeadline,
  //   linkedRcoId,
  //   message,
  //   attachments,
  // ) {
  //   const queryVariables = {};
  //   if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
  //     throw new Error('Invalid dbName');
  //   }
  //   if (!isNull(id) && typeof id === 'string') {
  //     queryVariables.id = id;
  //   }
  //   if (!isNull(title) && typeof title === 'string' && title.length > 0) {
  //     queryVariables.title = title;
  //   }
  //   if (!isNull(recordId) && typeof recordId === 'string' && recordId.length > 0) {
  //     queryVariables.recordId = recordId;
  //   }
  //   if (
  //     !isNull(approvalStatus) &&
  //     typeof approvalStatus === 'string' &&
  //     approvalStatus.length > 0
  //   ) {
  //     queryVariables.approvalStatus = approvalStatus;
  //   }
  //   if (!isNull(to) && typeof to === 'string' && to.length > 0) {
  //     queryVariables.to = to;
  //   }
  //   if (!isNull(from) && typeof from === 'string' && from.length > 0) {
  //     queryVariables.from = from;
  //   }
  //   if (!isNull(sfgCost) && typeof sfgCost === 'number') {
  //     queryVariables.sfgCost = sfgCost;
  //   }
  //   if (!isNull(clientCost) && typeof clientCost === 'number') {
  //     queryVariables.clientCost = clientCost;
  //   }
  //   if (!isNull(submissionDate)) {
  //     queryVariables.submissionDate = submissionDate;
  //   }
  //   if (!isNull(approvalDeadline)) {
  //     queryVariables.approvalDeadline = approvalDeadline;
  //   }
  //   if (!isNull(linkedRcoId)) {
  //     queryVariables.linkedRcoId = linkedRcoId;
  //   }
  //   if (!isNull(message) && typeof message === 'string') {
  //     queryVariables.message = message;
  //   }
  //   if (!isArrayEmpty(attachments)) {
  //     queryVariables.attachments = attachments;
  //   }
  //   queryVariables.parentId = parentId;
  //   return dbApi
  //     .mutate(queryVariables, UPDATE_CHANGE_ORDER, dbName)
  //     .then((result) => {
  //       return result.updateChangeOrder;
  //     })
  //     .catch((err) => {
  //       throw new Error(err);
  //     });
  // }

  uploadAttachments(dbName, type, attachments) {
    if (isNull(dbName) || typeof dbName !== 'string' || dbName.length <= 0) {
      throw new Error('Invalid dbName');
    }
    if (isArrayEmpty(attachments)) {
      return [];
    }
    const formData = new FormData();
    formData.append('dbName', dbName);
    formData.append('type', type);
    attachments.map((item) => formData.append('attachments', item));

    return uploadApi
      .uploadData(formData, 'subattachment_upload')
      .then((result) => {
        return result;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error(err);
      });
  }
}

export const changeOrderApi = new ChangeOrderApi();
