import { useCallback, useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Link,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '../../../icons/ChevronRight';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import { useSelector } from 'react-redux';
import { changeOrderApi } from '../../../api/changeOrderApi';
import { isNull, isArrayEmpty, parseError } from '../../../lib/helpers';
import { userApi } from '../../../api/userApi';
import getDate from '../../../utils/getDate';
import toast from 'react-hot-toast';
import LoadingSpinner from '../../../components/widgets/spinners/loadingSpinner';
import ChangeOrderDisplay from '../../../components/dashboard/project-management/change-order-submissions/ChangeOrderDisplay';

const tabs = [
  { label: 'Entries', value: 'submitted' },
  // { label: 'Replied', value: 'replied' }
];

const ChangeOrderSubmissionList = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('submitted');
  // const [project, setProject] = useState(null);
  const [refetchChangeOrders, setRefetchChangeOrders] = useState(false);
  const [changeOrders, setChangeOrders] = useState([]);
  const [projects, setProjects] = useState([]);
  const dbName = useSelector((state) => state.currentProjectDetails.dbName);
  const [nextNewRecordId, setNextNewRecordId] = useState(0);

  // const getChangeOrders = useCallback(async () => {
  //   try {
  //     let tmpNum = 0;
  //     const data = await changeOrderApi.getChangeOrders(dbName);

  //     if (!isArrayEmpty(data)) {
  //       data.map((submission) => {
  //         submission.approvalDeadline = getDate(submission.approvalDeadline);
  //         submission.dueDate = getDate(submission.dueDate);
  //         submission.submissionDate = getDate(submission.submissionDate);
  //         return false;
  //       });
  //     } else {
  //       tmpNum = 1;
  //     }
  //     if (mounted.current) {
  //       setNextNewRecordId(tmpNum);
  //       setChangeOrders(data.filter((co) => co.isCoInternal === false)); // only show COs that are no internal
  //     }
  //   } catch (err) {
  //     toast.error(parseError(err));
  //     console.error(err);
  //   }
  // }, [mounted]);

  useEffect(() => {
    const getChangeOrders = async () => {
      try {
        let tmpNum = 0;
        const data = await changeOrderApi.getChangeOrders(dbName);

        if (!isArrayEmpty(data)) {
          data.map((submission) => {
            submission.approvalDeadline = getDate(submission.approvalDeadline);
            submission.dueDate = getDate(submission.dueDate);
            submission.submissionDate = getDate(submission.submissionDate);
            return false;
          });
        } else {
          tmpNum = 1;
        }
        if (mounted.current) {
          setNextNewRecordId(tmpNum);
          setChangeOrders(data.filter((co) => co.isCoInternal === false)); // only show COs that are no internal
        }
      } catch (err) {
        toast.error(parseError(err));
        console.error(err);
      }
    };
    getChangeOrders();
  }, [refetchChangeOrders]);

  const refetchChangeOrdersCallback = () => {
    setRefetchChangeOrders(true);
    // getChangeOrders();
  };

  const currentStoredProjectId = useSelector(
    (state) => state.currentProjectDetails.currentProjectId,
  );
  const currentProject = projects.find((project) => project.id === currentStoredProjectId);
  const userData = useSelector((state) => state.userData.userData);

  const fetchUserProjects = useCallback(async () => {
    try {
      const data = await userApi.getUserProjectsDetailed(userData.id);
      if (isNull(data)) {
        throw new Error('No data recieved from server');
      }
      if (mounted.current) {
        setProjects(data.projects);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    }
  }, [mounted]);

  useMemo(() => {
    setIsLoading(true);
    fetchUserProjects();
  }, [fetchUserProjects]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>Project Management: Change Orders | Siber PLM</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false} className="submission-list">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Change Order Submissions
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/pm/change-order-submissions"
                  variant="subtitle2"
                >
                  Project Management
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Change Orders
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <LoadingSpinner isLoading={isLoading} />
            ) : (
              <ChangeOrderDisplay
                submissions={changeOrders}
                currentProject={currentProject}
                nextNewRecordId={nextNewRecordId}
                refetchChangeOrdersCallback={refetchChangeOrdersCallback}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ChangeOrderSubmissionList;
