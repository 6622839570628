import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Snackbar,
  SnackbarContent,
  CardContent,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import FileDropzone from '../../../FileDropzone';
import Egnyte from 'egnyte-js-sdk/dist/egnyte.min';
import QuillEditor from '../../../QuillEditor';
import CircularProgressWithLabel from '../../../widgets/spinners/CirlcularProgressWithLabel';
import { egnyteApi } from '../../../../api/egnyteApi';
import {
  isNull,
  isArrayEmpty,
  parseError,
  sleep,
  notesFieldGlobalRegex,
  // stringFieldGlobalRegex,
} from '../../../../lib/helpers';
import { userApi } from '../../../../api/userApi';
import { systemSettingsApi } from '../../../../api/systemSettingsApi';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { addCalendarEntry } from '../../../../actions/submissionEntryActions';

const EgnyteUploadAdditionalComments = (props) => {
  const { onBack, comments, setCommentsCallback, createRecordCallback, addToCalendarCheckbox } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorBar, setErrorBar] = useState(false);
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const [uploadProgressFileName, setUploadProgressFileName] = useState('');
  const [disableEgnyteUpload, setDisableEgnyteUpload] = useState(false);
  const [disableLocalFilepicker, setDisableLocalFilepicker] = useState(true);
  const [disableEgnyteLink, setDisableEgnyteLink] = useState(false);
  const [chosenFolderPath, setChosenFolderPath] = useState([]);
  const [egnyteData, setEgnyteData] = useState(undefined);
  const [egnyte, setEgnyte] = useState(undefined);
  const [commentData, setCommentData] = useState(comments);
  const [commentsValid, setCommentsValid] = useState(true);
  const userData = useSelector((state) => state.userData.userData);
  const filePickerPlaceholder = (1000 + Math.random() * (1000000 - 1000)).toString();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createCalendarEntry = useSelector((state) => state.submissionEntryData.addCalendarEntry);

  useEffect(() => {
    let result = false;
    if (!isNull(commentData)) {
      if (commentData.length > 0) {
        if (commentData === '<p><br></p>') {
          setCommentsValid(true);
        } else if (commentData.length > 1000) {
          setCommentsValid(false);
          return;
        } else {
          result = notesFieldGlobalRegex.test(commentData);
          if (result === true) {
            setCommentsValid(true);
          } else {
            setCommentsValid(false);
          }
        }
        setCommentsCallback(commentData);
      }
    }
  }, [commentData]);

  useEffect(() => {
    const setUpEgnyteConnection = async () => {
      try {
        const egnyteConnectionData = await systemSettingsApi.getEgnyteSettings();
        if (isNull(egnyteConnectionData)) {
          throw new Error('No data recieved from server');
        }

        const userTokenData = await userApi.getUserEgnyteToken(userData.id);
        if (isNull(userTokenData)) {
          throw new Error('No data recieved from server');
        }

        egnyteConnectionData.token = userTokenData.egnyteAuthToken;

        setEgnyteData(egnyteConnectionData);
        setEgnyte(
          Egnyte.init(egnyteConnectionData.engyteBaseUrl, {
            token: egnyteConnectionData.token,
          }),
        );
      } catch (error) {
        toast.error(parseError(error));
      }
    };

    setUpEgnyteConnection();
  }, []);

  const redirectToLogin = async () => {
    await sleep(2000);
    navigate('/dashboard/account');
  };

  const openEgnytePicker = (type) => {
    const node = document.getElementById(filePickerPlaceholder);
    if (type === 'upload') {
      setDisableEgnyteLink(true);
      setDisableEgnyteUpload(false);
      setDisableLocalFilepicker(false);
    } else {
      setDisableEgnyteLink(false);
      setDisableEgnyteUpload(true);
      setDisableLocalFilepicker(true);
    }

    if (egnyte?.filePicker) {
      egnyte.filePicker(node, {
        selection: (list) => {
          if (type === 'upload') {
            setChosenFolderPath([list[0].path]);
          } else {
            setChosenFolderPath(list);
          }
        },
        cancel: () => {
          if (type === 'upload') {
            setDisableEgnyteLink(false);
            setDisableEgnyteUpload(false);
            setChosenFolderPath([]);
          } else {
            setDisableEgnyteLink(false);
            setDisableEgnyteUpload(false);
            setChosenFolderPath([]);
          }
        },
        select: {
          /* eslint-disable-next-line */
          folder: type !== 'upload' ? false : true,
          /* eslint-disable-next-line */
          file: type === 'upload' ? false : true,
          // file: true,
          filesRemainVisible: true,
          /* eslint-disable-next-line */
          multiple: type === 'upload' ? false : true,
        },
        error: (e) => {
          if (e.statusCode === 401) {
            toast.error('Unauthorised access, please re-login');
            redirectToLogin();
          } else if (e.statusCode === 503) {
            toast.error('Query limit exceeded');
          }
        },
      });
    } else {
      toast.error('Filepicker not availablen');
      console.error('Filepicker not available');
    }
  };

  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleDropRejected = () => {
    setErrorBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorBar(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    // if (reason === 'escapeKeyDown') {
    //   console.log('escapeKeyDown');
    // }
    // if (reason === 'backdropClick') {
    //   console.log('backdropClick');
    // }
    setOpenDialog(false);
  };

  const handleDialogCloseBad = () => {
    setOpenDialog(false);
  };

  const updateUploadProgress = (currentChunkNumber, totalChunks, filename) => {
    const percent = Math.round((currentChunkNumber / totalChunks) * 100);
    setUploadProgressPercent(percent > 100 ? 100 : percent);
    setUploadProgressFileName(filename);
  };

  const handleAddCalendarEntry = (e) => {
    dispatch(addCalendarEntry(e.target.checked));
  };

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    setOpenDialog(true);

    try {
      let uploadedFilesMetadata;
      if (
        !isArrayEmpty(files) &&
        !isArrayEmpty(chosenFolderPath) &&
        !disableEgnyteUpload &&
        disableEgnyteLink
      ) {
        uploadedFilesMetadata = await egnyteApi.fileUpload(
          egnyteData.engyteBaseUrl,
          egnyteData.token,
          chosenFolderPath,
          files,
          updateUploadProgress,
        );
        if (isArrayEmpty(uploadedFilesMetadata)) {
          throw new Error('File upload failed');
        }
      }

      if (disableEgnyteUpload && !disableEgnyteLink) {
        uploadedFilesMetadata = await egnyteApi.getLinks(
          egnyteData.engyteBaseUrl,
          egnyteData.token,
          chosenFolderPath,
          updateUploadProgress,
        );
        if (isArrayEmpty(uploadedFilesMetadata)) {
          throw new Error('Links generation failed');
        }
      }

      createRecordCallback(uploadedFilesMetadata);
    } catch (err) {
      toast.error(parseError(err));
      console.error(err);
    } finally {
      setOpenDialog(false);
      setIsSubmitting(false);
    }
  };

  return (
    <form>
      <Card sx={{ p: 2 }}>
        <CardContent sx={{ justifyContent: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Typography color="textPrimary" variant="h6">
              Additional Comments
            </Typography>
            <Paper sx={{ mt: 3 }} variant="outlined">
              <QuillEditor
                onChange={setCommentData}
                error={commentsValid}
                placeholder="Submisison details or comments"
                sx={{ minHeight: 300 }}
                value={commentData}
              />
            </Paper>
            {!commentsValid && (
              <Typography color="error" variant="body2">
                Max(1000) characters, Letters, Numbers, Spaces and -_@#*()!?/,.;: characters only
              </Typography>
            )}
          </Box>
          {addToCalendarCheckbox &&
            <FormControlLabel
              control={<Checkbox checked={createCalendarEntry} onChange={handleAddCalendarEntry} />}
              label="Add due date to calendar"
            />
          }
          <Box
            sx={{
              // mt: 2,
              // pt: 2,
              alignItems: 'center',
              display: 'inline',
              // flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Card sx={{ justifyContent: 'center' }}>
              <div id={filePickerPlaceholder} />
            </Card>
          </Box>
          {!isNull(chosenFolderPath[0]) && typeof chosenFolderPath[0] === 'string' && (
            <Box>
              <Typography color="primary" variant="body1" align="center">
                {'Selected Egnyte Upload Folder:  '}
              </Typography>
              <Typography color="textPrimary" variant="body2" align="center">
                {chosenFolderPath[0]}
              </Typography>
            </Box>
          )}
          {!isArrayEmpty(chosenFolderPath) && typeof chosenFolderPath[0] === 'object' && (
            <Box>
              <Typography color="primary" variant="body1" align="center">
                {'Selected Egnyte Files:  '}
              </Typography>
              {chosenFolderPath.map((file) => (
                <Typography key={file?.checksum} color="textPrimary" variant="body2" align="center">
                  {file?.path}
                </Typography>
              ))}
            </Box>
          )}
          <Box
            sx={{
              // mt: 2,
              pt: 2,
              pb: 2,
              alignItems: 'center',
              display: 'flex',
              // flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Button
              color="primary"
              onClick={() => openEgnytePicker('upload')}
              size="large"
              variant="outlined"
              disabled={disableEgnyteUpload}
            >
              Select Egnyte Destination Folder
            </Button>
            <Button
              color="primary"
              onClick={() => openEgnytePicker('link')}
              size="large"
              variant="outlined"
              disabled={disableEgnyteLink}
            >
              Select Existing File(s) From Egnyte
            </Button>
          </Box>
          <Box sx={{ pt: 2 }}>
            <FileDropzone
              files={files}
              maxSize={200000000}
              onDropRejected={handleDropRejected}
              onDrop={handleDrop}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              disabled={disableLocalFilepicker}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={errorBar}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <SnackbarContent
                className="fileSizeSnackbar"
                message="Maximum 100 files at a time and file zize must not exceed 200MB"
              />
            </Snackbar>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 4,
            }}
          >
            {onBack && (
              <Button
                color="primary"
                size="large"
                variant="outlined"
                onClick={handleDialogOpen}
                disabled={!commentsValid}
              >
                Complete
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button color="secondary" onClick={onBack} size="large" variant="outlined">
              Previous
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        {uploadProgressPercent !== 0 ? (
          <>
            <DialogTitle id="file-upload-title">{`Uploading: ${uploadProgressFileName}`}</DialogTitle>
            <Box
              sx={{
                mb: 2,
                ml: 2,
                mr: 2,
                pb: 2,
                pl: 2,
                pr: 2,
                width: 500,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <CircularProgressWithLabel value={uploadProgressPercent} />
            </Box>
          </>
        ) : (
          <>
            <DialogTitle>Confirm Submission?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                After submitting, you will not be able to edit the details later without the
                approval of an administrator. Are you sure everything is correct?
              </DialogContentText>
            </DialogContent>
            {!disableEgnyteUpload && disableEgnyteLink && isArrayEmpty(files) && (
              <DialogContent>
                <DialogContentText color="error">
                  Egnyte Destination Folder was selected but no Local Files were selected for
                  upload. Proceed?
                </DialogContentText>
              </DialogContent>
            )}
            {isArrayEmpty(chosenFolderPath) && (
              <DialogContent>
                <DialogContentText color="error">
                  No new or existing files will be attached to this submission. Proceed?
                </DialogContentText>
              </DialogContent>
            )}
          </>
        )}

        <DialogActions>
          {uploadProgressPercent === 0 && (
            <>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
                onClick={handleSubmit}
                size="large"
              >
                Confirm
              </Button>
              <Button
                onClick={handleDialogCloseBad}
                size="large"
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </form>
  );
};

EgnyteUploadAdditionalComments.propTypes = {
  onBack: PropTypes.func,
  comments: PropTypes.string,
  setCommentsCallback: PropTypes.func,
  createRecordCallback: PropTypes.func,
  addToCalendarCheckbox: PropTypes.bool,
};

export default EgnyteUploadAdditionalComments;
