import { isNull } from '../lib/helpers';

function getDate(isoDateMs) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
  };

  let retVal = 'N/A';
  try {
    if (isNull(isoDateMs)) {
      return retVal;
    }
    const isNum = /^\d+$/.test(isoDateMs);

    if (isNum) {
      retVal = new Date(Number(isoDateMs)).toLocaleDateString([], options);
    } else {
      retVal = new Date(isoDateMs).toLocaleDateString([], options);
    }
  } catch (err) {
    console.error(err);
  }

  if (retVal === 'Invalid Date') {
    retVal = 'N/A';
  }
  return retVal;
}

export default getDate;
