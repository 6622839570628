import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  // Button,
  // Dialog,
  // DialogContent,
  Box,
  // Grid,
  // Table,
  // TableCell,
  // TableBody,
  // TableHead,
  // TableRow,
  // TableContainer,
  // Paper,
  Card,
  // breadcrumbsClasses,
} from '@mui/material';
import ReportTree from '../../lists/InternalChangeOrderSubmissionTree/InternalChangeOrderReportTree';
// import NewChangeOrder from './NewEntryForm/NewInternalChangeOrder';
import { isNull, isArrayEmpty } from '../../../../lib/helpers';

const ChangeOrderDisplay = (props) => {
  // const { submissions, currentProject, nextNewRecordId, projectSuppliers } = props;
  const { submissions, projectSuppliers } = props;
  const [totalSfgCost, setTotalSfgCost] = useState([]);
  const [totalSupplierCost, setTotalSupplierCost] = useState([]);
  const [reportTreeData, setReportTreeData] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  const noNegativeSfgCost = submissions.filter((submission) => submission.sfgCost >= 0);
  const noNegativeSupplierCost = submissions.filter((submission) => submission.supplierCost >= 0);

  // console.log(submissions);
  // console.log(projectSuppliers);

  /*
    {
      supplier Name
      supplier type
      contract amount
      ajustment amount
      newContractCost
    }
  */
  const getSupplierContractAmount = (projectSuppliersTypeArray, supplierId) => {
    // console.log(projectSuppliersTypeArray);
    for (let i = 0; i < projectSuppliersTypeArray.length; i += 1) {
      if (projectSuppliersTypeArray[i].supplierId === supplierId) {
        return projectSuppliersTypeArray[i].contractAmount;
      }
    }
    return 0;
  };

  const getActiveSuppliersData = (changeOrders, allProjectSuppliers) => {
    const suppliersFromCos = changeOrders.map((co) => ({
      id: co.supplierId,
      type: co.supplierType,
      name: co.to,
      sfgCost: co.sfgCost,
    }));
    const key = 'id';
    const uniqueSuppliers = [...new Map(suppliersFromCos.map((co) => [co[key], co])).values()];
    const reportData = [];
    for (let i = 0; i < uniqueSuppliers.length; i += 1) {
      let totallAdjustedAmmout = 0;
      for (let j = 0; j < suppliersFromCos.length; j++) {
        if (suppliersFromCos[j].id === uniqueSuppliers[i].id) {
          totallAdjustedAmmout += suppliersFromCos[j].sfgCost;
        }
      }

      switch (uniqueSuppliers[i].type) {
        case 'Installation Sub-Contractor': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.installSubContractorsProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Shop Drawings Sub-Contractor': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.shopDrawingSubContractorsProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Window Fabricator/Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.windowSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Door Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.doorSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Extrusion Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.extrusionSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Hardware Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.hardwareSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Glass Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.glassSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Gasket Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.gasketSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Silicone Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.siliconeSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        case 'Catalyst Supplier': {
          const contractAmount = getSupplierContractAmount(
            allProjectSuppliers.catalystSuppliersProjectData,
            uniqueSuppliers[i].id,
          );
          reportData.push({
            id: uniqueSuppliers[i].id,
            name: uniqueSuppliers[i].name,
            type: uniqueSuppliers[i].type,
            contractAmount,
            adjustedAmount: totallAdjustedAmmout, // need to calculate it here
            newContractCost: contractAmount + totallAdjustedAmmout, // need to calculate it here
          });
          break;
        }
        default: {
          break;
        }
      }
    }

    setReportTreeData(reportData);
  };

  useEffect(() => {
    getActiveSuppliersData(submissions, projectSuppliers);
    setTotalSfgCost([]);
    setTotalSupplierCost([]);
    noNegativeSfgCost.forEach((entry) => {
      if (entry.approvalStatus === 'Approved') {
        setTotalSfgCost((prevState) => [...prevState, entry.sfgCost]);
      }
    });
    noNegativeSupplierCost.forEach((entry) => {
      if (entry.approvalStatus === 'Approved') {
        setTotalSupplierCost((prevState) => [...prevState, entry.supplierCost]);
      }
    });
  }, [submissions]);

  // const handleDialogOpen = () => {
  //   setOpenDialog(true);
  // };

  // const handleDialogClose = () => {
  //   setOpenDialog(false);
  //   window.location.reload();
  // };

  // const closeDialog = () => {
  //   setOpenDialog(false);
  // };

  function createData(name, sfgCost, supplierCost, adjustmentAmount) {
    return { name, sfgCost, supplierCost, adjustmentAmount };
  }

  // const rows = [
  //   createData('Approved Total Costs', Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2, Math.round(totalClientCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2),
  // ];

  const rows = [];

  if (!isArrayEmpty(submissions) && !isNull(projectSuppliers)) {
    // console.log(projectSuppliers);
    rows.push(
      createData(
        'Approved Total Costs',
        Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
        Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
        Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2 -
          Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
      ),
    );
  }

  // const rows = [
  //   createData(
  //     'Approved Total Costs',
  //     Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
  //     Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2,
  //     (Math.round(totalSupplierCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2) - (Math.round(totalSfgCost.reduce((a, b) => a + b, 0) * 1e2) / 1e2)
  //   ),
  // ];

  // if (isNull(currentProject) || isNull(submissions) || isNull(projectSuppliers)) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div>
      <Box
        sx={{
          my: 2,
        }}
      >
        <Card>
          <Box
            sx={{
              mx: 2,
              my: 2,
            }}
          >
            {/* <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell className="RCOSfgCost">SFG Cost</TableCell>
                          <TableCell className="RCOClientCost">Supplier Cost</TableCell>
                          <TableCell className="RCOAdjustmentAmount">Adjustment Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="changeCalculatorOrderBodyText"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell>{`$${row.sfgCost}`}</TableCell>
                            <TableCell>{`$${row.supplierCost}`}</TableCell>
                            <TableCell><b>{`$${row.adjustmentAmount}`}</b></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid> */}
            <br />
            <ReportTree submissions={submissions} reportData={reportTreeData} />
            <br />
            {/* <div className="addButton">
              <Button
                color="primary"
                onClick={handleDialogOpen}
                variant="contained"
              >
                Add
              </Button>
            </div> */}
          </Box>
        </Card>
      </Box>
      {/* <Dialog
        open={openDialog}
        onClose={closeDialog}
      >
        <DialogContent>
          <NewChangeOrder
            handleClose={handleDialogClose}
            currentProject={currentProject}
            onClose={closeDialog}
            nextNewRecordId={nextNewRecordId}
          />
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

ChangeOrderDisplay.propTypes = {
  submissions: PropTypes.array,
  // currentProject: PropTypes.object,
  // nextNewRecordId: PropTypes.number,
  projectSuppliers: PropTypes.object,
};

export default ChangeOrderDisplay;
