import { apiServerConfig } from '../config';
import axios from 'axios';
import * as Sentry from '@sentry/react';

class UploadApi {
  uploadData(data, endpoint) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(
          `${apiServerConfig.api_endpoint}/${endpoint}`,
          data,
          { withCredentials: true },
          { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        .then((result) => {
          if (
            result.statusText === 'OK' &&
            result.status >= 200 &&
            result.status < 300 &&
            typeof result.data.data !== 'number' &&
            typeof result.data.data !== 'string' &&
            typeof result.data.data !== 'undefined'
          ) {
            resolve(result);
          } else {
            throw new Error('Upload could not be processed, please try again later.');
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (error.response) {
            // Request made and server responded
            // console.log(error.response.data);
            reject(error.response.data.error);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            reject(error.request);
            // The request was made but no response was received
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
            reject(error.message);
          }
          // reject(error.response.data);
          // console.log(error.response);
          // if (error.status === 406) {
          //   reject(new Error('Illegal Attachment'));
          // } else if (error.status >= 400 && error.status <= 500) {
          //   reject(new Error('Upload could not be processed'));
          // } else {
          //   console.log('we are erroring');
          //   reject(error);
          // }
        });
    });
    return promise;
  }
}

export const uploadApi = new UploadApi();
