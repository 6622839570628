import { useCallback, useState, useMemo, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Button, Menu, MenuItem } from '@mui/material';
import { experimentalStyled, useTheme } from '@mui/material/styles';
import { SOFTWARE_VERSION } from '../../constants';
import { useSelector, connect } from 'react-redux';
import { userApi } from '../../api/userApi';
import { isNull, parseError } from '../../lib/helpers';
import { store } from '../../store';
import { setProductData } from '../../actions/productActions';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import useMounted from '../../hooks/useMounted';
import toast from 'react-hot-toast';
import Logo from '../Logo';

import {
  setDbName,
  setCurrentProjectId,
  setCurrentProjectWindowSystem,
  setCurrentProjectAbrv,
} from '../../actions/projectActions';
import '../../css/styles.css';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer,
}));

const DashboardNavbar = (props) => {
  const mounted = useMounted();
  const { onSidebarMobileOpen } = props;
  const [projects, setProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const userData = useSelector((state) => state.userData.userData);
  const isMobile = window.screen.width < 600;
  const currentProjectId = useSelector((state) => state.currentProjectDetails.currentProjectId);
  const [currentProject, setCurrentProject] = useState(undefined);
  const [selectedProjectName, setSelectedProjectName] = useState('Projects');
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const updateUserData = async (projectId) => {
    try {
      const data = await userApi.updateUserLastProject(userData.id, projectId || '');

      if (isNull(data)) {
        throw new Error('No data recieved from server');
      }

      store.dispatch({ type: 'ADD_USER_DATA', payload: data });
      window.location.reload();
    } catch (err) {
      toast.error(parseError(err));
    }
  };

  const handleProjectButtonClose = () => {
    setAnchorEl(null);
  };

  const handleProjectListMenuItemClick = (event, index) => {
    if (event.target.outerText.length > 3) {
      setSelectedProjectName(event.target.outerText);
    }
    setCurrentProject(projects[index]);
    updateUserData(projects[index].id);
    setAnchorEl(null);
  };

  const getCurrentProjectIndex = () => {
    let tmp = 0;
    projects.map((project, idx) => {
      if (project.id === currentProjectId) {
        tmp = idx;
      }
      return false;
    });
    if (!isNull(projects[tmp])) {
      setSelectedProjectName(projects[tmp].name);
    }
    return tmp;
  };

  useEffect(() => {
    setCurrentProject(projects.find((project) => project.id === currentProjectId));
    setCurrentProjectIndex(getCurrentProjectIndex());
  }, [projects]);

  useEffect(() => {
    if (!isNull(currentProject)) {
      props.setDbName(currentProject.dbName); // eslint-disable-line
      props.setCurrentProjectId(currentProject.id); // eslint-disable-line
      props.setCurrentProjectAbrv(currentProject.abbreviation); // eslint-disable-line
    }
  }, [currentProject]);

  const fetchUserProjects = useCallback(async () => {
    try {
      const data = await userApi.getUserProjects(userData.id);
      if (!data) {
        // throw new Error('no data received');
      }
      if (mounted.current) {
        setProjects(data.projects);
        if (userData.lastWorkedOnProjectId === null && data.length > 0) {
          props.setDbName(data[0].dbName); // eslint-disable-line
          props.setCurrentProjectId(data[0].id); // eslint-disable-line
          props.setCurrentProjectAbrv(currentProject.abbreviation); // eslint-disable-line
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useMemo(() => {
    fetchUserProjects();
  }, [fetchUserProjects]);

  return (
    <DashboardNavbarRoot>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="default"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        {!isMobile && (
          <RouterLink to="/">
            <Logo
              sx={{
                display: {
                  lg: 'inline',
                  xs: 'none',
                },
                height: '0.5vh',
                width: '0.5vw',
              }}
            />
          </RouterLink>
        )}
        {!isMobile && (
          <Box
            sx={{
              paddingLeft: '75px',
              fontWeight: '250',
              color: theme.palette.softwareVersionText.main,
            }}
          >
            {`v.${SOFTWARE_VERSION.major}.${SOFTWARE_VERSION.minor}.${SOFTWARE_VERSION.build}`}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            ml: 3,
          }}
        />
        <Box sx={{ ml: 3 }}>
          <Button
            id="project-button"
            variant="outlined"
            color="navBarButtons"
            className="scanQrButton"
            aria-controls={open ? 'project-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            // sx={{ width: 50, padding: 1, margin: 2 }}
          >
            {/* Projects */}
            {selectedProjectName}
          </Button>
          <Menu
            id="project-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleProjectButtonClose}
            MenuListProps={{
              'aria-labelledby': 'project-button',
            }}
          >
            {projects.map((project, index) => (
              <MenuItem
                key={project.id}
                selected={index === currentProjectIndex}
                onClick={(event) => handleProjectListMenuItemClick(event, index)}
                // onClick={(event) => console.log(event.target)}
              >
                {project.name}
              </MenuItem>
            ))}
            {/* <MenuItem onClick={handleCloseProjectAnchor}>Profile</MenuItem>
            <MenuItem onClick={handleCloseProjectAnchor}>My account</MenuItem>
            <MenuItem onClick={handleCloseProjectAnchor}>Logout</MenuItem> */}
          </Menu>
        </Box>
        <Box sx={{ ml: 4 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  setCurrentProjectId: PropTypes.func,
  setDbName: PropTypes.func,
  setCurrentProjectAbrv: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  scannedProductData: state.scannedProductData,
});

export default connect(mapStateToProps, {
  setCurrentProjectAbrv,
  setDbName,
  setCurrentProjectId,
  setCurrentProjectWindowSystem,
  setProductData,
})(DashboardNavbar);
